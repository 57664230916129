import React, {Component} from "react";
import {Button, Input} from "antd";
import {api} from "../../data/urls";
import axios from "axios";
import returnAuthHeaderForAJAX from "../../helpers/auth/returnAuthHeaderForAJAX";
import {openNotification} from "../../helpers/openNotification";
import {getErrorValue} from "../../helpers/editor/getErrorValue";
import fireAnalyticsEvent from "../../helpers/editor/fireAnalyticsEvent";
import {getErrorKey} from "../../helpers/editor/getErrorKey";
import {compareEditedValueWithCurrent} from "../../helpers/editor/compareEditedValueWithCurrent";
import { CrispEvents } from "../../enums/AnalyticsEventsEnums";
import trimStringTo from "../../helpers/strings/trimStringTo";

interface Props {
    websites: any,
    blogs: any,
    blogPosts: any,
    clearUpdatePostMetaDescription: any,
    auth: any,

    currentBlog: any,
    currentWebsite: any,
    currentBlogPost: any,

    saveBlogPostDataInStore: any,

    postMetaDescriptionUpdateWaitingForServer: any,
    postMetaDescriptionUpdateToggleInvalidAttempt: any,
    postMetaDescriptionUpdateErrorMessage: any,
    meta_description: string,
    setMetaDescription: (meta_description: string) => void,
}
const { TextArea } = Input;

type MyState = {  };
class UpdatePostMetaDescriptionForm extends Component<Props, MyState>{
    componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any): void {
        // We set the new state only when we change a settings active website or page.
        // We want to set up an initial value of a new picked website or page.
        if((this.props.currentWebsite.subdomain !== nextProps.currentWebsite.subdomain) || (this.props.currentBlogPost.url !== nextProps.currentBlogPost.url)){
            this.props.setMetaDescription(nextProps.currentBlogPost.meta_description);
            // We also clear errors when switch a website or a page.
            this.props.clearUpdatePostMetaDescription();
        }
    }

    handleMetaDescriptionUpdate = (e:any):void => {
        e.preventDefault();

        this.props.postMetaDescriptionUpdateWaitingForServer(true);

        //We want to remove all errors from inputs on a new request.
        this.props.clearUpdatePostMetaDescription();


        // Our server API expects the 'indexWebsitePageDashboardUrl' as an URL kwarg because a URL of a home page ('') is in appropriate for API and the dashboard.
        let newMetaDescription = this.props.meta_description;

        let blogPostUrlToServer = this.props.currentBlogPost.url;
  

        let accessToken = this.props.auth.accessToken;
        let apiUrl = api.blogPosts.updateBlogPost.prefix + this.props.currentWebsite.subdomain + '/' + blogPostUrlToServer + api.blogPosts.updateBlogPost.postfix;
        axios.patch(apiUrl, {meta_description: newMetaDescription, subdomain: this.props.currentWebsite.subdomain}, {...returnAuthHeaderForAJAX(accessToken)})
            .then(() => {
                // Server gives the object of the saved website page. Throw it in the store.
                this.props.saveBlogPostDataInStore({
                  data: {
                    ...this.props.currentBlogPost,
                    meta_description: newMetaDescription,
                  }
                }, this.props.currentBlog, this.props.blogPosts.items, this.props.currentBlogPost.url);

                let successMessage = <span>Successfully changed the page description to <b>{newMetaDescription}</b>.</span>;
                if(newMetaDescription === ''){
                    successMessage = <span>Successfully removed the page description. Now it's blank.</span>;
                }

                fireAnalyticsEvent.fireCrisp(CrispEvents.changePostMetaDescription, {
                  subdomain: this.props.currentWebsite.subdomain,
                  post_url: this.props.currentBlogPost.url,
                  meta_description: trimStringTo(newMetaDescription, 100),
                });
            })
            .catch(error => {
                if(error.response){
                    let errorData = error.response.data;



                    if (errorData.detail !== undefined){
                        let message = errorData.detail;

                        let errorObjectKey = getErrorKey(errorData);
                        let errorObjectValue = getErrorValue(errorData, errorObjectKey);

                        fireAnalyticsEvent.fireCrisp(CrispEvents.changePostMetaDescriptionError, {
                          error_type: errorObjectKey,
                          error_message: errorObjectValue,
                          subdomain: this.props.currentWebsite.subdomain,
                          meta_description: newMetaDescription,
                          post_url: blogPostUrlToServer,
                        }, true);

                        // This happens when user tries to change a website which doesn't exist or doesn't belog to the user.
                        openNotification('Server error', 'Error message: "' + message + '". This should not have happened. Please contact us.', 'OK', 'error');
                    }

                    if (errorData.locked !== undefined){
                        let message = errorData.locked;
                        openNotification('Denied', message, 'OK', 'warn');
                    }

                    if (errorData.meta_description !== undefined){
                        let message = errorData.meta_description.join(' ');
                        this.props.postMetaDescriptionUpdateErrorMessage(message);
                        this.props.postMetaDescriptionUpdateToggleInvalidAttempt(true);
                    }
                }
            })
            .then(response => {
                // always executed
                this.props.postMetaDescriptionUpdateWaitingForServer(false);
            });
    };

    handleInputChange = (e:any) => {
        this.props.setMetaDescription(e.target.value);
    };

    render(){
        return (
            <form onSubmit={this.handleMetaDescriptionUpdate} className="settings-box__form" style={{display: "flex", gap: "10px", width: "300px", alignItems: "flex-start"}}>
                <div className="settings-box__input_box" style={{margin: 0, flexGrow: 1}}>
                    <TextArea
                        name="meta_description"
                        id="meta_description"
                        required={false}
                        placeholder="Enter meta description"
                        autosize={{ minRows: 2, maxRows: 10 }}
                        value={this.props.meta_description}
                        onChange={this.handleInputChange}
                        style={{width: "100%", marginBottom: 0}}
                    />
                    <div className="settings-box__error_message" style={{opacity: this.props.blogPosts.postMetaDescriptionUpdateErrorMessage === undefined ? 0 : 1, marginTop: !this.props.blogPosts.postMetaDescriptionUpdateErrorMessage ? 0 : "3px"}}>
                        {this.props.blogPosts.postMetaDescriptionUpdateErrorMessage}
                    </div>
                </div>
                <Button
                    className="settings-box__form_save"
                    icon='save'
                    type="primary"
                    loading={this.props.blogPosts.isWaitingForPostMetaDescriptionUpdateResponse}
                    disabled={this.props.blogPosts.isWaitingForPostMetaDescriptionUpdateResponse}
                    htmlType="submit"
                    hidden={compareEditedValueWithCurrent(this.props.currentBlogPost.meta_description, this.props.meta_description)}
                    style={{bottom: 0}}
                />
            </form>
        )
    }
}

export default UpdatePostMetaDescriptionForm;
