import { projectUrl } from "../../data/constants";

export const getFullPageUrl = (currentWebsite:any, websitePageUrl:string) => {
    let protocol = 'https://';
    if (process.env.REACT_APP_API_URL === "http://app.unicorn.com:8000") {
        protocol = 'http://';
    }
    let subdomainUrl = protocol + currentWebsite["subdomain"] + '.' + (currentWebsite.free_root_domain || projectUrl) + '/' + websitePageUrl;
    let customDomainUrl = protocol + currentWebsite["custom_domain"] + '/' + websitePageUrl;
    
    let fullPageUrl = subdomainUrl;
    if(currentWebsite.is_custom_domain_active){
        fullPageUrl = customDomainUrl;
    }

    if (websitePageUrl !== '') {
        fullPageUrl = fullPageUrl + '/';
    }

    return fullPageUrl;
};
