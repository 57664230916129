import React, { useEffect, useRef, useState } from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import _ from "lodash";
import axios from "axios";
import classNames from "classnames";
import { isKeyValue } from "../../helpers/isKeyValue";
import { lowerCaseKeys } from "../../helpers/lowercaseKeys";
import { GetCurrentStoreData, WebsitesState } from "../../store/websites/types";
import { apiUrlBase, sheetsApi } from "../../data/urls";
import {
  DEFAULT_DIRECTORY_2_ITEM_HTML,
  DEFAULT_DIRECTORY_3_ITEM_HTML,
  DEFAULT_DIRECTORY_ITEM_HTML,
} from "../../data/constants";
import { convertToNumber } from "../../helpers/convertToNumber";
import { unescapeCommas } from "../../helpers/unescapeCommas";
import { checkIfStringContainsProtocol } from "../../helpers/checkIfStringContainsProtocol";
import { getActiveDomain } from "../../helpers/websites/getActiveDomain";
import { slugifyNew } from "../../helpers/strings/slugifyNew";
import { FilterData } from "../../helpers/types/globalTypes";
import { CmsType } from "../../enums/enums";

const DEBOUNCE_ROWS_COUNT = 100;

interface Props {
  websites: WebsitesState;
  componentItem: any;
  checkIsEmptyContent: any;
  saveContentInStore: any;
  connectDefaultFormIntegration: any; //we need this to connect an integration to forms in CTAs
  isDarkBg: boolean;
  getCurrentStoreData: GetCurrentStoreData;
}

interface ItemLoadingProps extends Props {}
const ItemLoading = (props: ItemLoadingProps) => {
  return <li className="directory-01__loading-item" />;
};

interface ShowMoreButtonProps extends Props {
  showAllItems: () => void;
  showMoreButtonText: string;
}
const ShowMoreButton = (props: ShowMoreButtonProps) => {
  const { isDarkBg, showAllItems, showMoreButtonText } = props;
  return (
    <a
      className={classNames("button", {
        "button--white-bg": isDarkBg,
        "button--black-bg": !isDarkBg,
      })}
      onClick={(e) => {
        e.preventDefault();
        showAllItems();
      }}
    >
      <span className="button__text">{showMoreButtonText || "Show all"}</span>
    </a>
  );
};

interface DirectoryItemProps extends Props {
  row: any;
  isShowMoreHidden: boolean;
  itemHtml: string;
  dynamicUrl: string;
  dynamicTarget: string;
  dynamicUrlPrefix: string;
  filtersV2Items: FilterData[];
}
const DirectoryItem = (props: DirectoryItemProps) => {
  const {
    isShowMoreHidden,
    itemHtml,
    row,
    dynamicUrl,
    dynamicTarget,
    dynamicUrlPrefix,
    getCurrentStoreData,
    filtersV2Items,
  } = props;
  const itemRef = useRef(null);
  const { currentWebsite } = getCurrentStoreData();

  function replaceWithObjectValues(html) {
    return html.replace(/\{\{(\w+)\}\}/g, function (match, p1) {
      let field = p1.toLowerCase();
      const isDynamicUrl = field === "dynamicurl";
      const isDynamicTarget = field === "dynamictarget";

      if (isDynamicUrl) {
        field = dynamicUrl;
      }

      let value = row[field];

      if (isDynamicUrl && !checkIfStringContainsProtocol(value)) {
        value = slugifyNew(value);
        if (!value.startsWith("/")) {
          value = `/${value}`;
        }
        if (dynamicUrlPrefix && dynamicUrlPrefix !== "no_prefix") {
          value = `/${dynamicUrlPrefix}${value}`;
        }
        value = "https://" + getActiveDomain(currentWebsite) + value;
        if (
          (field || "").toString().startsWith("{{") ||
          (dynamicUrlPrefix || "").toString().startsWith("{{")
        ) {
          value = "https://" + getActiveDomain(currentWebsite);
        }
      }

      if (isDynamicTarget) {
        value = "_blank";
        if (dynamicTarget === "same_tab") {
          value = "_self";
        }
      }

      return value;
    });
  }

  const parentClassName = Object.keys(row).reduce((acc, key) => {
    const rowValue = (row[key] || "").toString().trim();
    if (!!rowValue && rowValue.toString().toLowerCase() !== "false") {
      return `${acc} dir-has-${key.toString().trim().replace(/ /g, "-")}`;
    }
    return acc;
  }, "directory-item-parent");

  const filterAttribute = {};
  filtersV2Items.forEach((filter) => {
    filterAttribute[filter.column] = Array.isArray(row[filter.column])
      ? row[filter.column].join(";")
      : row[filter.column];
  });

  return (
    <li
      style={{
        display: isShowMoreHidden ? "none" : "block",
      }}
      ref={itemRef}
      dangerouslySetInnerHTML={{
        __html: replaceWithObjectValues(itemHtml),
      }}
      className={parentClassName}
      data-filters={JSON.stringify(filterAttribute)}
    />
  );
};

interface TagsNewProps {
  topSectionHtml: string;
  rows: any[];
  searchPlaceholder: string;
  componentItem: any;
  isDarkBg: boolean;
}
const TopSection = (props: TagsNewProps) => {
  const { topSectionHtml, rows, searchPlaceholder, componentItem, isDarkBg } =
    props;

  const formattedSearchPlaceholder = (searchPlaceholder || "")
    .toString()
    .replace(/{{amount}}/g, rows.length.toString());

  const replaceUrlVariables = (text: string) => {
    if (typeof text !== "string") return text;
    const variables = {
      "{{searchPlaceholder}}": formattedSearchPlaceholder
        .replace(/\\/g, "\\\\")
        .replace(/'/g, "&#39;")
        .replace(/"/g, '\\"'),
      "{{id}}": componentItem.id,
      "{{amount}}": rows.length,
      "{{debounce}}":
        rows.length > 500
          ? "600ms"
          : rows.length > DEBOUNCE_ROWS_COUNT
          ? "300ms"
          : "1ms",
      "{{isDarkBg}}": isDarkBg,
      "{{filtersV2Items}}": JSON.stringify(
        _.get(
          componentItem,
          "componentData.directory.content.filtersV2.items",
          []
        )
      ).replace(/'/g, "&#39;"),
    };
    return text.replace(
      /{{searchPlaceholder}}|{{amount}}|{{id}}|{{debounce}}|{{isDarkBg}}|{{filtersV2Items}}/g,
      (matched) => {
        return variables[matched];
      }
    );
  };

  return (
    <div
      dangerouslySetInnerHTML={{ __html: replaceUrlVariables(topSectionHtml) }}
    />
  );
};

const Directory04 = (props: Props) => {
  const {
    componentItem,
    checkIsEmptyContent,
    saveContentInStore,
    isDarkBg,
    getCurrentStoreData,
    websites,
  } = props;
  const generator = new generateContentDataObject();
  const [alpineKey, setAlpineKey] = useState(0);

  const isMountedRef = useRef(false);
  const fullRowsRef = useRef([]);

  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [randomizedRows, setRandomizedRows] = useState([]);

  if (!isMountedRef.current) {
    let defaultHtml = DEFAULT_DIRECTORY_ITEM_HTML;
    if (componentItem.title === "02") {
      defaultHtml = DEFAULT_DIRECTORY_2_ITEM_HTML;
    }
    if (componentItem.title === "03") {
      defaultHtml = DEFAULT_DIRECTORY_3_ITEM_HTML;
    }
    const { isDefaultEmpty } = componentItem;
    if (checkIsEmptyContent()) {
      saveContentInStore(generator.setUpTitle({ isDefaultEmpty }));
      saveContentInStore(generator.setUpSubtitleText({ isDefaultEmpty }));
      saveContentInStore(
        generator.setUpDirectory({
          isDefaultEmpty,
          directoryItemHtml: "",
          alpineCode: `<div style='display: flex; justify-content: center; align-items: center; height: 100px;'>Insert your Alpine.js code here</div>`,
        })
      );
      saveContentInStore(
        generator.addDirectoryFilterV2(componentItem.componentData, "filter1")
      );
    }
    saveContentInStore(
      generator.convertLegacyDirectoryToV2(componentItem.componentData)
    );
  }

  const replaceUrlVariables = (text: string) => {
    if (typeof text !== "string") return text;
    const { currentWebsite } = getCurrentStoreData();
    const variables = {
      "{{subdomain}}": _.get(currentWebsite, "subdomain"),
      "{{apiBase}}": apiUrlBase,
      "{{website_id}}": _.get(currentWebsite, "id"),
      "{{website_domain}}": getActiveDomain(currentWebsite),
    };
    return text.replace(
      /{{subdomain}}|{{apiBase}}|{{website_id}}|{{website_domain}}/g,
      (matched) => {
        return variables[matched];
      }
    );
  };

  const saveRowsToWindow = (rows) => {
    const componentId = componentItem.id;
    if (!window.uniDirectoryData) {
      window.uniDirectoryData = {
        [componentId]: rows,
      };
    } else {
      window.uniDirectoryData[componentId] = rows;
    }
  };

  const title = displayTitleMarkupText(
    componentItem.componentData.title.content.markup
  );
  const subtitleText = displaySubtitleMarkupText(
    componentItem.componentData.subtitleText.content.markup
  );
  const cmsUrl = _.get(
    componentItem,
    "componentData.directory.content.cmsUrl",
    ""
  );
  const maxItems = parseInt(
    _.get(componentItem, "componentData.directory.content.maxItems") || 4
  );
  const showMoreButtonText = _.get(
    componentItem,
    "componentData.directory.content.showMoreButtonText",
    ""
  );
  const itemHtml = _.get(
    componentItem,
    "componentData.directory.content.directoryItemHtml",
    ""
  );
  const sortingField = _.get(
    componentItem,
    "componentData.directory.content.sortingField",
    ""
  );
  const searchPlaceholder = _.get(
    componentItem,
    "componentData.directory.content.searchPlaceholder",
    ""
  );
  const filterFields = _.get(
    componentItem,
    "componentData.directory.content.filterFields",
    ""
  );
  const filterFieldsMode = _.get(
    componentItem,
    "componentData.directory.content.filterFieldsMode",
    "all"
  );
  const sortingOrder = _.get(
    componentItem,
    "componentData.directory.content.sortingOrder",
    "ascending"
  );
  const hasFeatureHideButton =
    _.get(
      componentItem,
      "componentData.directory.content.versions.button",
      1
    ) >= 2;
  const dynamicUrl = _.get(
    componentItem,
    "componentData.directory.content.dynamicUrl"
  );
  const dynamicTarget = _.get(
    componentItem,
    "componentData.directory.content.dynamicTarget"
  );
  const dynamicUrlPrefix = _.get(
    componentItem,
    "componentData.directory.content.dynamicUrlPrefix"
  );
  const updateFilterColumnsIndicator = _.get(
    componentItem,
    "componentData.directory.content.updateFilterColumnsIndicator",
    ""
  );
  const notFoundPlaceholder = _.get(
    componentItem,
    "componentData.directory.content.notFoundPlaceholder",
    ""
  );
  const version = _.get(
    componentItem,
    "componentData.directory.content.version"
  );
  const alpineCode = _.get(
    componentItem,
    "componentData.directory.content.alpineCode",
    ""
  );

  const saveFilters = () => {
    if (fullRowsRef.current.length === 0) return;
    const filtersV2Items = _.get(
      componentItem,
      "componentData.directory.content.filtersV2.items",
      []
    );
    filtersV2Items.forEach((filter) => {
      const filtersArray = [];
      fullRowsRef.current.forEach((row, i) => {
        const category = row[filter.column];
        if (!category) return;
        if (Array.isArray(category)) {
          filtersArray.push(...category);
        } else {
          filtersArray.push(
            ...category
              .toString()
              .split(";")
              .map((t) => t.trim())
          );
        }
      });
      const filters = [...[...new Set(filtersArray)]];
      saveContentInStore(
        generator.changeDirectoryFiltersV2(componentItem.componentData, {
          id: filter.id,
          filters,
        })
      );
    });
  };

  useEffect(() => {
    saveFilters();
  }, [updateFilterColumnsIndicator]);

  const formatGoogleSheetUrl = (url: string) => {
    if (typeof url !== "string") {
      return url;
    }
    if (!url.startsWith("https://docs.google.com/spreadsheets/")) {
      return url;
    }
    const outputParam = "output=csv";
    let result = url
      .replace("pubhtml", `pub?${outputParam}`)
      .replace("output=tsv", outputParam)
      .replace("output=pdf", outputParam)
      .replace("output=xlsx", outputParam)
      .replace("output=ods", outputParam);
    return `${sheetsApi}/get-pages?sheet_url=${encodeURIComponent(result)}&v=2`;
  };

  const filterRowsByFilterV2Fn = (row) => {
    let displayRow = true;
    const filtersV2Items = _.get(
      componentItem,
      "componentData.directory.content.filtersV2.items",
      []
    );
    const itemsWithShowByFilters = filtersV2Items.filter((item) => {
      return !!item.showByFilters && item.showByFilters.items.length > 0;
    });
    itemsWithShowByFilters.forEach((filterV2Item: FilterData) => {
      const { mode, items } = filterV2Item.showByFilters;
      if (mode === "all") {
        const condition = items
          .split(";")
          .filter((i) => !!i)
          .every((item) => {
            let value = row[filterV2Item.column] || "";
            if (Array.isArray(value)) {
              value = value.join(";");
            }
            return value
              .toString()
              .split(";")
              .map((t) => t.trim())
              .includes(item);
          });
        if (!condition) {
          displayRow = false;
        }
      } else {
        const condition = items
          .split(";")
          .filter((i) => !!i)
          .some((item) => {
            let value = row[filterV2Item.column] || "";
            if (Array.isArray(value)) {
              value = value.join(";");
            }
            return value
              .toString()
              .split(";")
              .map((t) => t.trim())
              .includes(item);
          });
        if (!condition) {
          displayRow = false;
        }
      }
    });
    return displayRow;
  };

  const filterRowsByFieldsFn = (row) => {
    const checkFilterFieldsFn = (filterField) => {
      let isNegative = false;
      let field = filterField;
      if (filterField.startsWith("-")) {
        field = filterField.slice(1);
        isNegative = true;
      }
      if (!(field in row)) return true;
      const value = (row[field] || "").toString().trim();
      if (!value) return isNegative;
      if (value.toLowerCase() === "false") return isNegative;
      return !isNegative;
    };
    if (typeof filterFields !== "string" || !filterFields.trim()) {
      return true;
    }
    const filterFieldsArray = filterFields.split(";").map((t) => t.trim());
    if (filterFieldsMode === "any") {
      return filterFieldsArray.some(checkFilterFieldsFn);
    }
    return filterFieldsArray.every(checkFilterFieldsFn);
  };

  const filterRowsGeneralFn = (row) => {
    return filterRowsByFieldsFn(row) && filterRowsByFilterV2Fn(row);
  };

  useEffect(() => {
    isMountedRef.current = true;
  }, []);

  useEffect(() => {
    setRows([...fullRowsRef.current].filter(filterRowsGeneralFn));
  }, [filterFields, filterFieldsMode]);

  const resetItemsVisibilityToDefault = () => {
    const rows = fullRowsRef.current.filter(filterRowsGeneralFn);
    document
      .querySelectorAll<HTMLElement>(
        `#${componentItem.id} .directory-item-parent`
      )
      .forEach((item, i) => {
        item.style.display =
          i < (isNaN(maxItems) ? 4 : maxItems) ? "block" : "none";
      });
    const showAllButton: HTMLElement = document.querySelector(
      `#${componentItem.id} .directory-01__show-more-box`
    );
    if (showAllButton) {
      showAllButton.style.display =
        (isNaN(maxItems) ? 4 : maxItems) < rows.length &&
        (showMoreButtonText || !hasFeatureHideButton) &&
        rows.length > 0
          ? "flex"
          : "none";
    }
  };

  const showAllItems = () => {
    document
      .querySelectorAll<HTMLElement>(
        `#${componentItem.id} .directory-item-parent`
      )
      .forEach((item) => {
        item.style.display = "block";
      });
    const showAllButton: HTMLElement = document.querySelector(
      `#${componentItem.id} .directory-01__show-more-box`
    );
    if (showAllButton) {
      showAllButton.style.display = "none";
    }
  };

  const updateItemsIndicator = _.get(
    componentItem,
    "componentData.directory.content.updateItemsIndicator",
    ""
  );
  const updateTopSectionIndicator = _.get(
    componentItem,
    "componentData.directory.content.updateTopSectionIndicator",
    ""
  );
  const cmsType =
    _.get(componentItem, "componentData.directory.content.cmsType") ||
    CmsType.GOOGLE_SHEETS;
  const unicornCollectionId = _.get(
    componentItem,
    "componentData.directory.content.unicornCollectionId",
    ""
  );
  const customApiUrl = _.get(
    componentItem,
    "componentData.directory.content.customApiUrl",
    ""
  );

  useEffect(() => {
    setRows([...fullRowsRef.current].filter(filterRowsGeneralFn));
    resetItemsVisibilityToDefault();
  }, [updateItemsIndicator]);

  useEffect(() => {
    setRows([]);
    fullRowsRef.current = [];
    setIsLoading(false);
    saveRowsToWindow([]);
    setAlpineKey(Math.floor(Math.random() * 10000) + 1);
    let formattedCmsUrl = "";
    const headers: any = {};
    if (cmsType === CmsType.UNICORN) {
      if (!unicornCollectionId) return;
      formattedCmsUrl = `${sheetsApi}/cms/items?collection_id=${unicornCollectionId}`;
      headers["Cache-Control"] = "no-cache";
    }
    if (cmsType === CmsType.GOOGLE_SHEETS) {
      if (!cmsUrl) return;
      formattedCmsUrl = formatGoogleSheetUrl(replaceUrlVariables(cmsUrl));
    }
    if (cmsType === CmsType.CUSTOM) {
      if (!customApiUrl) return;
      formattedCmsUrl = replaceUrlVariables(customApiUrl);
    }
    if (
      !(
        formattedCmsUrl.startsWith("https://") ||
        formattedCmsUrl.startsWith("http://")
      )
    ) {
      return;
    }
    setIsLoading(true);
    axios
      .get(formattedCmsUrl, { headers })
      .then((response) => {
        let rows = [];

        let type = "text";
        if (Array.isArray(response.data)) {
          type = "jsonArray";
        }
        if (isKeyValue(response.data)) {
          type = "jsonObject";
        }

        let data = response.data;

        if (type === "jsonArray") {
          rows = data.map((row) => {
            return lowerCaseKeys(row);
          });
        } else if (type === "jsonObject") {
          if (
            cmsType === CmsType.GOOGLE_SHEETS &&
            formattedCmsUrl &&
            formattedCmsUrl.startsWith(`${sheetsApi}/get-pages`)
          ) {
            const items = _.get(data, "items", []);
            rows = items.map((row) => {
              return lowerCaseKeys(row);
            });
          } else {
            rows = [lowerCaseKeys(data)];
          }
        } else {
          rows = window.Papa.parse(data, {
            header: true,
          }).data.map((row) => {
            return lowerCaseKeys(unescapeCommas(row));
          });
        }

        rows.forEach((row) => {
          if ("title" in row) {
            row.slugified_title = slugifyNew(row.title);
          }
        });

        fullRowsRef.current = rows;

        saveContentInStore(
          generator.changeDirectoryAvailableColumns(
            componentItem.componentData,
            Object.keys(rows[0] || {})
          )
        );
        saveRowsToWindow(rows);
        rows = rows.filter(filterRowsGeneralFn);
        setRows(rows);
        setIsLoading(false);
        saveContentInStore(
          generator.changeDirectoryCurrentRowsSample(
            componentItem.componentData,
            rows.slice(0, 10)
          )
        );
        resetItemsVisibilityToDefault();
        saveFilters();
      })
      .catch((error) => {
        console.error(error);
        saveContentInStore(
          generator.changeDirectoryFilteredTags(componentItem.componentData, [])
        );
        setRows([]);
      })
      .finally(() => {
        setAlpineKey(Math.floor(Math.random() * 10000) + 1);
      })
  }, [
    cmsUrl,
    cmsType,
    unicornCollectionId,
    customApiUrl,
    websites.cmsRerenderKey,
  ]);

  useEffect(() => {
    setRandomizedRows([...rows].sort(() => Math.random() - 0.5));
  }, [rows.length]);

  return (
    <div
      className={classNames("directory-04", {
        "dir-is-loading": isLoading,
      })}
      dangerouslySetInnerHTML={{
        __html: (alpineCode || "").replace(/{{id}}/g, componentItem.id),
      }}
      key={`${componentItem.id}-${alpineKey}`}
    />
  );
};

export default Directory04;
