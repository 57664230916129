import { projectUrl } from "../data/constants";
import addNoCacheParamToURL from "./editor/addNoCacheParamToURL";
import { getWebsiteProtocol } from "./getWebsiteProtocol";

export const getCurrentWebsiteLiveUrl = (currentWebsite: any, shouldAddRandomCacheParam?: boolean) => {
  let currentWebsitePageUrl = '';
  if(currentWebsite.is_custom_domain_active){
      let websiteProtocol = getWebsiteProtocol(currentWebsite);
      currentWebsitePageUrl = websiteProtocol + currentWebsite.custom_domain + '/';
  }else{
      currentWebsitePageUrl = 'https://' + currentWebsite.subdomain + '.' + (currentWebsite.free_root_domain || projectUrl) + '/';
  }
  if(shouldAddRandomCacheParam){
    return addNoCacheParamToURL(currentWebsitePageUrl);
  }else{
    return currentWebsitePageUrl;
  }
};
