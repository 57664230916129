export enum LoadStatus {
  NO_DATA = "NO_DATA",
  NOT_LOADED = "NOT_LOADED",
  IN_PROGRESS = "IN_PROGRESS",
  LOADED = "LOADED",
  ERROR = "ERROR",
  DELETED = "DELETED",
}

export enum CmsType {
  UNICORN = "unicorn",
  GOOGLE_SHEETS = "googleSheets",
  CUSTOM = "custom",
}
