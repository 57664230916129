export const createScript = (src: string, defer = true, async = true) => {
  let script = document.querySelector(
    `script[src="${src}"]`
  ) as HTMLScriptElement;
  if (script) return;
  script = document.createElement("script");
  script.src = src;
  script.async = async;
  script.defer = defer;
  document.head.append(script);
};
