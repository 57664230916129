import React, { FormEventHandler, useEffect, useState } from "react";
import SettingsBox from "./SettingsBox";
import { Button, Input, InputNumber, List, Switch, notification } from "antd";
import { createAdminAccessAsync } from "../../helpers/async/createAdminAccessAsync";
import { connect } from "react-redux";
import { AuthState } from "../../store/auth/types";
import _ from "lodash";
import { openNotification } from "../../helpers/openNotification";
import {
  addNewSharedAccessItemInState,
  removeSharedAccessItemFromState,
  toggleSharedAccessChangeWaitingForServer,
} from "../../store/sharedAccesses/actions";
import { SharedAccessesState } from "../../store/sharedAccesses/types";
import { GetCurrentStoreData, WebsitesState } from "../../store/websites/types";
import { deleteAdminAccessAsync } from "../../helpers/async/deleteAdminAccessAsync";
import {
  DEFAULT_ERROR_MESSAGE,
  notificationDuration,
} from "../../data/constants";
import { ActiveMenu } from "./SettingsDrawerContent";
import { getCurrentStoreData } from "../../store/websites/thunks";
import { changeWebsitePagesStateGlobalData } from "../../store/websitePages/actions";
import {
  ChangeWebsitePagesStateGlobalData,
  PageTemplateData,
  WebsitePagesState,
} from "../../store/websitePages/types";
import { updatePageAsync } from "../../helpers/async/updatePageAsync";
import ImagePicker from "../editor/ImagePicker";

interface Props {
  auth: AuthState;
  websites: WebsitesState;
  websitesPages: WebsitePagesState;
  sharedAccesses: SharedAccessesState;
  addNewSharedAccessItemInState: any;
  removeSharedAccessItemFromState: any;
  toggleSharedAccessChangeWaitingForServer: any;
  getCurrentStoreData: GetCurrentStoreData;
  changeWebsitePagesStateGlobalData: ChangeWebsitePagesStateGlobalData;

  currentMenuTitle: ActiveMenu;
  saveWebsitePageDataInStore: any;
}

const InternalSettings = (props: Props): JSX.Element => {
  const {
    auth,
    addNewSharedAccessItemInState,
    sharedAccesses,
    removeSharedAccessItemFromState,
    toggleSharedAccessChangeWaitingForServer,
    currentMenuTitle,
    getCurrentStoreData,
    changeWebsitePagesStateGlobalData,
    websitesPages,
    saveWebsitePageDataInStore,
  } = props;
  const { currentWebsitePage, currentWebsite } = getCurrentStoreData();
  const currentPageTemplateData: PageTemplateData = _.get(
    currentWebsitePage,
    "page_template_data",
    {}
  );
  const [value, setValue] = useState<string>("");
  const [templateTitle, setTemplateTitle] = useState<string>(
    currentPageTemplateData.title || ""
  );
  const [templatePriority, setTemplatePriority] = useState<number>(
    currentPageTemplateData.priority === undefined
      ? 100
      : currentPageTemplateData.priority
  );
  const [templateDescription, setTemplateDescription] = useState<string>(
    currentPageTemplateData.description || ""
  );
  const [templateKeywords, setTemplateKeywords] = useState<string>(
    currentPageTemplateData.keywords || ""
  );

  const handleOnSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (!value) return;
    toggleSharedAccessChangeWaitingForServer(true);
    createAdminAccessAsync({ accessToken: auth.accessToken, websiteId: value })
      .then((response) => {
        setValue("");
        addNewSharedAccessItemInState(response.data.new_access);
      })
      .catch((error) => {
        console.error(error);
        const message = _.get(
          error,
          "response.data.message",
          DEFAULT_ERROR_MESSAGE
        );
        openNotification(
          "Error",
          message,
          "OK",
          "error",
          notificationDuration.short
        );
      })
      .finally(() => {
        toggleSharedAccessChangeWaitingForServer(false);
      });
  };

  const handleOnDelete = (itemId) => {
    toggleSharedAccessChangeWaitingForServer(true);
    deleteAdminAccessAsync({ accessToken: auth.accessToken, itemId })
      .then(() => {
        removeSharedAccessItemFromState(itemId);
      })
      .catch((error) => {
        console.error(error);
        const message = _.get(
          error,
          "response.data.message",
          DEFAULT_ERROR_MESSAGE
        );
        openNotification(
          "Error",
          message,
          "OK",
          "error",
          notificationDuration.short
        );
      })
      .finally(() => {
        toggleSharedAccessChangeWaitingForServer(false);
      });
  };

  const accesses = sharedAccesses.items.filter((item) => item.is_admin_access);

  if (currentMenuTitle === "internal__admin") {
    return (
      <div className="settings__container">
        <SettingsBox titleText="Admin">
          <div className="settings-box__input">
            <div className="settings-box__input_label">
              <div
                className="settings-box__input_info_text"
                style={{ margin: 0 }}
              >
                Get access to any website by URL or domain:
              </div>
            </div>
            <form
              onSubmit={handleOnSubmit}
              style={{ display: "flex", gap: "10px", marginBottom: "10px" }}
            >
              <Input
                placeholder="unicornplatform.com"
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
                style={{ flexGrow: 1 }}
              />
              <Button
                htmlType="submit"
                loading={sharedAccesses.isWaitingForChangeSharedAccessResponse}
                disabled={sharedAccesses.isWaitingForChangeSharedAccessResponse}
              >
                Get full access
              </Button>
            </form>
            {accesses.length > 0 && (
              <List
                bordered
                size="small"
                dataSource={accesses}
                renderItem={(item) => {
                  return (
                    <List.Item
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <div>
                        {item.details.custom_domain ? item.details.custom_domain : `${item.details.subdomain}`} ({item.website})
                        <br />
                        <div className="account_fields__value">
                          {item.owner_email}
                        </div>
                      </div>
                      <Button
                        icon="delete"
                        type="danger"
                        ghost
                        onClick={() => {
                          handleOnDelete(item.id);
                        }}
                        loading={
                          sharedAccesses.isWaitingForChangeSharedAccessResponse
                        }
                      />
                    </List.Item>
                  );
                }}
              />
            )}
          </div>
        </SettingsBox>
      </div>
    );
  }

  const updatePage = (newData) => {
    if (websitesPages.isWaitingPageTemplateResponse) return;
    changeWebsitePagesStateGlobalData({
      data: {
        isWaitingPageTemplateResponse: true,
      },
    });
    updatePageAsync({
      accessToken: auth.accessToken,
      subdomain: currentWebsite.subdomain,
      data: newData,
      pageUrl: currentWebsitePage.url,
    })
      .then(() => {
        const dataToStore = {
          data: {
            ...currentWebsitePage,
            ...newData,
          },
        };
        saveWebsitePageDataInStore(
          dataToStore,
          currentWebsite,
          websitesPages.items,
          currentWebsitePage.url
        );
      })
      .catch((error) => {
        console.error(error.message);

        const adminValidationMessage = _.get(
          error,
          "response.data.not_admin",
          ""
        );
        if (adminValidationMessage) {
          openNotification(
            "Error",
            adminValidationMessage,
            "OK",
            "error",
            notificationDuration.short
          );
          return;
        }

        const message = _.get(
          error,
          "response.data.message",
          DEFAULT_ERROR_MESSAGE
        );
        
        openNotification(
          "Error",
          message,
          "OK",
          "error",
          notificationDuration.short
        );
      })
      .finally(() => {
        changeWebsitePagesStateGlobalData({
          data: {
            isWaitingPageTemplateResponse: false,
          },
        });
      });
  };

  if (currentMenuTitle === "internal__pageTemplate") {
    return (
      <div className="settings__container">
        <SettingsBox titleText="Page template">
          <div className="settings-box__input">
            <div className="settings-box__input_label">
              <div
                className="settings-box__input_info_text"
                style={{ margin: 0 }}
              >
                Add "/{currentWebsitePage.url}" to templates gallery:
              </div>
            </div>
            <div>
              <Switch
                loading={websitesPages.isWaitingPageTemplateResponse}
                checked={currentWebsitePage.is_page_template}
                onChange={(checked) => {
                  updatePage({ is_page_template: checked });
                }}
              />
            </div>
          </div>
          <div className="settings-box__input">
            <div className="settings-box__input_label">
              <div
                className="settings-box__input_info_text"
                style={{ margin: 0 }}
              >
                Title:
              </div>
            </div>
            <div>
              <form
                className="settings-box__form"
                onSubmit={(e) => {
                  e.preventDefault();
                  updatePage({
                    page_template_data: {
                      ...currentPageTemplateData,
                      title: templateTitle,
                    },
                  });
                }}
              >
                <div className="settings-box__input_box full-width">
                  <Input
                    value={templateTitle}
                    onChange={(e) => setTemplateTitle(e.target.value)}
                    placeholder="Pricing"
                  />
                </div>
                <Button
                  className="settings-box__form_save"
                  icon="save"
                  type="primary"
                  loading={websitesPages.isWaitingPageTemplateResponse}
                  disabled={websitesPages.isWaitingPageTemplateResponse}
                  htmlType="submit"
                  hidden={
                    (currentPageTemplateData.title || "") === templateTitle
                  }
                />
              </form>
            </div>
          </div>
          <div className="settings-box__input">
            <div className="settings-box__input_label">
              <div
                className="settings-box__input_info_text"
                style={{ margin: 0 }}
              >
                Thumbnail:
              </div>
            </div>
            <div>
              <ImagePicker
                currentImgSrc={currentPageTemplateData.thumbnail || ""}
                saveImageInReduxStore={(uploadedImgSrc) => {
                  updatePage({
                    page_template_data: {
                      ...currentPageTemplateData,
                      thumbnail: uploadedImgSrc,
                    },
                  });
                }}
                inputId="page-template-thumbnail"
                saveImageInfoInDB={() => {}}
                currentImgUUID={null}
                removeImageFromDB={() => {}}
              />
            </div>
          </div>
          <div className="settings-box__input">
            <div className="settings-box__input_label">
              <div
                className="settings-box__input_info_text"
                style={{ margin: 0 }}
              >
                Priority (higher first):
              </div>
            </div>
            <div>
              <form
                className="settings-box__form"
                onSubmit={(e) => {
                  e.preventDefault();
                  updatePage({
                    page_template_data: {
                      ...currentPageTemplateData,
                      priority: templatePriority,
                    },
                  });
                }}
              >
                <div className="settings-box__input_box full-width">
                  <InputNumber
                    value={templatePriority}
                    onChange={setTemplatePriority}
                    placeholder="100"
                  />
                </div>
                <Button
                  className="settings-box__form_save"
                  icon="save"
                  type="primary"
                  loading={websitesPages.isWaitingPageTemplateResponse}
                  disabled={websitesPages.isWaitingPageTemplateResponse}
                  htmlType="submit"
                  hidden={
                    (currentPageTemplateData.priority === undefined
                      ? 100
                      : currentPageTemplateData.priority) === templatePriority
                  }
                />
              </form>
            </div>
          </div>
          <div className="settings-box__input">
            <div className="settings-box__input_label">
              <div
                className="settings-box__input_info_text"
                style={{ margin: 0 }}
              >
                Description:
              </div>
            </div>
            <div>
              <form
                className="settings-box__form"
                onSubmit={(e) => {
                  e.preventDefault();
                  updatePage({
                    page_template_data: {
                      ...currentPageTemplateData,
                      description: templateDescription,
                    },
                  });
                }}
              >
                <div className="settings-box__input_box full-width">
                  <Input
                    value={templateDescription}
                    onChange={(e) => setTemplateDescription(e.target.value)}
                    placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                  />
                </div>
                <Button
                  className="settings-box__form_save"
                  icon="save"
                  type="primary"
                  loading={websitesPages.isWaitingPageTemplateResponse}
                  disabled={websitesPages.isWaitingPageTemplateResponse}
                  htmlType="submit"
                  hidden={
                    (currentPageTemplateData.description || "") ===
                    templateDescription
                  }
                />
              </form>
            </div>
          </div>
          <div className="settings-box__input">
            <div className="settings-box__input_label">
              <div
                className="settings-box__input_info_text"
                style={{ margin: 0 }}
              >
                Keywords:
              </div>
            </div>
            <div>
              <form
                className="settings-box__form"
                onSubmit={(e) => {
                  e.preventDefault();
                  updatePage({
                    page_template_data: {
                      ...currentPageTemplateData,
                      keywords: templateKeywords,
                    },
                  });
                }}
              >
                <div className="settings-box__input_box full-width">
                  <Input
                    value={templateKeywords}
                    onChange={(e) => setTemplateKeywords(e.target.value)}
                    placeholder="lorem ipsum dolor sit amet"
                  />
                </div>
                <Button
                  className="settings-box__form_save"
                  icon="save"
                  type="primary"
                  loading={websitesPages.isWaitingPageTemplateResponse}
                  disabled={websitesPages.isWaitingPageTemplateResponse}
                  htmlType="submit"
                  hidden={
                    (currentPageTemplateData.keywords || "") ===
                    templateKeywords
                  }
                />
              </form>
            </div>
          </div>
        </SettingsBox>
      </div>
    );
  }

  return null;
};

const mapStateToProps = (state: Props) => ({
  auth: state.auth,
  sharedAccesses: state.sharedAccesses,
  websites: state.websites,
  websitesPages: state.websitesPages,
});
export default connect(mapStateToProps, {
  addNewSharedAccessItemInState,
  removeSharedAccessItemFromState,
  toggleSharedAccessChangeWaitingForServer,
  getCurrentStoreData,
  changeWebsitePagesStateGlobalData,
})(InternalSettings);
