export const getFullTemplatePageUrl = (currentTemplateSubdomain:any, websitePageUrl:string, currentWebsiteFreeRootDomain:string) => {
    let subdomainUrl = 'https://' + currentTemplateSubdomain + '.' + currentWebsiteFreeRootDomain + '/' + websitePageUrl;

    let fullTemplatePageUrl = subdomainUrl;

    if (websitePageUrl !== '') {
        fullTemplatePageUrl = fullTemplatePageUrl + '/';
    }

    return fullTemplatePageUrl;
};
