import React, { Component } from "react";
import { Button, Input, Select } from "antd";
import { api } from "../../data/urls";
import { getCurrentWebsiteSubdomain } from "../../helpers/getCurrentWebsiteSubdomain";
import axios from "axios";
import returnAuthHeaderForAJAX from "../../helpers/auth/returnAuthHeaderForAJAX";
import { openNotification } from "../../helpers/openNotification";
import fireAnalyticsEvent from "../../helpers/editor/fireAnalyticsEvent";
import { getErrorValue } from "../../helpers/editor/getErrorValue";
import { getErrorKey } from "../../helpers/editor/getErrorKey";
import MyButton from "../MyButton";
import { getWebsiteProtocol } from "../../helpers/getWebsiteProtocol";
import { handleBadWebsiteError } from "../../helpers/handleBadWebsiteError";
import { CrispEvents } from "../../enums/AnalyticsEventsEnums";
import { connect } from "react-redux";
import {
  subdomainUpdateErrorMessage,
  subdomainUpdateToggleInvalidAttempt,
  subdomainUpdateWaitingForServer,
  toggleWebsitesFetched,
} from "../../store/websites/actions";
import { savePartialWebsiteData } from "../../store/websites/thunks";
import {
  SavePartialWebsiteData,
  WebsitesItem,
} from "../../store/websites/types";
import { getCurrentWebsite } from "../../helpers/getCurrentWebsite";
import { WebsitePagesState } from "../../store/websitePages/types";
import { getCurrentWebsitePageUrl } from "../../helpers/getCurrentWebsitePageUrl";
import _ from "lodash";
import { projectUrl } from "../../data/constants";
import { MetaState } from "../../store/meta/types";

interface Props {
  websites: any;
  auth: any;
  meta: MetaState;
  websitesPages: WebsitePagesState;
  subdomainUpdateToggleInvalidAttempt: any;
  subdomainUpdateErrorMessage: any;
  toggleWebsitesFetched: any;
  subdomainUpdateWaitingForServer: any;
  savePartialWebsiteData: SavePartialWebsiteData;

  currentWebsiteSubdomain: string;
  history: any;
  currentWebsite: WebsitesItem;
}
type MyState = {
  subdomain: string;
  freeRootDomain: string;
};
class UpdateSubdomainForm extends Component<Props, MyState> {
  componentWillMount(): void {
    this.setState({
      subdomain: this.props.currentWebsiteSubdomain,
      freeRootDomain: this.props.currentWebsite.free_root_domain || projectUrl,
    });
  }
  clearUpdateSubdomainInput = (): void => {
    this.props.subdomainUpdateToggleInvalidAttempt(false);
    this.props.subdomainUpdateErrorMessage(undefined);
  };

  componentWillReceiveProps(
    nextProps: Readonly<Props>,
    nextContext: any
  ): void {
    if (
      this.props.currentWebsiteSubdomain !== nextProps.currentWebsiteSubdomain
    ) {
      this.setState({ subdomain: nextProps.currentWebsiteSubdomain });
      this.clearUpdateSubdomainInput();
    }
  }

  handleSubdomainUpdate = (e: any): void => {
    e.preventDefault();
    if (
      this.props.currentWebsiteSubdomain === this.state.subdomain &&
      this.state.freeRootDomain ===
        (this.props.currentWebsite.free_root_domain || projectUrl)
    ) {
      return;
    }
    const currentWebsite = getCurrentWebsite(this.props.websites.items);
    const currentPageUrl = getCurrentWebsitePageUrl(
      currentWebsite,
      this.props.websitesPages.items
    );
    const oldSubdomain = currentWebsite.subdomain;
    this.props.subdomainUpdateWaitingForServer(true);

    this.clearUpdateSubdomainInput();

    let newSubdomain = this.state.subdomain
      .toString()
      .toLowerCase()
      .replace(" ", "");

    let accessToken = this.props.auth.accessToken;
    let apiUrl =
      api.websites.updateWebsite.prefix +
      getCurrentWebsiteSubdomain(this.props.websites.items) +
      api.websites.updateWebsite.postfix;
    axios
      .patch(
        apiUrl,
        {
          subdomain: newSubdomain,
          free_root_domain: this.state.freeRootDomain,
        },
        { ...returnAuthHeaderForAJAX(accessToken) }
      )
      .then(() => {
        const dataToStore: Partial<WebsitesItem> = {
          ...currentWebsite,
          subdomain: newSubdomain,
          free_root_domain: this.state.freeRootDomain,
        };
        this.props.savePartialWebsiteData({
          data: dataToStore,
          websiteId: currentWebsite.id,
        });

        const newUrl = this.props.history.location.pathname.replace(
          `/${oldSubdomain}/`,
          `/${newSubdomain}/`
        );
        this.props.history.replace(newUrl);

        fireAnalyticsEvent.fireCrisp(CrispEvents.changeSubdomain, {
          new_subdomain: newSubdomain,
        });
      })
      .catch((error) => {
        if (error.response) {
          let errorData = error.response.data;
          handleBadWebsiteError(errorData);

          let errorObjectKey = getErrorKey(errorData);
          let errorObjectValue = getErrorValue(errorData, errorObjectKey);

          fireAnalyticsEvent.fireCrisp(
            CrispEvents.changeSubdomainError,
            {
              error_type: errorObjectKey,
              error_message: errorObjectValue,
              new_subdomain: newSubdomain,
            },
            true
          );

          if (errorData.detail !== undefined) {
            let message = errorData.detail;

            openNotification(
              "Server error",
              'Error message: "' +
                message +
                '". This should not have happened. Please contact us.',
              "OK",
              "error"
            );
          }

          if (errorData.locked !== undefined) {
            let message = errorData.locked;
            openNotification("Denied", message, "OK", "warn");
          }

          if (errorData.subdomain !== undefined) {
            let message = errorData.subdomain.join(" ");
            this.props.subdomainUpdateErrorMessage(message);
            this.props.subdomainUpdateToggleInvalidAttempt(true);
          }
        }
      })
      .then((response) => {
        this.props.subdomainUpdateWaitingForServer(false);
      });
  };

  handleInputChange = (e: any) => {
    this.setState({ subdomain: e.target.value });
  };

  handleFreeRootDomainChange = (value: string) => {
    this.setState({ freeRootDomain: value });
  };

  render() {
    const freeRootDomains = _.get(
      this.props.meta,
      "items.0.free_root_domains",
      []
    );
    const domainsJsx = freeRootDomains.map((domain: string) => (
      <Select.Option value={domain} key={domain}>
        {domain}
      </Select.Option>
    ));
    const subdomain = _.get(this.props.currentWebsite, "subdomain", "");
    const freeRootDomain = _.get(this.props.currentWebsite, "free_root_domain") || projectUrl;
    const fullUrl = `https://${subdomain}.${freeRootDomain}`;
    return (
      <form
        onSubmit={this.handleSubdomainUpdate}
        className="settings-box__form"
      >
        <div className="settings-box__input_box full-width" style={{marginRight: 10}}>
          <div style={{ display: "flex", gap: 10 }}>
            <Input
              name="subdomain"
              id="subdomain"
              required={false}
              placeholder="Subdomain"
              type="text"
              value={this.state.subdomain}
              onChange={this.handleInputChange}
              style={{
                flex: 1,
              }}
            />
            <Select
              style={{ width: 200 }}
              defaultValue={this.state.freeRootDomain}
              onChange={this.handleFreeRootDomainChange}
            >
              {domainsJsx}
            </Select>
          </div>
          <div
            className="settings-box__error_message"
            style={{
              opacity:
                this.props.websites.subdomainUpdateErrorMessage === undefined
                  ? 0
                  : 1,
            }}
          >
            {this.props.websites.subdomainUpdateErrorMessage}
          </div>
          <div style={{ marginTop: 7, maxWidth: '100%', overflow: 'hidden' }}>
            <a
              href={fullUrl + "/"}
              target="_blank"
              style={{
                textDecoration: 'underline',
                fontSize: 14,
                display: 'inline-block',
                maxWidth: 350,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              {fullUrl}
            </a>
          </div>
        </div>
        <Button
          className="settings-box__form_save"
          icon="save"
          type="primary"
          loading={this.props.websites.isWaitingForSubdomainUpdateResponse}
          htmlType="submit"
          hidden={
            this.props.currentWebsiteSubdomain === this.state.subdomain &&
            this.state.freeRootDomain ===
              (this.props.currentWebsite.free_root_domain || projectUrl)
          }
        />
      </form>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    websites: state.websites,
    auth: state.auth,
    websitesPages: state.websitesPages,
    meta: state.meta,
  };
};
const mapActionsToProps = {
  subdomainUpdateToggleInvalidAttempt,
  subdomainUpdateErrorMessage,
  toggleWebsitesFetched,
  subdomainUpdateWaitingForServer,
  savePartialWebsiteData,
};
export default connect(mapStateToProps, mapActionsToProps)(UpdateSubdomainForm);
