import React, { useState } from "react";
import { connect } from "react-redux";
import { getCurrentStoreData } from "../../store/websites/thunks";
import {
  ChangeWebsiteItemGlobalData,
  ChangeWebsitesStateGlobalData,
  GetCurrentStoreData,
} from "../../store/websites/types";
import "./CmsSidebar.scss";
import { replaceDemoCollectionIds } from "../../store/websitePages/actions";
import {
  changeWebsiteItemGlobalData,
  changeWebsitesStateGlobalData,
} from "../../store/websites/actions";
import { AuthState } from "../../store/auth/types";
import SpinnerBox from "./SpinnerBox";

interface Props {
  auth: AuthState;
  getCurrentStoreData: GetCurrentStoreData;
  replaceDemoCollectionIds;
  changeWebsiteItemGlobalData: ChangeWebsiteItemGlobalData;
  changeWebsitesStateGlobalData: ChangeWebsitesStateGlobalData;
}

const CmsSidebar = (props: Props): JSX.Element => {
  const { getCurrentStoreData } = props;
  const { currentWebsite } = getCurrentStoreData();
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className="cms-sidebar">
      {isLoading && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <SpinnerBox text="Loading collections..." />
        </div>
      )}
      <iframe
        src={`${window.location.origin}/mars/${currentWebsite.id}/collections/`}
        onLoad={() => setIsLoading(false)}
      ></iframe>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps, {
  getCurrentStoreData,
  replaceDemoCollectionIds,
  changeWebsiteItemGlobalData,
  changeWebsitesStateGlobalData,
})(CmsSidebar);
