import { projectUrl } from "../../data/constants";
import { WebsitesItem } from "../../store/websites/types";
import trimStringTo from "../strings/trimStringTo";

// Return custom domain if it's active, otherwise return full subdomain
export const getActiveDomain = (website: WebsitesItem, trimTo?: number) => {
  if (!website) {
    return "";
  }
  let domain = `${website.subdomain}.${(website.free_root_domain || projectUrl)}`;
  if (website.is_custom_domain_active && website.custom_domain) {
    domain = website.custom_domain;
  }
  if (trimTo) {
    return trimStringTo(domain, trimTo);
  }
  return domain;
};
