import { CmsType } from "../enums/enums";
import { WebsitePagesState } from "../store/websitePages/types";
import { WebsitesItem } from "../store/websites/types";

export const getCurrentWebsitesDynamicPages = (
  websitesPages: WebsitePagesState,
  currentWebsite: WebsitesItem
) => {
  return websitesPages.items.filter((item) => {
    const { cms } = item;
    return (
      item.website === currentWebsite.id &&
      !item.is_deleted &&
      (
        !!item.api_source_url && !cms.cmsType ||
        cms.cmsType === CmsType.UNICORN && cms.unicorn && cms.unicorn.collectionId ||
        cms.cmsType === CmsType.GOOGLE_SHEETS && cms.googleSheets && cms.googleSheets.url ||
        cms.cmsType === CmsType.CUSTOM && cms.customApi && cms.customApi.url
      )
    );
  });
};
