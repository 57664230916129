import Clients01 from "../pageComponents/clients/Clients01";
import Clients02 from "../pageComponents/clients/Clients02";
import Clients03 from "../pageComponents/clients/Clients03";
import Code01 from "../pageComponents/code/Code01";
import Code02 from "../pageComponents/code/Code02";
import Contact01 from "../pageComponents/contact/Contact01";
import Contact02 from "../pageComponents/contact/Contact02";
import CtaButton01 from "../pageComponents/cta_button/CtaButton01";
import CtaButton03 from "../pageComponents/cta_button/CtaButton03";
import CtaButton12 from "../pageComponents/cta_button/CtaButton12";
import CtaButton16 from "../pageComponents/cta_button/CtaButton16";
import CtaButton21 from "../pageComponents/cta_button/CtaButton21";
import CtaButton25 from "../pageComponents/cta_button/CtaButton25";
import CtaForm01 from "../pageComponents/cta_form/CtaForm01";
import CtaForm03 from "../pageComponents/cta_form/CtaForm03";
import CtaForm04 from "../pageComponents/cta_form/CtaForm04";
import Directory01 from "../pageComponents/directory/Directory01";
import Faq01 from "../pageComponents/faq/Faq01";
import Faq02 from "../pageComponents/faq/Faq02";
import Faq03 from "../pageComponents/faq/Faq03";
import Features01 from "../pageComponents/features/Features01";
import Features02 from "../pageComponents/features/Features02";
import Features03 from "../pageComponents/features/Features03";
import Features05 from "../pageComponents/features/Features05";
import Features06 from "../pageComponents/features/Features06";
import Features07 from "../pageComponents/features/Features07";
import Features09 from "../pageComponents/features/Features09";
import Header01 from "../pageComponents/header/Header01";
import Header02 from "../pageComponents/header/Header02";
import Header03 from "../pageComponents/header/Header03";
import Header04 from "../pageComponents/header/Header04";
import Header05 from "../pageComponents/header/Header05";
import Header06 from "../pageComponents/header/Header06";
import Header08 from "../pageComponents/header/Header08";
import Header09 from "../pageComponents/header/Header09";
import Header23 from "../pageComponents/header/Header23";
import Header27 from "../pageComponents/header/Header27";
import Header28 from "../pageComponents/header/Header28";
import Header29 from "../pageComponents/header/Header29";
import Header35 from "../pageComponents/header/Header35";
import Header39 from "../pageComponents/header/Header39";
import Header43 from "../pageComponents/header/Header43";
import Header44 from "../pageComponents/header/Header44";
import Header49 from "../pageComponents/header/Header49";
import Header60 from "../pageComponents/header/Header60";
import Header61 from "../pageComponents/header/Header61";
import Header62 from "../pageComponents/header/Header62";
import Header63 from "../pageComponents/header/Header63";
import Header64 from "../pageComponents/header/Header64";
import Header65 from "../pageComponents/header/Header65";
import Links01 from "../pageComponents/links/Links01";
import Links02 from "../pageComponents/links/Links02";
import Links03 from "../pageComponents/links/Links03";
import Links04 from "../pageComponents/links/Links04";
import Links05 from "../pageComponents/links/Links05";
import Links06 from "../pageComponents/links/Links06";
import Other01 from "../pageComponents/other/Other01";
import Photos01 from "../pageComponents/photos/Photos01";
import Photos02 from "../pageComponents/photos/Photos02";
import Photos03 from "../pageComponents/photos/Photos03";
import Posts01 from "../pageComponents/posts/Posts01";
import Posts02 from "../pageComponents/posts/Posts02";
import Posts03 from "../pageComponents/posts/Posts03";
import Posts04 from "../pageComponents/posts/Posts04";
import Posts05 from "../pageComponents/posts/Posts05";
import Press01 from "../pageComponents/press/Press01";
import Press02 from "../pageComponents/press/Press02";
import Press03 from "../pageComponents/press/Press03";
import Pricing01 from "../pageComponents/pricing/Pricing01";
import Pricing02 from "../pageComponents/pricing/Pricing02";
import Pricing03 from "../pageComponents/pricing/Pricing03";
import Pricing04 from "../pageComponents/pricing/Pricing04";
import Pricing05 from "../pageComponents/pricing/Pricing05";
import Slider01 from "../pageComponents/slider/Slider01";
import Slider03 from "../pageComponents/slider/Slider03";
import Slider04 from "../pageComponents/slider/Slider04";
import Slider05 from "../pageComponents/slider/Slider05";
import Slider06 from "../pageComponents/slider/Slider06";
import Slider07 from "../pageComponents/slider/Slider07";
import Slider10 from "../pageComponents/slider/Slider10";
import Steps01 from "../pageComponents/steps/Steps01";
import Steps03 from "../pageComponents/steps/Steps03";
import Tabs01 from "../pageComponents/tabs/Tabs01";
import Tabs02 from "../pageComponents/tabs/Tabs02";
import Tabs03 from "../pageComponents/tabs/Tabs03";
import Tabs04 from "../pageComponents/tabs/Tabs04";
import Tabs10 from "../pageComponents/tabs/Tabs10";
import Team01 from "../pageComponents/team/Team01";
import Team02 from "../pageComponents/team/Team02";
import Team03 from "../pageComponents/team/Team03";
import Testimonials01 from "../pageComponents/testimonials/Testimonials01";
import Testimonials02 from "../pageComponents/testimonials/Testimonials02";
import Testimonials05 from "../pageComponents/testimonials/Testimonials05";
import Text01 from "../pageComponents/text/Text01";
import Text02 from "../pageComponents/text/Text02";
import Text03 from "../pageComponents/text/Text03";
import Text04 from "../pageComponents/text/Text04";
import Text06 from "../pageComponents/text/Text06";
import Text07 from "../pageComponents/text/Text07";
import Text08 from "../pageComponents/text/Text08";
import Video01 from "../pageComponents/video/Video01";
import Video02 from "../pageComponents/video/Video02";
import Video03 from "../pageComponents/video/Video03";
import Video04 from "../pageComponents/video/Video04";
import popup01 from "../popups/Popup01";
import Other02 from "../pageComponents/other/Other02";
import Other03 from "../pageComponents/other/Other03";
import Other04 from "../pageComponents/other/Other04";
import Directory04 from "../pageComponents/directory/Directory04";

export const pageComponentsMap = {
  // Used for ReactJS internal needs. To display or remove a component from the gallery use the Django admin panel.
  header: {
    "01": {
      ref: Header01,
    },
    "60": {
      ref: Header60,
    },
    "61": {
      ref: Header61,
    },
    "62": {
      ref: Header62,
    },
    "63": {
      ref: Header63,
    },
    "64": {
      ref: Header64,
    },
    "65": {
      ref: Header65,
    },

    "23": {
      ref: Header23,
    },
    "04": {
      ref: Header04,
    },
    "28": {
      ref: Header28,
    },
    "05": {
      ref: Header05,
    },
    "49": {
      ref: Header49,
    },
    "06": {
      ref: Header06,
    },
    "29": {
      ref: Header29,
    },
    "39": {
      ref: Header39,
    },
    "44": {
      ref: Header44,
    },
    "03": {
      ref: Header03,
    },
    "27": {
      ref: Header27,
    },
    "35": {
      ref: Header35,
    },
    "43": {
      ref: Header43,
    },
    "02": {
      ref: Header02,
    },
    "08": {
      ref: Header08,
    },
    "09": {
      ref: Header09,
    },
  },
  features: {
    "01": {
      ref: Features01,
    },
    "02": {
      ref: Features02,
    },
    "03": {
      ref: Features03,
    },
    "05": {
      ref: Features05,
    },
    "06": {
      ref: Features06,
    },
    "07": {
      ref: Features07,
    },
    "09": {
      ref: Features09,
    },
  },
  text: {
    "01": {
      ref: Text01,
    },
    "04": {
      ref: Text04,
    },
    "02": {
      ref: Text02,
    },
    "03": {
      ref: Text03,
    },
    "07": {
      ref: Text07,
    },
    "08": {
      ref: Text08,
    },
    "06": {
      ref: Text06,
    },
  },
  photos: {
    "03": {
      ref: Photos03,
    },
    "02": {
      ref: Photos02,
    },
    "01": {
      ref: Photos01,
    },
  },
  other: {
    "01": {
      ref: Other01,
    },
    "02": {
      ref: Other02,
    },
    "03": {
      ref: Other03,
    },
    "04": {
      ref: Other04,
    },
  },
  team: {
    "01": {
      ref: Team01,
    },
    "02": {
      ref: Team02,
    },
    "03": {
      ref: Team03,
    },
  },
  video: {
    "01": {
      ref: Video01,
    },
    "02": {
      ref: Video02,
    },
    "03": {
      ref: Video03,
    },
    "04": {
      ref: Video04,
    },
  },
  cta_button: {
    "01": {
      ref: CtaButton01,
    },
    "21": {
      ref: CtaButton21,
    },
    "03": {
      ref: CtaButton03,
    },
    "12": {
      ref: CtaButton12,
    },
    "16": {
      ref: CtaButton16,
    },
    "25": {
      ref: CtaButton25,
    },
  },
  cta_form: {
    "03": {
      ref: CtaForm03,
    },
    "01": {
      ref: CtaForm01,
    },
    "04": {
      ref: CtaForm04,
    },
  },
  press: {
    "01": {
      ref: Press01,
    },
    "02": {
      ref: Press02,
    },
    "03": {
      ref: Press03,
    },
  },
  links: {
    "01": {
      ref: Links01,
    },
    "02": {
      ref: Links02,
    },
    "03": {
      ref: Links03,
    },
    "04": {
      ref: Links04,
    },
    "05": {
      ref: Links05,
    },
    "06": {
      ref: Links06,
    },
  },
  contact: {
    "01": {
      ref: Contact01,
    },
    "02": {
      ref: Contact02,
    },
  },
  steps: {
    "01": {
      ref: Steps01,
    },
    "03": {
      ref: Steps03,
    },
  },
  clients: {
    "01": {
      ref: Clients01,
    },
    "02": {
      ref: Clients02,
    },
    "03": {
      ref: Clients03,
    },
  },
  code: {
    "01": {
      ref: Code01,
    },
    "02": {
      ref: Code02,
    },
  },
  pricing: {
    "01": {
      ref: Pricing01,
    },
    "02": {
      ref: Pricing02,
    },
    "03": {
      ref: Pricing03,
    },
    "04": {
      ref: Pricing04,
    },
    "05": {
      ref: Pricing05,
    },
  },
  testimonials: {
    "01": {
      ref: Testimonials01,
    },
    "02": {
      ref: Testimonials02,
    },
    "05": {
      ref: Testimonials05,
    },
  },
  faq: {
    "01": {
      ref: Faq01,
    },
    "02": {
      ref: Faq02,
    },
    "03": {
      ref: Faq03,
    },
  },
  posts: {
    "01": {
      ref: Posts01,
    },
    "02": {
      ref: Posts02,
    },
    "03": {
      ref: Posts03,
    },
    "04": {
      ref: Posts04,
    },
    "05": {
      ref: Posts05,
    },
  },
  slider: {
    "01": {
      ref: Slider01,
    },
    // '02': {
    //     ref: Slider02
    // },
    "03": {
      ref: Slider03,
    },
    "04": {
      ref: Slider04,
    },
    "05": {
      ref: Slider05,
    },
    "06": {
      ref: Slider06,
    },
    "07": {
      ref: Slider07,
    },
    "10": {
      ref: Slider10,
    },
  },
  tabs: {
    "01": {
      ref: Tabs01,
    },
    "02": {
      ref: Tabs02,
    },
    "03": {
      ref: Tabs03,
    },
    "04": {
      ref: Tabs04,
    },
    "10": {
      ref: Tabs10,
    },
  },
  popups: {
    "01": {
      ref: popup01,
    },
  },
  directory: {
    "01": {
      ref: Directory01,
    },
    "02": {
      ref: Directory01,
    },
    "03": {
      ref: Directory01,
    },
    "04": {
      ref: Directory04,
    },
  }
};
