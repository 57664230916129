import React, { useState } from "react";
import capitalizeString from "../../../../helpers/strings/capitalizeString";
import getIntegrationLogoSourceFromTitle from "../../../../helpers/editor/getIntegrationLogoSourceFromTitle";
import { ZapierIntegrationStatus } from "../../../../enums/ZapierIntegrationStatus";
import { fetchZapierStatusAsync } from "../../../../helpers/formIntegrations/fetchZapierStatusAsync";
import { Tooltip, Icon, Radio, Tag, Popconfirm, Button } from "antd";
import classNames from "classnames";
import { distanceInWords } from "date-fns";
import IntegrationItemTitle from "./IntegrationItemTitle";
import IntegrationItemValue from "./IntegrationItemValue";
import IntegrationItemInfo from "./IntegrationItemInfo";
import _ from "lodash";

interface Props {
  item: any;
  availableIntegrations: any[];
  accessToken: string;
  currentWebsite: any;
  websites: any;

  deleteIntegration: () => void;
  renameIntegration: any;
  removeWebsiteFormIntegration: (payload: {
    id: string;
    currentWebsiteSubdomain: string;
  }) => void;
}

const IntegrationItem = (props: Props): JSX.Element => {
  const {
    item,
    availableIntegrations,
    accessToken,
    currentWebsite,
    websites,
    deleteIntegration,
    removeWebsiteFormIntegration,
  } = props;

  const [zapierStatus, setZapierStatus] = useState(
    ZapierIntegrationStatus.notActive
  );
  const [zapierStatusError, setZapierStatusError] = useState("");
  const [zapierStatusLoaded, setZapierStatusLoaded] = useState(false);

  const clientCurrentTime = new Date();
  const difference = distanceInWords(
    clientCurrentTime.getTime(),
    new Date(item.connectedOn).getTime()
  );

  const handleOnMouseEnter = (item: any) => {
    if (item.integrationTitle !== "zapier") return;
    setZapierStatusLoaded(false);
    fetchZapierStatusAsync(accessToken, currentWebsite.id, item.id)
      .then((response) => {
        setZapierStatus(response);
        setZapierStatusLoaded(true);
      })
      .catch((error) => {
        const errorMessage = _.get(
          error,
          ["response", "data", "detail"],
          "Internal server error"
        );
        setZapierStatus(ZapierIntegrationStatus.error);
        setZapierStatusError(errorMessage);
        setZapierStatusLoaded(true);
      });
  };

  return (
    <Tooltip
      placement="left"
      title={
        item.integrationTitle !== "cms" && <span>
          <IntegrationItemTitle item={item} />
          <div className="content-input__toltip-value">
            <IntegrationItemValue
              integrationTitle={item.integrationTitle}
              zapierStatus={zapierStatus}
              zapierStatusLoaded={zapierStatusLoaded}
              item={item}
            />
          </div>
          <IntegrationItemInfo
            integrationTitle={item.integrationTitle}
            zapierStatus={zapierStatus}
            zapierStatusLoaded={zapierStatusLoaded}
            item={item}
            zapierStatusError={zapierStatusError}
          />
        </span>
      }
    >
      <Radio
        // className={"content-input__integrations_radio " + additionalClassname}
        className={classNames("content-input__integrations_radio")}
        value={item.id}
        onMouseEnter={() => handleOnMouseEnter(item)}
      >
        <span className="content-input__integrations_radio__title">
          <img
            className="content-input__integrations_radio_icon"
            src={getIntegrationLogoSourceFromTitle(item.integrationTitle)}
            alt={item.integrationTitle + " logo"}
          />
          <span className="content-input__integrations_radio__title_text">
            {item.name ? item.name : item.data && item.data.collectionName ? item.data.collectionName : capitalizeString(item.id)}
          </span>
          <span className="content-input__integrations_radio__created">
            <Icon style={{ marginRight: 3 }} type="clock-circle" />{" "}
            <span
              className={
                "content-input__integrations_radio__created_text " +
                (difference.length > 12
                  ? "content-input__integrations_radio__created_text--small "
                  : " ")
              }
            >
              {difference} ago
            </span>
          </span>
        </span>
        <span className="content-input__integrations_radio__del">
          <Popconfirm
            placement="bottomRight"
            title={
              <div style={{ width: 300 }}>
                Delete the form integration globally? This will break any forms
                connected to this integration.
              </div>
            }
            onConfirm={(event: any) => {
              // Save the new integration of the website in the Store:
              removeWebsiteFormIntegration({
                id: item.id,
                currentWebsiteSubdomain: currentWebsite.subdomain,
              });

              // Also save the new integration of the website in the server:
              deleteIntegration();
            }}
            // onCancel={}
            okText="Delete"
            cancelText="Cancel"
          >
            <Button
              htmlType="button"
              className="content-input__integrations_radio__del_button"
              shape="circle"
              title="Delete this integration"
              icon="cross"
              size="small"
              loading={websites.isWaitingForCommonWebsiteUpdateResponse}
            />
          </Popconfirm>
          <Button
              htmlType="button"
              className="content-input__integrations_radio__rename_button"
              shape="circle"
              title="Rename integration"
              icon="edit"
              size="small"
              loading={websites.isWaitingForCommonWebsiteUpdateResponse}
              onClick={() => {
                const newName = window.prompt("Rename integration:", item.name || "");
                props.renameIntegration(newName || "");
              }}
            />
        </span>
      </Radio>
    </Tooltip>
  );
};

export default IntegrationItem;
