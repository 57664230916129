import React, { useRef, useState } from "react";
import ContentInputLabel from "../../../ui_components/ContentInputLabel";
import CMSMenu from "../../ContentEditionBox/CMSMenu";
import { CmsType } from "../../../../enums/enums";
import { SaveConnectedIntegrationsInServer } from "../../../../helpers/types/globalTypes";
import { connect } from "react-redux";
import { saveWebsiteNewFormIntegrationInState } from "../../../../store/websites/actions";
import { toggleNewWebsitePageEditionsDetected } from "../../../../store/websitePages/actions";
import generateFormIntegrationDataObject from "../../../../helpers/editor/generateFormIntegrationDataObject";
import { WebsitesState } from "../../../../store/websites/types";

interface Props {
  currentFormIntegrationTitle: string;
  saveConnectedIntegrationsInServer: SaveConnectedIntegrationsInServer;
  toggleNewWebsitePageEditionsDetected: any;
  saveWebsiteNewFormIntegrationInState: any;
  currentWebsite: any;
  websites: WebsitesState;
}

const CmsInputs = (props: Props): JSX.Element => {
  const {
    toggleNewWebsitePageEditionsDetected,
    currentFormIntegrationTitle,
    saveConnectedIntegrationsInServer,
    saveWebsiteNewFormIntegrationInState,
    currentWebsite,
    websites,
  } = props;
  const [collectionId, setCollectionId] = useState<string>();
  const collectionName = useRef<string>();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const handleOnSave = () => {
    props.toggleNewWebsitePageEditionsDetected(true);
    const newConnectedIntegrationObject = generateFormIntegrationDataObject.cms(
      currentFormIntegrationTitle,
      collectionId,
      process.env.NODE_ENV === "development" ? 722 : currentWebsite.id,
      collectionName.current
    );
    props.saveWebsiteNewFormIntegrationInState({
      newConnectedIntegrationObject,
      currentWebsiteSubdomain: currentWebsite.subdomain,
    });
    saveConnectedIntegrationsInServer(newConnectedIntegrationObject);
  };

  return (
    <div>
      <ContentInputLabel>Send data to collection</ContentInputLabel>
      <CMSMenu
        cmsType={CmsType.UNICORN}
        unicornCollectionId={collectionId}
        onSelectCollection={(v, n) => {
          setCollectionId(v);
          collectionName.current = n;
          setHasUnsavedChanges(true);
        }}
        hideLabels
        saveButtonLoading={websites.isWaitingForFormIntegrationsServerResponse}
        onSaveChanges={handleOnSave}
        hasUnsavedChanges={hasUnsavedChanges}
        className="is-no-inputs"
      />
    </div>
  );
};

const mapActionsToProps = {
  toggleNewWebsitePageEditionsDetected,
  saveWebsiteNewFormIntegrationInState,
};
export default connect(null, mapActionsToProps)(CmsInputs);
