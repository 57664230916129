import React from "react";
import { Button, Icon } from "antd";
import getIntegrationLogoSourceFromTitle from "../../../helpers/editor/getIntegrationLogoSourceFromTitle";
import SpinnerBox from "../../dashboard/SpinnerBox";
import { UserState } from "../../../store/user/types";
import _ from "lodash";

interface Props {
  formIntegrations: any;
  setParentState: (newState: any) => void;
  showConnectFormIntegrationDrawer: () => void;
  user: UserState;
}

const FormIntegrationsMarketplace = (props: Props): JSX.Element => {
  const {
    formIntegrations,
    setParentState,
    showConnectFormIntegrationDrawer,
    user,
  } = props;

  if (!formIntegrations.dataFetched) {
    return <SpinnerBox text="The integrations are loading..." />;
  }

  const integrations = formIntegrations.items;

  // first, let's create a list of all categories: ["Mailing services", "CRM", "Other", ...]
  const integrationsCategories = [];
  let integrationsCount = integrations.length;
  for (let i = 0; i < integrationsCount; i++) {
    let currentIntegrationTitle = integrations[i].category_title;
    // if no such category in the list, add.
    if (integrationsCategories.indexOf(currentIntegrationTitle) === -1) {
      integrationsCategories.push(currentIntegrationTitle);
    }
  }

  // second, let's create a list of JSX components of all categories and its items. E.g. "CRM" : <Button>Salesforce</Button> <Button>Pipedrive</Button>
  const integrationsWithCategoriesJSX = []; //the final piece of JSX we will render.
  let integrationsCategoriesCount = integrationsCategories.length;
  for (let i = 0; i < integrationsCategoriesCount; i++) {
    let currentIntegrationCategory = integrationsCategories[i];

    //loop through each category in the categories list and for each item we find corresponding integrations from the integrations[] list.
    const integrationsJSX = integrations.sort((a, b) => b.priority - a.priority).map((item: any, key: number) => {
      let integrationCategory = item.category_title;

      if (integrationCategory === currentIntegrationCategory) {
        let integrationTitle = item.title;
        let integrationLogo = getIntegrationLogoSourceFromTitle(
          integrationTitle
        );

        return (
          <li className="page-component__integration_item" key={key}>
            <Button
              onClick={() => {
                setParentState({
                  currentFormIntegrationTitle: integrationTitle,
                  currentFormIntegrationHumanTitle: item.human_title,
                  currentFormIntegrationAvailable: item.is_available,
                  currentFormIntegrationProOnly: item.is_pro_only,
                });
                showConnectFormIntegrationDrawer();
              }}
              htmlType="button"
              title={
                item.description
                  ? item.description
                  : "Click to connect a " +
                    item.human_title +
                    " integration and collect users' emails with the service."
              }
            >
              <div className="page-component__img_box">
                <img
                  src={integrationLogo}
                  height="14"
                  alt={item.title + " logo"}
                />
              </div>
              <span>Connect {item.human_title}</span>
              <Icon
                type="api"
                theme={item.is_available ? "twoTone" : "filled"}
              />
            </Button>
          </li>
        );
      }
    });
    //we iterated through each integration from the integrations[] list and added all the items which has same category with the current one.
    // We also collected all the found integrations in one variable integrationsJSX. Let's display it under it's category title:

    let categoryWithButtons = (
      <div key={i}>
        <ul className="page-component__integrations_list">{integrationsJSX}</ul>
      </div>
    );

    // Add this block (category title + the category integrations) to the final piece of JSX to render.
    integrationsWithCategoriesJSX.push(categoryWithButtons);
  }

  return <div style={{marginTop: 16}}>{integrationsWithCategoriesJSX}</div>;
};

export default FormIntegrationsMarketplace;
