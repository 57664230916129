import React, {Component} from "react";
import {BrowserRouter as Router, Route, Link, Switch, Redirect} from "react-router-dom";
import {connect} from "react-redux";
import LoginForm from "../auth/LoginForm";
import JoinForm from "../auth/JoinForm";
import RestorePassForm from "../auth/RestorePassForm";
// import { ReCaptcha } from 'react-recaptcha-v3'

import {
    toggleAccessTokenIsValid,
    saveAccessTokenInState,
    accessTokenToggleServerResponded,
    registrationToggleWaitingForServer,
    registrationToggleInvalidEmail,
    registrationToggleInvalidPassword,
    registrationToggleInvalidPasswordRepeat,
    registrationSetEmailMessage,
    registrationSetPasswordMessage,
    registrationSetPasswordRepeatMessage,
    loginToggleWaitingForServer,
    loginToggleInvalidEmail,
    loginToggleInvalidPassword,
    loginSetEmailMessage,
    loginSetPasswordMessage,
    restorePasswordToggleWaitingForServer,
    restorePasswordToggleInvalidAttempt,
    restorePasswordSetServerMessage,
    setNewPasswordToggleWaitingForServer,
    setNewPasswordToggleInvalidPassword,
    setNewPasswordToggleInvalidPasswordRepeat,
    setNewPasswordSetPasswordMessage,
    setNewPasswordSetPasswordRepeatMessage, validateUserAccessTokenInAuth, toggleNeedsOnboarding,
} from "../../store/auth/actions";
import {saveUserEventsInStore, toggleUserDataFetched, setUserStateData} from "../../store/user/actions";
import './AuthLayout.css'
import UnicornPlatformLogo from '../../img/other/unicorn-platform-logo.svg'
import UnicornPlatformAndAppsumoLogo from '../../img/logos/unicorn_platform_plus_appsumo.svg'
import '../../data/urls'
import {api, urls} from "../../data/urls";
import SetNewPassForm from "../auth/SetNewPassForm";
import {recaptachav3SiteKey} from "../../data/constants";
import axios from "axios";
import returnAuthHeaderForAJAX from "../../helpers/auth/returnAuthHeaderForAJAX";
import fireAnalyticsEvent from "../../helpers/editor/fireAnalyticsEvent";
import {getErrorKey} from "../../helpers/editor/getErrorKey";
import {getErrorValue} from "../../helpers/editor/getErrorValue";
import {Interface} from "readline";
import {openNotification} from "../../helpers/openNotification";
import {messages} from "../../data/messages";
import {Icon} from "antd";
import { getDiffieHellman } from "crypto";
import getHelloGetParam from "../../helpers/getHelloGetParam";
import { any } from 'prop-types';
import { SaveUserEventsInStore, SetUserStateData } from "../../store/user/types";
import _ from "lodash";
import SocialAuth from "../auth/SocialAuth";

interface Props {
    auth: object,
    match: any,
    user: any,

    saveAccessTokenInState: object
    toggleAccessTokenIsValid: object,
    accessTokenToggleServerResponded: object,

    registrationToggleWaitingForServer: object,
    registrationToggleInvalidEmail: object,
    registrationToggleInvalidPassword: object,
    registrationToggleInvalidPasswordRepeat: object,
    registrationSetEmailMessage: object,
    registrationSetPasswordMessage: object,
    registrationSetPasswordRepeatMessage: object,

    loginToggleWaitingForServer: object,
    loginToggleInvalidEmail: object,
    loginToggleInvalidPassword: object,
    loginSetEmailMessage: object,
    loginSetPasswordMessage: object,

    restorePasswordToggleWaitingForServer: object,
    restorePasswordToggleInvalidAttempt: object,
    restorePasswordSetServerMessage: object,

    setNewPasswordToggleWaitingForServer: object,
    setNewPasswordToggleInvalidPassword: object,
    setNewPasswordToggleInvalidPasswordRepeat: object,
    setNewPasswordSetPasswordMessage: object,
    setNewPasswordSetPasswordRepeatMessage: object,

    saveUserDataInState: object,
    toggleUserDataFetched: object,
    validateUserAccessTokenInAuth: object,
    toggleNeedsOnboarding: any,

    saveUserEventsInStore: SaveUserEventsInStore;
    setUserStateData: SetUserStateData;
}
interface MyState {
    whereUserCameFrom: string|undefined // the service a user came from
}
class AuthLayout extends Component<Props, MyState>{
    getCaptchaTokenAndSubmitTheForm = (callback:any) => {
        if((window as any).grecaptcha !== undefined){
            // console.log('captcha script loaded');
            // captcha script loaded
            (window as any).grecaptcha.ready(function() {
                (window as any).grecaptcha.execute(recaptachav3SiteKey, {action: 'submit'}).then(function(token) {
                    // console.log('token', token);
                    if(callback){
                        callback(token);
                    }
                    // axios.post(api.account.recaptchaAPI,
                    //     {
                    //         recaptcha_token: token
                    //     }
                    //     , {})
                    //     .then(response => {
                    //         console.log(JSON.parse(response.data));
                    //
                    //         let score = JSON.parse(response.data).score;
                    //         if (score > 0.5){
                    //
                    //         }
                    //     })
                    //     .catch(error => {
                    //         // handle error
                    //         // console.log(error);
                    //
                    //     })
                    //     .then(response => {
                    //     });
                });
            });
        }else{
            openNotification('A few moments, please...', "We are still checking your browser. Usually it takes a few seconds. Please re-try after you read this.", 'OK, thanks', 'info', 20, <Icon type="loading" style={{color: 'var(--ocean)', fontSize: '30px'}} />);
            return undefined;
        }
    };

    componentDidMount() {
      const referrer = sessionStorage.getItem('referrer');
      if (referrer === null) {
        sessionStorage.setItem('referrer', document.referrer === "" ? "$direct" : document.referrer);
      }
    }

    componentWillMount() {
        const whereUserCameFrom = getHelloGetParam()
        this.setState({
            'whereUserCameFrom': whereUserCameFrom
        })
    }
    
    displayLogo (){
        let logoJSX:any;
        if(this.state.whereUserCameFrom && this.state.whereUserCameFrom === 'appsumo'){
            logoJSX = (<img className="auth-layout__home_logo" src={UnicornPlatformAndAppsumoLogo} alt="Unicorn Platform and AppSumo Logo"/>);
        }else{
            logoJSX = (<img className="auth-layout__home_logo" src={UnicornPlatformLogo} alt="Unicorn Platform Logo"/>);
        }
        return logoJSX;
    }

    render(){
        return (
            <div className="auth-layout">
                <div className="auth-layout__top">
                    <a className="auth-layout__home_link" target="_blank" href={urls.homeLandingPage}>
                        {this.displayLogo()}
                    </a>
                </div>
                {/*<ReCaptcha*/}
                {/*    sitekey={recaptachav3SiteKey}*/}
                {/*    action='homepage'*/}
                {/*    getRecaptchaToken={this.getRecaptchaToken}*/}
                {/*/>*/}
                <Switch>
                    {/*Only one of the routes in a given <Switch> will render.*/}
                    <Route exact path={`${this.props.match.path}/google-auth`} render={props => (
                        <SocialAuth 
                        setUserStateData={this.props.setUserStateData}
                        saveUserEventsInStore={this.props.saveUserEventsInStore}
                        />
                    )} />
                    <Route exact path={`${this.props.match.path}/join`} render={props => (
                        <JoinForm auth={this.props.auth}
                                  registrationToggleWaitingForServer={this.props.registrationToggleWaitingForServer}

                                  registrationToggleInvalidEmail={this.props.registrationToggleInvalidEmail}
                                  registrationToggleInvalidPassword={this.props.registrationToggleInvalidPassword}
                                  registrationToggleInvalidPasswordRepeat={this.props.registrationToggleInvalidPasswordRepeat}

                                  registrationSetEmailMessage={this.props.registrationSetEmailMessage}
                                  registrationSetPasswordMessage={this.props.registrationSetPasswordMessage}
                                  registrationSetPasswordRepeatMessage={this.props.registrationSetPasswordRepeatMessage}

                                  toggleAccessTokenIsValid={this.props.toggleAccessTokenIsValid}
                                  saveAccessTokenInState={this.props.saveAccessTokenInState}
                                  accessTokenToggleServerResponded={this.props.accessTokenToggleServerResponded}
                                  validateUserAccessTokenInAuth={this.props.validateUserAccessTokenInAuth}
                                  getCaptchaTokenAndSubmitTheForm={this.getCaptchaTokenAndSubmitTheForm}
                                  whereUserCameFrom={this.state.whereUserCameFrom}
                                  toggleNeedsOnboarding={this.props.toggleNeedsOnboarding}

                                  saveUserEventsInStore={this.props.saveUserEventsInStore}
                                  setUserStateData={this.props.setUserStateData}
                                  user={this.props.user}
                        />
                    )} />
                    <Route exact path={`${this.props.match.path}/login`} render={props =>  (
                        <LoginForm auth={this.props.auth}
                                   toggleAccessTokenIsValid={this.props.toggleAccessTokenIsValid}
                                   saveAccessTokenInState={this.props.saveAccessTokenInState}

                                   loginToggleWaitingForServer={this.props.loginToggleWaitingForServer}

                                   loginToggleInvalidEmail={this.props.loginToggleInvalidEmail}
                                   loginToggleInvalidPassword={this.props.loginToggleInvalidPassword}

                                   loginSetEmailMessage={this.props.loginSetEmailMessage}
                                   loginSetPasswordMessage={this.props.loginSetPasswordMessage}
                                   accessTokenToggleServerResponded={this.props.accessTokenToggleServerResponded}
                                   validateUserAccessTokenInAuth={this.props.validateUserAccessTokenInAuth}
                                   setUserStateData={this.props.setUserStateData}
                                   user={this.props.user}
                        />
                    )} />
                    <Route exact path={`${this.props.match.path}/restore-pass`} render={props => (
                        <RestorePassForm user={this.props.user}
                                         auth={this.props.auth}
                                         restorePasswordToggleWaitingForServer={this.props.restorePasswordToggleWaitingForServer}
                                         restorePasswordToggleInvalidAttempt={this.props.restorePasswordToggleInvalidAttempt}
                                         restorePasswordSetServerMessage={this.props.restorePasswordSetServerMessage}
                                         setUserStateData={this.props.setUserStateData}
                        />
                    )} />
                    <Route path={`${this.props.match.path}/reset`} render={props => (
                        <SetNewPassForm auth={this.props.auth}
                                        setNewPasswordToggleWaitingForServer={this.props.setNewPasswordToggleWaitingForServer}
                                        setNewPasswordToggleInvalidPassword={this.props.setNewPasswordToggleInvalidPassword}
                                        setNewPasswordToggleInvalidPasswordRepeat={this.props.setNewPasswordToggleInvalidPasswordRepeat}
                                        setNewPasswordSetPasswordMessage={this.props.setNewPasswordSetPasswordMessage}
                                        setNewPasswordSetPasswordRepeatMessage={this.props.setNewPasswordSetPasswordRepeatMessage}
                        />
                    )} />
                    <Redirect to="/404"/>
                </Switch>
                {/*social networks*/}
            </div>
        )
    }
}

const putStateToProps = (state:any) => {
    return {
        auth: state.auth as object,
        user: state.user as object
    }
};

const putActionsToProps = {
    toggleAccessTokenIsValid,
    saveAccessTokenInState,
    accessTokenToggleServerResponded,

    registrationToggleWaitingForServer,
    registrationToggleInvalidEmail,
    registrationToggleInvalidPassword,
    registrationToggleInvalidPasswordRepeat,

    registrationSetEmailMessage,
    registrationSetPasswordMessage,
    registrationSetPasswordRepeatMessage,

    loginToggleWaitingForServer,
    loginToggleInvalidEmail,
    loginToggleInvalidPassword,
    loginSetEmailMessage,
    loginSetPasswordMessage,

    restorePasswordToggleWaitingForServer,
    restorePasswordToggleInvalidAttempt,
    restorePasswordSetServerMessage,

    setNewPasswordToggleWaitingForServer,
    setNewPasswordToggleInvalidPassword,
    setNewPasswordToggleInvalidPasswordRepeat,
    setNewPasswordSetPasswordMessage,
    setNewPasswordSetPasswordRepeatMessage,

    toggleUserDataFetched,
    validateUserAccessTokenInAuth,
    toggleNeedsOnboarding,
    saveUserEventsInStore,
    setUserStateData
};

export default connect(putStateToProps, putActionsToProps)(AuthLayout);
