import _ from "lodash";

export const checkIfPageIsDynamic = (page: any) => {
  if (!page) return false;
  const cms = _.get(page, "cms", {});
  if (page.api_source_url && !cms.cmsType) return true;
  if (cms.cmsType === "googleSheets" && _.get(cms, "googleSheets.url")) return true;
  if (cms.cmsType === "custom" && _.get(cms, "customApi.url")) return true;
  if (cms.cmsType === "unicorn" && _.get(cms, "unicorn.collectionId")) return true;
  return false;
};
