import { displayImageUrl } from "../content_displayers/displayImageUrl";
import { calculateDisplayDimensions } from "./calculateDisplayDimensions";
import { getImageQuerystring } from "./getImageQuerystring";

type SrcDict = {
  src: string;
  srcset: string;
  sizes: string;
};

export const getSrcDict = (
  imageObject: any,
  isCover: boolean,
  maxSizes: Array<number>
): SrcDict => {
  const imageUrl = displayImageUrl(imageObject);
  let src = imageUrl;

  if (
    !("width" in imageObject) ||
    !("height" in imageObject) ||
    !imageObject["width"] ||
    !imageObject["height"] ||
    typeof imageObject["width"] !== "number" ||
    typeof imageObject["height"] !== "number"
  ) {
    return {
      src: src,
      srcset: null,
      sizes: null,
    };
  }

  function getCurrentQueryString(size: any, imageUrl: string, density: number = 1): string {
    return getImageQuerystring(size["width"], size["height"], density, imageUrl);
  }

  let maxSizesArray: any[] = [];

  for (let index = 0; index < maxSizes.length; index += 3) {
    maxSizesArray.push({
      width: maxSizes[index] || 5000,
      height: maxSizes[index + 1] || 5000,
      breakpoint: maxSizes[index + 2],
    });
  }

  let actualSizesArray = maxSizesArray.map((maxSizeDict) =>
    calculateDisplayDimensions(
      imageObject,
      maxSizeDict["width"],
      maxSizeDict["height"],
      isCover
    )
  );

  let srcset = "";
  let sizes = "";

  for (const actualSize of actualSizesArray) {
    srcset += `${imageUrl}${getCurrentQueryString(actualSize, imageUrl)} ${
      actualSize["width"]
    }w,`;
  }

  for (let index = 0; index < maxSizesArray.length; index++) {
    const maxSize = maxSizesArray[index];
    if (maxSize["breakpoint"] !== null) {
      sizes += `(max-width: ${maxSize["breakpoint"]}px) ${actualSizesArray[index]["width"]}px,`;
    } else {
      sizes += `${actualSizesArray[index]["width"]}px`;
    }
  }

  const biggestSide =
    imageObject["width"] > imageObject["height"] ? "width" : "height";

  for (const actualSize of actualSizesArray) {
    const diff = parseFloat(
      (imageObject[biggestSide] / (actualSize[biggestSide] * 2)).toFixed(3)
    );
    if (diff <= 1) {
      srcset += `${imageUrl}${getCurrentQueryString(imageObject, imageUrl)} ${
        imageObject["width"]
      }w,`;
      break;
    }
    srcset += `${imageUrl}${getCurrentQueryString(actualSize, imageUrl, 2)} ${actualSize[
      "width"
    ] * 2}w,`;
  }

  for (const actualSize of actualSizesArray) {
    const diff = parseFloat(
      (imageObject[biggestSide] / (actualSize[biggestSide] * 3)).toFixed(3)
    );
    if (diff <= 1) {
      srcset += `${imageUrl}${getCurrentQueryString(imageObject, imageUrl)} ${
        imageObject["width"]
      }w,`;
      break;
    }
    srcset += `${imageUrl}${getCurrentQueryString(actualSize, imageUrl, 3)} ${actualSize[
      "width"
    ] * 3}w,`;
  }

  return {
    src: src,
    srcset: srcset,
    sizes: sizes,
  };
};
