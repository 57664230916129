import React, { Component } from "react";
import "./PageComponent.css";
import {
  Button,
  Drawer,
  Icon,
  Input,
  Popconfirm,
  Tooltip,
  Menu,
  Dropdown,
  message,
  Spin,
} from "antd";
import * as _ from "lodash";
import ContentInput from "../components/editor/ContentInput";
import ContentEditionBox from "../components/editor/ContentEditionBox/ContentEditionBox";
import composeSortedArray from "../helpers/composeSortedArray";
import { contentEditionDrawerWidth } from "../data/settings";
import { callToActionOptions } from "../data/callToActionOptions";
import generateContentDataObject from "../helpers/editor/generateContentDataObject";
import { componentsBackgroundColorsOptions } from "../data/componentsBackgroundColorsOptions";
import { checkIfComponentHasDarkBg } from "../helpers/editor/checkIfComponentHasDarkBg";
import { alwaysWhiteBackgroundComponents } from "../data/alwaysWhiteBackgroundComponents";
import fireAnalyticsEvent from "../helpers/editor/fireAnalyticsEvent";
import checkIfNewUser from "../helpers/user/checkIfNewUser";
import templatePluginsInitializerClass from "../helpers/editor/templatePluginsInitializerClass";
import {
  changeComponentIntegrationId,
  setCustomBackgroundColor,
  switchComponent,
} from "../store/websitePages/actions";
import { applyCustomColorsClassnames } from "../helpers/editor/applyCustomColorsClassnames";
import { checkIfComponentHasBrightCustomColor } from "../helpers/editor/checkIfComponentHasBrightCustomColor";
import { checkIfCustomColorsActive } from "../helpers/editor/checkIfCustomColorsActive";
import { checkIfNavFixed } from "../helpers/editor/checkIfNavFixed";
import IntegrationsMarketplaceDrawer from "../components/editor/formIntegration/IntegrationsMarketplaceDrawer";
import { componentsVerticalPaddings } from "../data/componentsVerticalPaddings";
import SubMenu from "antd/lib/menu/SubMenu";
import { copiedComponentDataSessionStorageKey } from "../data/constants";
import {
  changePopupContent,
  changePopupBackgroundColor,
  changePopupBackgroundImage,
  toggleWebsitePopupEditionsDetected,
  changePopupBackgroundOverlayOpacity,
  changePopupCustomBackgroundColor,
} from "../store/websites/actions";
import {
  ChangePopupBackgroundColorType,
  ChangePopupBackgroundImageType,
  ChangePopupBackgroundOverlayOpacityType,
  ChangePopupContentType,
} from "../helpers/types/popupTypes";
import { connect } from "react-redux";
import { generatePopupDrawerClass } from "../helpers/generatePopupDrawerClass";
import { CrispEvents, PosthogEvents } from "../enums/AnalyticsEventsEnums";
import {
  ChangeComponentIntegrationId,
  SetCustomBackgroundColor,
  SwitchComponent,
} from "../store/websitePages/types";
import { pageComponentsMap } from "../data/pageComponentsMap";
import { getCustomBackgroundColorData } from "../helpers/getCustomBackgroundColorData";
import {
  ChangePopupCustomBackgroundColor,
  GetCurrentStoreData,
} from "../store/websites/types";
import { ColorOption, CustomColorData } from "../helpers/types/globalTypes";
import { Components } from "../enums/Components";
import { formatImageUrl } from "../helpers/strings/formatImageUrl";
import classNames from "classnames";
import { GptState } from "../store/gpt/types";
import { ReactComponent as MaterialWandSm } from "../img/icons/material_wand_sm.svg";
import "../components/editor/ai2/GptAssistant.css";
import {
  AbortControllersRef,
  DropdownContainerRef,
  GptInputObject,
  GptPopoverObject,
  OnGptClick,
  QueueItem,
} from "../components/editor/ai2/types";
import InputDropdown from "../components/editor/ai2/contextual_input/InputDropdown";
import DeleteButtons from "../components/dashboard/DeleteButtons";
import { getCurrentWebsite } from "../helpers/getCurrentWebsite";
import { insertIdIntoCode } from "../helpers/strings/insertIdIntoCode";
import { convertAttributeStringToObject } from "../helpers/convertAttributeStringToObject";
import { getCurrentStoreData } from "../store/websites/thunks";
import { PageComponentCategoriesState } from "../store/pageComponentCategories/types";
import { getActualComponentName } from "../helpers/getActualComponentName";
import DrawerTitle from "../components/editor/DrawerTitle";

interface Props {
  componentItem: any;
  websites: any;
  auth: any;
  user: any;
  gpt: GptState;
  pageComponentCategories: PageComponentCategoriesState;

  removeWebsiteFormIntegration: any;

  formIntegrations: any;

  openAddNewComponent: any;
  componentCategory: string;
  componentKey: number;
  componentsArray: any; //it's an array

  currentPageIndex: number;
  currentWebsite: any;

  editWebsitePageSchemeToggleWaitingForServer: any;
  moveWebsitePageComponent: any;
  toggleNewWebsitePageEditionsDetected: any;
  deleteWebsitePageComponent: any;
  changeWebsitePageComponentContent: any;
  saveWebsiteNewFormIntegrationInState: any;
  toggleWaitingForResponseOnWebsiteFormIntegrationAdd: any;

  changeWebsitePageComponentBackgroundColor: any;
  changeWebsitePageComponentBackgroundImage: any;
  changeWebsitePageComponentBackgroundImageOverlayOpacity: any;

  changeWebsitePageComponentPaddingTop: any;
  changeWebsitePageComponentPaddingBottom: any;

  toggleWaitingForConnectPaymentIntegration: any;
  toggleWaitingForChangePaymentIntegrationProductsArray: any;
  toggleWaitingForEditPaymentIntegrationProductDetails: any;
  toggleWaitingForConfigurePaymentIntegration: any;

  removeImageFromDB: any;
  saveImageInfoInDB: any;

  changeStripeSecretKey: any;
  changeStripePublicKey: any;
  changeStripeCheckoutProductsArray: any;
  toggleWaitingForCommonWebsiteUpdate: any;

  saveWebsiteDataInStore: any;

  history: any;
  changeWebsitePageComponentVisibility: any;

  markFirstComponentWasEditedAsTrue: any;

  toggleIsPageNavHidden: any;
  toggleIsPageFooterHidden: any;
  currentWebsitePage: any;

  changePopupContent?: (payload: ChangePopupContentType) => any;
  changePopupBackgroundColor?: (payload: ChangePopupBackgroundColorType) => any;
  changePopupBackgroundImage?: (payload: ChangePopupBackgroundImageType) => any;
  toggleWebsitePopupEditionsDetected?: (payload: boolean) => void;
  changePopupBackgroundOverlayOpacity?: (
    payload: ChangePopupBackgroundOverlayOpacityType
  ) => void;
  setCustomBackgroundColor: SetCustomBackgroundColor;
  changePopupCustomBackgroundColor: ChangePopupCustomBackgroundColor;
  switchComponent: SwitchComponent;
  getCurrentStoreData: GetCurrentStoreData;
  changeComponentIntegrationId: ChangeComponentIntegrationId;

  gptInputObject?: GptInputObject;
  gptPopoverObject?: GptPopoverObject;
  onGptClick?: OnGptClick;
  gptAssistantComponentRef?: React.MutableRefObject<any>;
  queueDataRef?: React.MutableRefObject<QueueItem[]>;
  intervalRef?: React.MutableRefObject<NodeJS.Timeout>;
  forceStopAllFlagRef?: React.MutableRefObject<boolean>;
  abortControllersRef?: AbortControllersRef;
  navHeight?: number;
}
const Search = Input.Search; // not actually a Search, but a field with a button (https://ant.design/components/input/)
// high order component for all page components. Contains controls such as :
// move up/down, delete
// insert new component
// change content/style

class PageComponent extends Component<Props> {
  gptInputTextAreaRef: React.RefObject<HTMLTextAreaElement>;
  dropdownContainerRef: DropdownContainerRef;
  isNewUser = false;
  isPopup =
    this.props.componentCategory && this.props.componentCategory === "popups";
  constructor(props: Props) {
    super(props);
    this.gptInputTextAreaRef = React.createRef();
    this.dropdownContainerRef = React.createRef();
  }

  //This component is used in 2 components: Editor and PageComponent

  // shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<{}>, nextContext: any): boolean {
  //     console.log('shouldComponentUpdate');
  //     console.log('nextProps', nextProps);
  //     console.log('nextState', nextState);
  //     console.log('nextContext', nextContext);
  //
  //     return true;
  // }

  componentDidMount(): void {
    // Forms are tested out of the page editor.
    // this.props.templatePluginsInitializer.subscribeMailchimpForm();
    // this.props.templatePluginsInitializer.subscribeZapierForm();

    templatePluginsInitializerClass.initTabs();
    templatePluginsInitializerClass.initLightbox();

    this.isNewUser = checkIfNewUser(this.props.user);

    const { componentItem } = this.props;
    const { currentWebsitePage } = this.props.getCurrentStoreData();
    if (
      !_.isEmpty(componentItem.componentData) &&
      componentItem.isForSwitch &&
      !componentItem.isSwitchDataSent
    ) {
      this.props.switchComponent({
        pageId: currentWebsitePage.id,
        componentId: componentItem.switchComponentId,
        newComponentItem: componentItem,
        pageComponentCategories: this.props.pageComponentCategories,
      });
      this.props.toggleNewWebsitePageEditionsDetected(true);
      this.deleteComponent();
    }
  }

  componentDidUpdate() {
    const { componentItem } = this.props;
    const { currentWebsitePage } = this.props.getCurrentStoreData();
    if (
      !_.isEmpty(componentItem.componentData) &&
      componentItem.isForSwitch &&
      !componentItem.isSwitchDataSent
    ) {
      this.props.switchComponent({
        pageId: currentWebsitePage.id,
        componentId: componentItem.switchComponentId,
        newComponentItem: componentItem,
        pageComponentCategories: this.props.pageComponentCategories,
      });
      this.props.toggleNewWebsitePageEditionsDetected(true);
      this.deleteComponent();
    }
  }

  generator = new generateContentDataObject();

  state = {
    contentEditionFieldsVisible: false,
    formIntegrationsMarketplaceVisible: false,
    connectIntegrationVisible: false,
    popupComponentHasImage: true,
    isDropdownVisible: false,
    isAddDropdownVisible: false,
    isConfirmDeleteOpened: false,
    isInputDropdownVisible: false,
    componentKey: 1,
  };

  triggerRemount = () => {
    this.setState((prevState: any) => {
      return {
        componentKey: prevState.componentKey + 1,
      };
    });
  };

  setPopupComponentHasImage = (newValue: boolean) => {
    this.setState({
      popupComponentHasImage: newValue,
    });
  };

  setIsDropdownVisible = (newValue: boolean) => {
    this.setState({
      isDropdownVisible: newValue,
    });
  };
  setIsAddDropdownVisible = (newValue: boolean) => {
    this.setState({
      isAddDropdownVisible: newValue,
    });
  };
  setIsInputDropdownVisible = (newValue: boolean) => {
    this.setState({
      isInputDropdownVisible: newValue,
    });
  };

  showContentEditionFieldsDrawer = () => {
    this.setState({
      contentEditionFieldsVisible: true,
    });
  };
  hideContentEditionFieldsDrawer = () => {
    this.setState({
      contentEditionFieldsVisible: false,
    });
  };

  showFormIntegrationsDrawer = () => {
    fireAnalyticsEvent.fireCrisp(CrispEvents.openAddNewIntegrationWindow, {
      subdomain: this.props.currentWebsite.subdomain,
    });
    this.setState({
      formIntegrationsMarketplaceVisible: true,
    });
  };
  closeFormIntegrationsDrawer = () => {
    this.setState({
      formIntegrationsMarketplaceVisible: false,
    });
  };

  showConnectFormIntegrationDrawer = () => {
    this.setState({
      connectIntegrationVisible: true,
    });
  };
  closeConnectFormIntegrationDrawer = () => {
    this.setState({
      connectIntegrationVisible: false,
    });
  };

  deleteComponent = () => {
    this.props.markFirstComponentWasEditedAsTrue(this.props.componentKey === 0);
    this.props.toggleNewWebsitePageEditionsDetected(true);
    this.props.deleteWebsitePageComponent({
      currentPageIndex: this.props.currentPageIndex,
      componentIndex: this.props.componentKey,
    });
  };
  moveComponentUp = () => {
    this.props.markFirstComponentWasEditedAsTrue(this.props.componentKey === 1); // if we move the second component up it becomes the first and we need to remake the screenshot
    this.props.toggleNewWebsitePageEditionsDetected(true);
    this.props.moveWebsitePageComponent({
      currentPageIndex: this.props.currentPageIndex,
      oldIndex: this.props.componentKey,
      newIndex: this.props.componentKey - 1,
    });
  };
  moveComponentDown = () => {
    this.props.markFirstComponentWasEditedAsTrue(this.props.componentKey === 0); // if the first component is moved down - needs to remake the page screenshot
    this.props.toggleNewWebsitePageEditionsDetected(true);
    this.props.moveWebsitePageComponent({
      currentPageIndex: this.props.currentPageIndex,
      oldIndex: this.props.componentKey,
      newIndex: this.props.componentKey + 1,
    });
  };

  displayContentEditionBox = () => {
    let displayContentEditionFields = () => {
      let componentDataObject = this.props.componentItem.componentData;

      let componentDataSortedArray = composeSortedArray(componentDataObject);

      // create a list of all inputs
      const contentInputsArray = []; //an array of JSX components

      let componentInputsCount = componentDataSortedArray.length;
      let toggleUpdatesFunction =
        this.props.toggleNewWebsitePageEditionsDetected;
      if (this.isPopup) {
        toggleUpdatesFunction = this.props.toggleWebsitePopupEditionsDetected;
      }
      for (let i = 0; i < componentInputsCount; i++) {
        let currentItem = componentDataSortedArray[i];
        let currentInputType = currentItem[0]; //e.g. "image" or "title"
        let currentInputData = currentItem[1];
        let currentInputsArrayLength = contentInputsArray.length;
        const { componentItem } = this.props;
        const uniqueComponentKey =
          currentInputsArrayLength.toString() +
          (componentItem.category || "").toString() +
          (componentItem.title || "").toString();
        contentInputsArray.push(
          <ContentInput
            removeWebsiteFormIntegration={
              this.props.removeWebsiteFormIntegration
            }
            saveContentInStore={this.saveContentInStore}
            toggleNewEditionsDetected={toggleUpdatesFunction}
            contentType={currentInputType}
            currentInputData={currentInputData}
            key={uniqueComponentKey} // We make the key dependent on the component's type so the inputs are re-mounted when the component type is changed. This is done to reset inputs' state.
            currentWebsite={this.props.currentWebsite}
            toggleWaitingForCommonWebsiteUpdate={
              this.props.toggleWaitingForCommonWebsiteUpdate
            }
            componentType={this.props.componentCategory} //e.g. header, cta_form
            componentTitle={this.props.componentItem.title} //e.g. 10, 05
            showFormIntegrationsDrawer={this.showFormIntegrationsDrawer}
            closeFormIntegrationsDrawer={this.closeFormIntegrationsDrawer}
            user={this.props.user}
            websites={this.props.websites}
            auth={this.props.auth}
            toggleWaitingForConnectPaymentIntegration={
              this.props.toggleWaitingForConnectPaymentIntegration
            }
            toggleWaitingForChangePaymentIntegrationProductsArray={
              this.props.toggleWaitingForChangePaymentIntegrationProductsArray
            }
            toggleWaitingForEditPaymentIntegrationProductDetails={
              this.props.toggleWaitingForEditPaymentIntegrationProductDetails
            }
            toggleWaitingForConfigurePaymentIntegration={
              this.props.toggleWaitingForConfigurePaymentIntegration
            }
            saveImageInfoInDB={this.props.saveImageInfoInDB}
            removeImageFromDB={this.props.removeImageFromDB}
            changeStripeSecretKey={this.props.changeStripeSecretKey}
            changeStripePublicKey={this.props.changeStripePublicKey}
            changeStripeCheckoutProductsArray={
              this.props.changeStripeCheckoutProductsArray
            }
            saveWebsiteDataInStore={this.props.saveWebsiteDataInStore}
          />
        );
      }

      let customColorsActiveClassnames = applyCustomColorsClassnames(
        this.props.currentWebsite
      );

      return (
        <div className="comps">
          <div
            className={
              this.props.currentWebsite.color_classname +
              " " +
              customColorsActiveClassnames
            }
          >
            <ul className="page-component__editor_fields_list">
              {contentInputsArray}
            </ul>
          </div>
        </div>
      );
    };
    let toggleUpdatesFunction = this.props.toggleNewWebsitePageEditionsDetected;
    if (this.isPopup) {
      toggleUpdatesFunction = this.props.toggleWebsitePopupEditionsDetected;
    }

    return (
      <ContentEditionBox
        displayFields={displayContentEditionFields}
        hide={this.hideContentEditionFieldsDrawer}
        componentItem={this.props.componentItem}
        componentType={this.props.componentCategory} //e.g. header, cta_form
        componentTitle={this.props.componentItem.title} //e.g. 10, 05
        currentWebsite={this.props.currentWebsite}
        removeImageFromDB={this.props.removeImageFromDB}
        saveImageInfoInDB={this.props.saveImageInfoInDB}
        currentPageIndex={this.props.currentPageIndex}
        componentKey={this.props.componentKey}
        changeWebsitePageComponentVisibility={
          this.props.changeWebsitePageComponentVisibility
        }
        toggleNewEditionsDetected={toggleUpdatesFunction}
        saveComponentBackgroundColorInStore={
          this.saveComponentBackgroundColorInStore
        }
        saveComponentBackgroundImageInStore={
          this.saveComponentBackgroundImageInStore
        }
        saveComponentBackgroundImageOverlayOpacityInStore={
          this.saveComponentBackgroundImageOverlayOpacityInStore
        }
        toggleIsPageNavHidden={this.props.toggleIsPageNavHidden}
        toggleIsPageFooterHidden={this.props.toggleIsPageFooterHidden}
        currentWebsitePage={this.props.currentWebsitePage}
        triggerComponentRemount={this.triggerRemount}
        saveContentInStore={this.saveContentInStore}
        directory={this.props.componentItem.componentData.directory}
      />
    );
  };
  checkIsEmptyContent = (): boolean => {
    // when a new component is added it has no content, so we need to fill it with some default texts and pics.
    // so we check the content (componentData) of a component:
    // if true, then the component is populated with the data from the this.props.componentItem.componentData
    // if false - fill in the obj with dummy content (generate via faker or better - copy paste) and save the object in the store

    // kudos: https://stackoverflow.com/questions/679915/how-do-i-test-for-an-empty-javascript-object
    let isContentEmpty =
      Object.entries(this.props.componentItem.componentData).length === 0 &&
      this.props.componentItem.componentData.constructor === Object;
    return isContentEmpty;
  };

  connectDefaultFormIntegration = (componentItem: any) => {
    if (
      this.props.componentItem.componentData.cta.activeOption ===
      callToActionOptions.form
    ) {
      let allWebsiteAvailableIntegrations =
        this.props.currentWebsite.connected_form_integrations.connected;
      if (
        // So we have > 0 available integrations (availableIntegrations) saved to this website.
        allWebsiteAvailableIntegrations &&
        allWebsiteAvailableIntegrations.length > 0 &&
        // But the current form doesn't have any connected integrations
        componentItem.componentData.cta.content.form.connectedIntegrationsIds
          .length === 0
      ) {
        // We automatically connect this form to the newest (filter by "connectedOn" - the timestamp) available integration from the list of integrations connected to the website.
        let maxTimestamp = 0;
        let newestIntegrationIndex: number;
        let newestIntegration: any;

        let availableIntegrationsCount = allWebsiteAvailableIntegrations.length;
        for (let i = 0; i < availableIntegrationsCount; i++) {
          let currentIntegrationTimestamp =
            allWebsiteAvailableIntegrations[i]["connectedOn"];
          if (currentIntegrationTimestamp > maxTimestamp) {
            maxTimestamp = currentIntegrationTimestamp;
            newestIntegrationIndex = i; //find the index of the newest integration
          }
        }
        // get the newest integration by index:
        newestIntegration =
          allWebsiteAvailableIntegrations[newestIntegrationIndex];

        // use generator to assign this newest available integration to the form
        this.saveContentInStore(
          this.generator.activateCtaEmailFormIntegration(
            { cta: this.props.componentItem.componentData.cta },
            newestIntegration.id
          )
        );
      } else {
        // if the website has no available integrations the editor will offer to add a new one and the form will stay without an 'action' attribute until a user adds an integration.
      }
    }
  };

  saveContentInStore = (contentObj: object) => {
    if (this.isPopup) {
      this.props.changePopupContent({
        currentWebsiteSubdomain: this.props.currentWebsite.subdomain,
        popupId: this.props.componentItem.id,
        componentData: contentObj,
      });
      return;
    }

    this.props.markFirstComponentWasEditedAsTrue(this.props.componentKey === 0);
    this.props.changeWebsitePageComponentContent({
      currentPageIndex: this.props.currentPageIndex,
      componentKey: this.props.componentKey,
      componentData: contentObj,
    });
  };

  saveComponentBackgroundColorInStore = (
    color: string,
    customColorData?: CustomColorData
  ) => {
    if (this.isPopup) {
      this.props.changePopupBackgroundColor({
        currentWebsiteSubdomain: this.props.currentWebsite.subdomain,
        popupId: this.props.componentItem.id,
        color,
      });
      this.props.changePopupCustomBackgroundColor({
        currentWebsiteSubdomain: this.props.currentWebsite.subdomain,
        popupId: this.props.componentItem.id,
        data: customColorData || {
          isActive: false,
        },
      });
      return;
    }

    this.props.markFirstComponentWasEditedAsTrue(this.props.componentKey === 0);
    this.props.changeWebsitePageComponentBackgroundColor({
      currentPageIndex: this.props.currentPageIndex,
      componentKey: this.props.componentKey,
      color: color,
    });
    this.props.setCustomBackgroundColor({
      pageIndex: this.props.currentPageIndex,
      componentIndex: this.props.componentKey,
      data: customColorData || {
        isActive: false,
      },
    });
  };

  saveComponentPaddingTopInStore = (new_padding_top_value: number) => {
    this.props.toggleNewWebsitePageEditionsDetected(true);
    this.props.changeWebsitePageComponentPaddingTop({
      currentPageIndex: this.props.currentPageIndex,
      componentKey: this.props.componentKey,
      new_padding_top_value: new_padding_top_value,
    });
  };

  saveComponentPaddingBottomInStore = (new_padding_bottom_value: number) => {
    this.props.toggleNewWebsitePageEditionsDetected(true);
    this.props.changeWebsitePageComponentPaddingBottom({
      currentPageIndex: this.props.currentPageIndex,
      componentKey: this.props.componentKey,
      new_padding_bottom_value: new_padding_bottom_value,
    });
  };

  saveComponentBackgroundImageInStore = (
    imageUrl: string,
    imageUUID: string | undefined
  ) => {
    if (this.isPopup) {
      this.props.changePopupBackgroundImage({
        currentWebsiteSubdomain: this.props.currentWebsite.subdomain,
        popupId: this.props.componentItem.id,
        imageUrl,
        imageUUID,
      });
      return;
    }

    this.props.markFirstComponentWasEditedAsTrue(this.props.componentKey === 0);
    this.props.changeWebsitePageComponentBackgroundImage({
      currentPageIndex: this.props.currentPageIndex,
      componentKey: this.props.componentKey,
      imageUrl: imageUrl,
      imageUUID: imageUUID,
    });
  };

  saveComponentBackgroundImageOverlayOpacityInStore = (
    newBgImageOverlayOpacity: number
  ) => {
    if (this.isPopup) {
      this.props.changePopupBackgroundOverlayOpacity({
        currentWebsiteSubdomain: this.props.currentWebsite.subdomain,
        popupId: this.props.componentItem.id,
        newOverlayOpacity: newBgImageOverlayOpacity,
      });
      return;
    }

    this.props.changeWebsitePageComponentBackgroundImageOverlayOpacity({
      currentPageIndex: this.props.currentPageIndex,
      componentKey: this.props.componentKey,
      newBgImageOverlayOpacity: newBgImageOverlayOpacity,
    });
  };

  addComponentToClipboard = (componentId) => {
    // inform the copy is successful
    let contentJSX = (
      <span>
        Component <code>{componentId}</code> has been added to the clipboard.
      </span>
    );
    message.success(contentJSX, 5);

    // highlight the copied component
    function blinker(element, duration) {
      element.style.transform = "scale(1.01)";
      setTimeout(function () {
        element.style.transform = "scale(1)";
      }, duration);
    }
    var componentWrapper = document.getElementById(componentId);
    if (componentWrapper) {
      var componentContent = componentWrapper.getElementsByClassName(
        "page-component__copy_blink_animation"
      );
      if (componentContent) {
        blinker(componentContent[0], 200);
      }
    }

    // add the component to the clipboard
    // NB: do not forget to change the ID of the copied component when users pastes it.
    sessionStorage.setItem(
      copiedComponentDataSessionStorageKey,
      JSON.stringify(this.props.componentItem)
    );
  };

  render() {
    const ButtonGroup = Button.Group;
    let backgroundImageUrl = ""; // default for all
    let backgroundActiveOption = "image";
    let backgroundVideoUrl = "";
    const currentComponent = `${this.props.componentCategory}-${this.props.componentItem.title}`;

    const setBackgroundImageUrl = () => {
      if (
        this.props.componentItem.settings &&
        this.props.componentItem.settings.background &&
        this.props.componentItem.settings.background.imageUrl &&
        backgroundActiveOption === "image"
      ) {
        backgroundImageUrl =
          this.props.componentItem.settings.background.imageUrl;
      }
    };

    const setBackgroundVideoUrl = () => {
      if (
        this.props.componentItem.settings &&
        this.props.componentItem.settings.background &&
        this.props.componentItem.settings.background.backgroundVideoUrl
      ) {
        backgroundVideoUrl =
          this.props.componentItem.settings.background.backgroundVideoUrl;
      }
    };

    const setBackgroundActiveOption = () => {
      if (
        this.props.componentItem.settings &&
        this.props.componentItem.settings.background &&
        this.props.componentItem.settings.background.activeOption
      ) {
        backgroundActiveOption =
          this.props.componentItem.settings.background.activeOption;
      }
    };

    const setBackgroundOptions = () => {
      setBackgroundActiveOption();
      setBackgroundVideoUrl();
      setBackgroundImageUrl();
    };

    const componentHasBackgroundImage = () => {
      return backgroundImageUrl !== "" && backgroundActiveOption === "image";
    };

    const componentHasBackgroundVideo = () => {
      return backgroundVideoUrl !== "" && backgroundActiveOption === "video";
    };

    const displayBackgroundVideo = () => {
      if (componentHasBackgroundVideo()) {
        return (
          <video
            key={backgroundVideoUrl}
            loop
            muted
            autoPlay
            playsInline
            className="page-component__bg-video"
          >
            <source src={backgroundVideoUrl} type="video/mp4" />
          </video>
        );
      }
      return null;
    };

    let displayMoveUpButton = (isFirst: boolean) => {
      // we can't move up the first component
      if (isFirst) {
        return null;
      }
      return (
        <Button
          onClick={this.moveComponentUp}
          title="Move the block up ↑"
          size="small"
          htmlType="button"
          type="default"
          icon="arrow-up"
          className="page-component__component_control"
          disabled={isBeingModifiedByAi}
        />
      );
    };
    let displayMoveDownButton = (isLast: boolean) => {
      // we can't move down the last component
      if (isLast) {
        return null;
      }
      return (
        <Button
          onClick={this.moveComponentDown}
          title="Move this block down ↓"
          size="small"
          htmlType="button"
          type="default"
          icon="arrow-down"
          className="page-component__component_control"
          disabled={isBeingModifiedByAi}
        />
      );
    };

    const displayMoveComponentButtons = () => {
      const isFirst = this.props.componentKey === 0;
      const isLast =
        this.props.componentKey === this.props.componentsArray.length - 1;
      if (isFirst && isLast) return null;
      return (
        <li className="page-component__component_controls_item">
          <ButtonGroup>
            {displayMoveUpButton(isFirst)}
            {displayMoveDownButton(isLast)}
          </ButtonGroup>
        </li>
      );
    };

    let backgroundColorClassname = componentsBackgroundColorsOptions.white; // default for most components
    let isDarkBg = false; //default for most components

    let backgroundColorTitle: ColorOption;
    if (
      this.props.componentItem.settings &&
      this.props.componentItem.settings.background &&
      this.props.componentItem.settings.background.color
    ) {
      //all the components have settings.background.color when created but we still additionally check just in case.
      backgroundColorTitle = this.props.componentItem.settings.background.color;
      backgroundColorClassname =
        componentsBackgroundColorsOptions[backgroundColorTitle];
      isDarkBg = checkIfComponentHasDarkBg(backgroundColorTitle);
    }

    // If the custom color activated an this custom color is bright, we declare isDarkBg as false;
    let ifComponentHasBrightCustomColor = checkIfComponentHasBrightCustomColor(
      this.props.currentWebsite
    );
    // But this only works for the components which has backgroundColorTitle == 'accent' as active.
    if (ifComponentHasBrightCustomColor && backgroundColorTitle === "accent") {
      isDarkBg = false;
    }

    // Because style-yellow-1 accent background is too bright to have white text (need to set isDarkBg to false to enable black text).
    // But only for the default 'style-yellow-1' palette is used (because yellow is too bright)
    // AND custom colors are not activated (because user can use another yellow - more darker - we detect the contrast level by JS and then decide)
    if (
      this.props.currentWebsite.color_classname === "style-yellow-1" &&
      backgroundColorTitle === "accent" &&
      checkIfCustomColorsActive(this.props.currentWebsite) === false
    ) {
      isDarkBg = false;
    }

    setBackgroundOptions();

    let backgroundImageClassname = "";

    if (componentHasBackgroundImage() || componentHasBackgroundVideo()) {
      // Also check for video BG
      backgroundImageClassname += " page-component__bg_image_box--has-image "; //makes 'page-component__bg_overlay_box' act as an image overlay, not as a color background (just add transparency). See the public/css, base/background_colors.scss.
    }

    if (
      this.props.componentKey === 0 &&
      this.props.currentWebsite.nav &&
      checkIfNavFixed(this.props.currentWebsite.nav) === false
    ) {
      backgroundImageClassname += " first_component "; //the first component has a little bigger padding to to give space between it and the nav bar. This class is also applied in unicornplatform/website_pages/templates/website_page.html
      // the bigger padding is needed only for non-fixed nav
    }

    let isAlwaysWhiteBg = false;
    if (
      alwaysWhiteBackgroundComponents[this.props.componentCategory] &&
      alwaysWhiteBackgroundComponents[this.props.componentCategory].indexOf(
        this.props.componentItem.title
      ) !== -1
    ) {
      isAlwaysWhiteBg = true;
    }

    let alwaysWhiteBackgroundClassname = "";
    if (isAlwaysWhiteBg) {
      alwaysWhiteBackgroundClassname =
        " page-component__bg_overlay_box--always-white ";
    }

    let componentId = "";
    if (this.props.componentItem.id) {
      componentId = this.props.componentItem.id;
    }

    // start of: vertical paddings:
    let currentPaddingTop = _.has(
      this.props.componentItem.settings,
      "padding.top"
    )
      ? this.props.componentItem.settings.padding.top
      : undefined;
    let currentPaddingBottom = _.has(
      this.props.componentItem.settings,
      "padding.bottom"
    )
      ? this.props.componentItem.settings.padding.bottom
      : undefined;
    // If current component does not have the padding setting, we search its default value in componentsVerticalPaddings.ts
    // Starting from June 2021 each components is set paddings upon adding. We set default values from the same file - componentsVerticalPaddings.ts
    // This logic is needed for components which were added before June 2021 - they do not have this setting set.
    if (currentPaddingTop === undefined) {
      if (
        componentsVerticalPaddings[this.props.componentCategory] &&
        componentsVerticalPaddings[this.props.componentCategory][
          this.props.componentItem.title
        ] &&
        componentsVerticalPaddings[this.props.componentCategory][
          this.props.componentItem.title
        ]["top"] !== undefined
      ) {
        currentPaddingTop =
          componentsVerticalPaddings[this.props.componentCategory][
            this.props.componentItem.title
          ]["top"];
      }
    }
    if (currentPaddingBottom === undefined) {
      if (
        componentsVerticalPaddings[this.props.componentCategory] &&
        componentsVerticalPaddings[this.props.componentCategory][
          this.props.componentItem.title
        ] &&
        componentsVerticalPaddings[this.props.componentCategory][
          this.props.componentItem.title
        ]["bottom"] !== undefined
      ) {
        currentPaddingBottom =
          componentsVerticalPaddings[this.props.componentCategory][
            this.props.componentItem.title
          ]["bottom"];
      }
    }

    let isDefaultVerticalPaddingSet = true;
    if (currentPaddingTop === undefined && currentPaddingBottom === undefined) {
      // this could happen if a component has the padding.top and padding.bottom === udefined in .setting
      // it means the component paddings can not be tuned - it is set in css by us.
      isDefaultVerticalPaddingSet = false;
    }

    let verticalMarginTuningJSX = (
      <div className="page-component__margin_tuner">
        <Tooltip
          placement="top"
          title={
            currentPaddingTop !== undefined
              ? currentPaddingTop + "px"
              : "This component's padding-top can not be changed."
          }
        >
          <div className="page-component__margin_tuner_item">
            <Button
              disabled={
                currentPaddingTop === undefined || currentPaddingTop === 0
              }
              onClick={() =>
                this.saveComponentPaddingTopInStore(currentPaddingTop - 10)
              }
              icon="minus"
              size="small"
              type="default"
            />
            <span className="page-component__margin_tuner_label">
              padding-top
            </span>
            <Button
              disabled={currentPaddingTop === undefined}
              onClick={() =>
                this.saveComponentPaddingTopInStore(currentPaddingTop + 10)
              }
              icon="plus"
              size="small"
              type="default"
            />
          </div>
        </Tooltip>

        <Tooltip
          placement="bottom"
          title={
            currentPaddingBottom !== undefined
              ? currentPaddingBottom + "px"
              : "This component's padding-bottom can not be changed."
          }
        >
          <div className="page-component__margin_tuner_item">
            <Button
              disabled={
                currentPaddingBottom === undefined || currentPaddingBottom === 0
              }
              onClick={() =>
                this.saveComponentPaddingBottomInStore(
                  currentPaddingBottom - 10
                )
              }
              icon="minus"
              size="small"
              type="default"
            />
            <span className="page-component__margin_tuner_label">
              padding-bottom
            </span>
            <Button
              disabled={currentPaddingBottom === undefined}
              onClick={() =>
                this.saveComponentPaddingBottomInStore(
                  currentPaddingBottom + 10
                )
              }
              icon="plus"
              size="small"
              type="default"
            />
          </div>
        </Tooltip>
      </div>
    );

    // actually set 1px instead of 0 because margin tops will pop out of the container in case if padding === 0
    let currentPaddingTopStyleValue = currentPaddingTop;
    if (currentPaddingTopStyleValue === 0) {
      currentPaddingTopStyleValue = 1;
    }
    let currentPaddingBottomStyleValue = currentPaddingBottom;
    if (currentPaddingBottomStyleValue === 0) {
      currentPaddingBottomStyleValue = 1;
    }
    // end of: vertical paddings

    // start of visibility settings
    // frontend/src/components/editor/ContentEditionBox.tsx
    let isComponentHasVisibilitySettings =
      this.props.componentCategory !== "nav" &&
      this.props.componentCategory !== "footer"; // we can hide component but not nav or footer
    let isComponentHiddenOnDesktops = false;
    let isComponentHiddenOnTablets = false;
    let isComponentHiddenOnMobiles = false;
    const isHiddenWhenHasSlug =
      this.props.componentItem.dynamicVisibility === "dynamic";
    const isHiddenWhenNoSlug =
      this.props.componentItem.dynamicVisibility === "static";
    if (
      this.props.componentItem === undefined ||
      this.props.componentItem.settings === undefined ||
      this.props.componentItem.settings.visibility === undefined
    ) {
      // if the settings.visibility object is not defined, the component is visible on all devices by default
    } else {
      isComponentHiddenOnDesktops =
        this.props.componentItem.settings.visibility.isHiddenOnDesktops;
      isComponentHiddenOnTablets =
        this.props.componentItem.settings.visibility.isHiddenOnTablets;
      isComponentHiddenOnMobiles =
        this.props.componentItem.settings.visibility.isHiddenOnMobiles;
    }
    // compose tooltip title
    let visibilitySettingsTooltipTitle = "This component is hidden:";
    if (isComponentHiddenOnDesktops) {
      visibilitySettingsTooltipTitle += "\n- On desktops";
    }
    if (isComponentHiddenOnTablets) {
      visibilitySettingsTooltipTitle += "\n- On tablets";
    }
    if (isComponentHiddenOnMobiles) {
      visibilitySettingsTooltipTitle += "\n- On phones";
    }
    if (isHiddenWhenHasSlug) {
      visibilitySettingsTooltipTitle +=
        "\n- When the page is accessed without a dynamic slug";
    }
    if (isHiddenWhenNoSlug) {
      visibilitySettingsTooltipTitle +=
        "\n- When the page is accessed via a dynamic slug";
    }
    // end of visibility settings

    // start of dropdown content
    let verticalSpaceTunerButtonTitleJSX = (
      <span>
        <svg
          className="page-component__padding_icon"
          viewBox="64 64 896 896"
          focusable="false"
          data-icon="column-height"
          width="1em"
          height="1em"
          fill="currentColor"
          aria-hidden="true"
        >
          <path d="M840 836H184c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h656c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8zm0-724H184c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h656c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8zM610.8 378c6 0 9.4-7 5.7-11.7L515.7 238.7a7.14 7.14 0 0 0-11.3 0L403.6 366.3a7.23 7.23 0 0 0 5.7 11.7H476v268h-62.8c-6 0-9.4 7-5.7 11.7l100.8 127.5c2.9 3.7 8.5 3.7 11.3 0l100.8-127.5c3.7-4.7.4-11.7-5.7-11.7H548V378h62.8z"></path>
        </svg>
        Adjust vertical space
      </span>
    );

    const renderDeleteButton = () => {
      if (!this.state.isConfirmDeleteOpened) {
        return (
          <a
            style={{ color: "var(--blood)" }}
            href="#"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              window.posthog.capture(PosthogEvents.CLICK_DELETE_COMPONENT, {
                component: getActualComponentName(this.props.componentItem),
              });
              if (!this.state.isConfirmDeleteOpened) {
                this.setState({ isConfirmDeleteOpened: true });
              }
            }}
          >
            <Icon type="delete" className="page-component__padding_icon" />
            Delete component
          </a>
        );
      }
      return (
        <DeleteButtons
          loading={false}
          deleteAction={() => {
            this.deleteComponent();
          }}
          deleteItemTitle={currentComponent}
          cancelAction={() => {
            this.setState({ isConfirmDeleteOpened: false });
          }}
        />
      );
    };

    const dropdownMenu = (
      <Menu>
        <Menu.Item
          key="1"
          onClick={() => this.addComponentToClipboard(componentId)}
        >
          <Icon type="copy" />
          Copy component
        </Menu.Item>
        {isDefaultVerticalPaddingSet && (
          <SubMenu
            title={verticalSpaceTunerButtonTitleJSX}
            className="page-component__margin_tuner_submenu"
          >
            <div className="page-component__margin_tuner_box">
              {verticalMarginTuningJSX}
            </div>
          </SubMenu>
        )}
        <Menu.Item>{renderDeleteButton()}</Menu.Item>
      </Menu>
    );
    // end of dropdown content

    //start of Bg Image Transparency
    // also used on frontend/src/components/dashboard/BackgroundImagePicker.tsx
    let currentBgImageTransparencyValue = undefined;
    if (
      this.props.componentItem &&
      this.props.componentItem.settings &&
      this.props.componentItem.settings.background
    ) {
      if (
        this.props.componentItem.settings.background.overlayOpacity !==
        undefined
      ) {
        currentBgImageTransparencyValue =
          this.props.componentItem.settings.background.overlayOpacity;
      }
    }

    const customBackgroundColorData = getCustomBackgroundColorData(
      this.props.componentItem
    );

    if (customBackgroundColorData.isActive) {
      isDarkBg = customBackgroundColorData.isDark;
    }

    let backgorundImageOverlayOpacityStyle: React.CSSProperties = {};
    if (componentHasBackgroundImage() || componentHasBackgroundVideo()) {
      if (currentBgImageTransparencyValue !== undefined) {
        backgorundImageOverlayOpacityStyle = {
          filter: `opacity(${currentBgImageTransparencyValue * 100}%)`,
          opacity: "1",
        };
      }
    }
    if (
      customBackgroundColorData.isActive &&
      currentComponent !== Components.text01
    ) {
      // text-01 is an exception, its background is always white
      backgorundImageOverlayOpacityStyle = {
        ...backgorundImageOverlayOpacityStyle,
        backgroundColor: `#${customBackgroundColorData.color}`,
      };
    }
    // end of Bg Image Transparency

    const displayAdditionalComponentControls = () => {
      if (this.isPopup) return null;
      return (
        <>
          <li className="page-component__component_controls_item">
            <Dropdown
              overlay={dropdownMenu}
              placement="bottomRight"
              disabled={isBeingModifiedByAi}
            >
              <Button
                size="small"
                htmlType="button"
                type="default"
                className="page-component__component_control"
                disabled={isBeingModifiedByAi}
              >
                <Icon type="more" />
              </Button>
            </Dropdown>
          </li>
          {displayMoveComponentButtons()}

          <li
            className="page-component__component_controls_item"
            ref={this.dropdownContainerRef}
          >
            <InputDropdown
              currentWebsitePage={this.props.currentWebsitePage}
              currentPageIndex={this.props.currentPageIndex}
              currentWebsite={this.props.currentWebsite}
              componentIds={[componentId]}
              textAreaRef={this.gptInputTextAreaRef}
              queueDataRef={this.props.queueDataRef}
              intervalRef={this.props.intervalRef}
              dropdownContainerRef={this.dropdownContainerRef}
              isDropdownVisible={this.state.isInputDropdownVisible}
              setIsDropdownVisible={this.setIsInputDropdownVisible}
              forceStopAllFlagRef={this.props.forceStopAllFlagRef}
              abortControllersRef={this.props.abortControllersRef}
            >
              <Button
                size="small"
                htmlType="button"
                type="primary"
                title="Edit this block with AI"
                className="page-component__component_control white-border page-component__component_control--ai"
                onClick={() => {
                  window.posthog.capture(PosthogEvents.CLICK_ASK_AI, {
                    website_id: this.props.currentWebsite.id,
                    component: getActualComponentName(this.props.componentItem),
                  });
                }}
              >
                <MaterialWandSm className="gpt-assistant__icon" /> Ask AI
              </Button>
            </InputDropdown>
          </li>
        </>
      );
    };
    const displayAddComponentButton = () => {
      if (this.isPopup) return null;
      if (fillTemplateStatus === "inProgress") return null;
      return (
        <div className="page-component__add_button_box">
          <Button
            title="Add a new page component below this button ↓"
            size="small"
            onClick={() => {
              let newComponentPosition = this.props.componentKey + 1;

              fireAnalyticsEvent.fireCrisp(
                CrispEvents.openComponentsGalleryWindow,
                {
                  subdomain: this.props.currentWebsite.subdomain,
                  page_url: this.props.currentWebsitePage.url,
                }
              );

              window.posthog.capture(PosthogEvents.CLICK_INSERT_COMPONENT, {
                component_index: newComponentPosition,
              });

              this.props.openAddNewComponent(newComponentPosition); // the new component position - the next to the current component
            }}
            type="primary"
            htmlType="button"
            className="page-component__add_button white-border"
            icon="plus"
          >
            Insert a page component
          </Button>
        </div>
      );
    };
    const getEditButtonTitle = () => {
      if (this.isPopup) return "Edit popup";
      return "Edit component";
    };
    const renderCloseButton = () => {
      if (!this.isPopup) return null;
      return (
        <button className="popup-component__close-button js-close-custom-popup-button">
          <svg
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.0806 17.9986L30.6275 5.42673C30.8043 5.2178 30.6556 4.90039 30.3824 4.90039H27.1762C26.9873 4.90039 26.8065 4.98477 26.682 5.12941L17.9833 15.4995L9.28465 5.12941C9.16411 4.98477 8.98331 4.90039 8.79045 4.90039H5.5842C5.31099 4.90039 5.16233 5.2178 5.33911 5.42673L15.886 17.9986L5.33911 30.5705C5.29951 30.6171 5.2741 30.674 5.26591 30.7346C5.25771 30.7952 5.26708 30.8568 5.29288 30.9122C5.31869 30.9677 5.35985 31.0145 5.41149 31.0472C5.46313 31.0799 5.52307 31.0972 5.5842 31.0968H8.79045C8.97929 31.0968 9.16009 31.0124 9.28465 30.8678L17.9833 20.4977L26.682 30.8678C26.8025 31.0124 26.9833 31.0968 27.1762 31.0968H30.3824C30.6556 31.0968 30.8043 30.7794 30.6275 30.5705L20.0806 17.9986Z"
              fill="white"
            />
          </svg>
        </button>
      );
    };

    const isBeingModifiedByAi =
      this.props.gpt.currentlyModifiedComponents.includes(componentId);
    const currentWebsite = getCurrentWebsite(this.props.websites.items);
    const fillTemplateStatus = _.get(currentWebsite, [
      "fillTemplateData",
      "status",
    ]);

    const { navHeight } = this.props;
    const DEFAULT_OFFSET = 20;
    let controllsTop = DEFAULT_OFFSET;
    let controlsMarginTop = DEFAULT_OFFSET;

    if (navHeight) {
      if (checkIfNavFixed(this.props.currentWebsite.nav)) {
        controllsTop = DEFAULT_OFFSET + navHeight;
      } else {
        if (
          this.props.componentKey === 0 &&
          this.props.componentCategory === "header"
        ) {
          controlsMarginTop = DEFAULT_OFFSET + navHeight;
        }
      }
    }

    const actualComponent = getActualComponentName(this.props.componentItem);

    const additionalVerticalSpace = parseInt(
      _.get(currentWebsite, "custom_styles.additionalVerticalSpace")
    );

    return (
      // We need page-component--visible-controls when Intercom tour is active to display invisible buttons which are appeared on hover only (we can not hover while tour because a cursor is inside Intercom overlay).
      <>
        <div
          key={this.state.componentKey}
          className={classNames(
            `page-component highlighted-on-focus ${actualComponent}-parent`,
            {
              "popup-component js-custom-popup popup-component__editor":
                this.isPopup,
              "popup-component_no-image": !this.state.popupComponentHasImage,
              "page-component--force-visible-controls":
                this.state.isInputDropdownVisible,
              [this.props.componentItem.customClasses]:
                !!this.props.componentItem.customClasses,
              "is-being-edited": this.state.contentEditionFieldsVisible,
              "is-display-none": this.props.componentItem.isForSwitch,
              "uni-is-dark-bg": isDarkBg,
              "is-first-component":
                this.props.componentKey === 0 &&
                this.props.componentCategory !== "popups",
              "is-not-first-component":
                this.props.componentKey !== 0 ||
                this.props.componentCategory === "popups",
            }
          )}
          id={componentId}
          data-uniactualcomponent={actualComponent}
          {...convertAttributeStringToObject(
            this.props.componentItem.customAttributes
          )}
        >
          {renderCloseButton()}
          {/* soif first component is header and is first, it is overlapped by a navigation bar. Make lit lower with a special class - page-component__component_controls--low*/}
          {
            <div
              className="page-component__component_visibility"
              style={{
                display: "flex",
                gap: "10px",
                fontSize: "16px",
                top: controlsMarginTop,
              }}
            >
              {isComponentHasVisibilitySettings &&
                (isComponentHiddenOnDesktops ||
                  isComponentHiddenOnTablets ||
                  isComponentHiddenOnMobiles ||
                  isHiddenWhenHasSlug ||
                  isHiddenWhenNoSlug) && (
                  <Tooltip
                    placement="bottomLeft"
                    title={
                      <div style={{ whiteSpace: "pre" }}>
                        {visibilitySettingsTooltipTitle}
                      </div>
                    }
                    mouseLeaveDelay={0}
                    overlayStyle={{ maxWidth: "500px" }}
                  >
                    <Icon type="eye-invisible" style={{ color: "red" }} />
                  </Tooltip>
                )}
              {this.props.componentItem.customJs && (
                <Tooltip
                  placement="bottomLeft"
                  title={
                    "This component has custom JavaScript logic. Its appearance may be different on a real webpage comparing to the way it looks inside the builder."
                  }
                  mouseLeaveDelay={0}
                  overlayStyle={{ maxWidth: "300px" }}
                  arrowPointAtCenter
                >
                  <Icon
                    type="warning"
                    style={{
                      color:
                        isDarkBg && currentComponent !== Components.text01
                          ? "#fff"
                          : "var(--orange)",
                    }}
                  />
                </Tooltip>
              )}
            </div>
          }
          {fillTemplateStatus !== "inProgress" && (
            <div
              className={
                "page-component__component_controls " +
                (this.props.componentKey === 0 &&
                this.props.componentCategory === "header"
                  ? " page-component__component_controls--low"
                  : " ")
              }
              style={{
                zIndex:
                  500 +
                  (this.props.componentsArray.length - this.props.componentKey),
              }}
            >
              <ul
                className="page-component__component_controls_list"
                style={{
                  top: `${controllsTop + 48}px`,
                  marginTop: `${controlsMarginTop}px`,
                }}
              >
                {displayAdditionalComponentControls()}
                <li className="page-component__component_controls_item">
                  <Button
                    onClick={() => {
                      window.posthog.capture(PosthogEvents.CLICK_EDIT, {
                        component: getActualComponentName(
                          this.props.componentItem
                        ),
                      });
                      this.showContentEditionFieldsDrawer();
                    }}
                    title={getEditButtonTitle()}
                    size="small"
                    htmlType="button"
                    type="primary"
                    icon="edit"
                    className="page-component__component_control white-border js-tour__edit_component"
                    disabled={isBeingModifiedByAi}
                  >
                    {getEditButtonTitle()}
                  </Button>
                </li>
              </ul>
            </div>
          )}

          {/* Get the component from the pageComponentsMap object which maps the title with real ReactJS components.*/}

          {/*// the color hex values are store in the public/css, base/background_colors.scss because public/css is also user in the Django render and we need to render colors not only in the editor.*/}
          <div
            className={classNames(
              "page-component__bg_image_box",
              "page-component__copy_blink_animation",
              "custom_fonts",
              backgroundColorClassname,
              backgroundImageClassname,
              {
                "popup-component__bg_image_box": this.isPopup,
                "bg-custom-color--dark":
                  isDarkBg && backgroundColorTitle === "custom",
              }
            )}
            style={{
              backgroundImage:
                "url(" + formatImageUrl(backgroundImageUrl) + ")",
            }}
          >
            {displayBackgroundVideo()}
            <div
              className={
                "page-component__bg_overlay_box " +
                alwaysWhiteBackgroundClassname
              }
              style={backgorundImageOverlayOpacityStyle}
            />
            <div
              style={{
                zIndex:
                  100 +
                  (this.props.componentsArray.length - this.props.componentKey),
                paddingTop:
                  isNaN(additionalVerticalSpace) ||
                  isNaN(currentPaddingTopStyleValue)
                    ? currentPaddingTopStyleValue
                    : currentPaddingTopStyleValue + additionalVerticalSpace,
                paddingBottom:
                  isNaN(additionalVerticalSpace) ||
                  isNaN(currentPaddingBottomStyleValue)
                    ? currentPaddingBottomStyleValue
                    : currentPaddingBottomStyleValue + additionalVerticalSpace,
              }}
              className={
                "page-component__wrapper " +
                this.props.currentWebsite.active_font_classname
              }
            >
              {React.createElement(
                pageComponentsMap[this.props.componentCategory][
                  this.props.componentItem.title
                ]["ref"],
                {
                  componentItem: this.props.componentItem,
                  checkIsEmptyContent: this.checkIsEmptyContent,
                  saveContentInStore: this.saveContentInStore,
                  currentWebsite: this.props.currentWebsite, // we need the currentWebsite to extract the connected_form_integrations data to render forms and other website-scope data (social links etc)
                  connectDefaultFormIntegration:
                    this.connectDefaultFormIntegration, // we need this to connect an integration to forms when they first created.
                  isDarkBg: isDarkBg,
                  ifComponentHasBrightCustomColor:
                    ifComponentHasBrightCustomColor,
                  popupComponentHasImage: this.state.popupComponentHasImage,
                  setPopupComponentHasImage: this.setPopupComponentHasImage,
                  getCurrentStoreData: this.props.getCurrentStoreData,
                  websites: this.props.websites,
                }
              )}
            </div>
          </div>

          {displayAddComponentButton()}

          <div>
            <Drawer
              title={
                <DrawerTitle
                  title={
                    <>
                      Edit{" "}
                      <span style={{ userSelect: "all" }}>
                        #{this.props.componentItem.id}
                      </span>
                    </>
                  }
                  onClose={this.hideContentEditionFieldsDrawer}
                />
              }
              placement="right"
              closable={false}
              onClose={this.hideContentEditionFieldsDrawer}
              visible={this.state.contentEditionFieldsVisible}
              width={contentEditionDrawerWidth}
              maskStyle={{ cursor: "pointer" }}
              className={
                "edit-component-drawer is-main " +
                generatePopupDrawerClass(this.props.componentCategory)
              }
              afterVisibleChange={(visible) => {
                if (visible) {
                  document.body.style.removeProperty("overflow");
                  document.body.style.removeProperty("touch-action");
                }
              }}
            >
              {this.displayContentEditionBox()}

              <IntegrationsMarketplaceDrawer
                formIntegrationsMarketplaceVisible={
                  this.state.formIntegrationsMarketplaceVisible
                }
                connectIntegrationVisible={this.state.connectIntegrationVisible}
                closeFormIntegrationsDrawer={this.closeFormIntegrationsDrawer}
                closeConnectFormIntegrationDrawer={
                  this.closeConnectFormIntegrationDrawer
                }
                history={this.props.history}
                currentWebsite={this.props.currentWebsite}
                user={this.props.user}
                componentItem={this.props.componentItem}
                saveWebsiteNewFormIntegrationInState={
                  this.props.saveWebsiteNewFormIntegrationInState
                }
                toggleNewWebsitePageEditionsDetected={
                  this.props.toggleNewWebsitePageEditionsDetected
                }
                websites={this.props.websites}
                formIntegrations={this.props.formIntegrations}
                showConnectFormIntegrationDrawer={
                  this.showConnectFormIntegrationDrawer
                }
                toggleWaitingForResponseOnWebsiteFormIntegrationAdd={
                  this.props.toggleWaitingForResponseOnWebsiteFormIntegrationAdd
                }
                auth={this.props.auth}
                componentType={this.props.componentCategory}
                updateIntegrationId={(integrationId) => {
                  this.props.changeComponentIntegrationId({
                    pageId: this.props.currentWebsitePage.id,
                    componentId: this.props.componentItem.id,
                    integrationId: integrationId,
                  });
                  this.props.toggleNewWebsitePageEditionsDetected(true);
                }}
              />
            </Drawer>
          </div>
        </div>
        {this.props.componentItem.customCss && (
          <style>
            {insertIdIntoCode(
              this.props.componentItem.customCss,
              `#${this.props.componentItem.id}`
            )}
          </style>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    gpt: state.gpt,
    pageComponentCategories: state.pageComponentCategories,
  };
};
const actionCreators = {
  changePopupContent,
  changePopupBackgroundColor,
  changePopupBackgroundImage,
  toggleWebsitePopupEditionsDetected,
  changePopupBackgroundOverlayOpacity,
  setCustomBackgroundColor,
  changePopupCustomBackgroundColor,
  switchComponent,
  getCurrentStoreData,
  changeComponentIntegrationId,
};
export default connect(mapStateToProps, actionCreators)(PageComponent);
