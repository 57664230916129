import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  Button,
  Icon,
  Input,
  message,
  Progress,
  Tooltip,
  Typography,
} from "antd";
import {
  ChangePageItemGlobalData,
  ChangeWebsitePagesStateGlobalData,
  EditApiSourceValidationData,
  WebsitePagesState,
} from "../../store/websitePages/types";
import { connect } from "react-redux";
import { getCurrentStoreData } from "../../store/websites/thunks";
import { GetCurrentStoreData } from "../../store/websites/types";
import {
  changePageItemGlobalData,
  changeWebsitePagesStateGlobalData,
  editApiSourceValidationData,
} from "../../store/websitePages/actions";
import { updatePageAsync } from "../../helpers/async/updatePageAsync";
import { AuthState } from "../../store/auth/types";
import {
  DEFAULT_ERROR_TITLE,
  DEFAULT_ERROR_MESSAGE,
  notificationDuration,
} from "../../data/constants";
import { openNotification } from "../../helpers/openNotification";
import _ from "lodash";
import "./UpdateApiSourceForm.scss";
import ClipboardJS from "clipboard";
import { getActiveDomain } from "../../helpers/websites/getActiveDomain";
import { urls } from "../../data/urls";
import { openChatWidget } from "../../helpers/editor/openChatWidget";
import axios, { CancelTokenSource } from "axios";
import trimStringTo from "../../helpers/strings/trimStringTo";
import { fetchAndSaveApiValidationData } from "../../store/websitePages/thunks";
import { formatImageUrl } from "../../helpers/strings/formatImageUrl";

const { Paragraph, Title } = Typography;

const antMessage = message;

const PURGE_TIME_SEC = 300;

interface Props {
  publishPage: (isPublished: boolean, pageUrl: string, website: any) => void;
  value: string;
  onChangeValue: (value: string) => void;
  onPressEnter: () => void;

  websitesPages: WebsitePagesState;
  auth: AuthState;
  getCurrentStoreData: GetCurrentStoreData;
  changePageItemGlobalData: ChangePageItemGlobalData;
  changeWebsitePagesStateGlobalData: ChangeWebsitePagesStateGlobalData;
  editApiSourceValidationData: EditApiSourceValidationData;
  fetchAndSaveApiValidationData: any;
}

export interface ValidationAlertProps {
  message: React.ReactNode;
  type: "success" | "info" | "error" | "warning";
  handleOnTryAgain?: () => void;
  noButtons?: boolean;
}
export const ValidationAlert = (props: ValidationAlertProps) => {
  const { message, type, handleOnTryAgain, noButtons } = props;
  return (
    <Alert
      message={
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <div style={{ width: "100%" }}>{message}</div>

          {!noButtons && (
            <div style={{ display: "flex", gap: "8px", marginTop: "6px" }}>
              <Button
                type="default"
                size="small"
                onClick={() => {
                  window.open(urls.guides.dynamicDataGoogleSheets, "_blank");
                }}
              >
                Read guide
              </Button>
              {!!handleOnTryAgain && (
                <Button type="primary" size="small" onClick={handleOnTryAgain}>
                  Try again
                </Button>
              )}
            </div>
          )}
        </div>
      }
      type={type}
      showIcon
      className="update-api-source-form__validation-message"
    />
  );
};

interface ValidationMessageProps extends Props {
  validationData: any;
  isValidateLoading: boolean;
  handleOnTryAgain: (successMessage?: string) => void;
}
const ValidationMessage = (props: ValidationMessageProps) => {
  const {
    isValidateLoading,
    validationData,
    getCurrentStoreData,
    handleOnTryAgain,
    publishPage,
    websitesPages,
    changeWebsitePagesStateGlobalData,
    auth,
    changePageItemGlobalData,
  } = props;
  const clipboardRef = useRef(null);
  const { currentWebsitePage, currentWebsite } = getCurrentStoreData();
  const MAX_PAGES_TO_SHOW = 3;
  const [isChatLoading, setIsChatLoading] = useState(false);
  const timeoutRef = useRef(null);

  useEffect(() => {
    return () => {
      if (clipboardRef.current) {
        clipboardRef.current.destroy();
      }
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        changeWebsitePagesStateGlobalData({
          data: {
            isPageSheetPurgeCacheLoading: false,
          },
        });
      }
    };
  }, []);

  if (!validationData) return null;

  const openChat = () => {
    setIsChatLoading(true);
    window.$crisp.push([
      "on",
      "chat:opened",
      () => {
        setIsChatLoading(false);
      },
    ]);
    openChatWidget();
  };

  if (validationData.type === "validation:no_title") {
    return (
      <ValidationAlert
        message={
          <div>
            <Title level={4}>No "title" column</Title>
            <Paragraph>
              Please add a column named "title" and try again. It should contain
              the names of the spreadsheet items in any format.
            </Paragraph>
            <Paragraph>
              We will automatically generate inner page URLs based on the titles
              from this column.
            </Paragraph>
            <Paragraph>
              <img src={formatImageUrl("https://unicorn-cdn.b-cdn.net/99c2b2a9-f191-4334-8454-3455c952e20c/")} />
            </Paragraph>
          </div>
        }
        type={"error"}
        handleOnTryAgain={handleOnTryAgain}
      />
    );
  }

  if (validationData.type === "invalid_format") {
    return (
      <ValidationAlert
        message={
          <div>
            <Title level={4}>Invalid format</Title>
            <Paragraph>
              The spreadsheet returns unsupported data format:{" "}
              <b>{_.get(validationData, "data.format", "unknown")}</b>. Please
              use .csv instead.
            </Paragraph>
            <Paragraph>
              <img src={formatImageUrl("https://unicorn-cdn.b-cdn.net/c0584081-a2e4-47e8-b56e-6da5cd5d1809/")} />
            </Paragraph>
          </div>
        }
        type={"error"}
        handleOnTryAgain={handleOnTryAgain}
      />
    );
  }

  if (validationData.type === "sheet_request_error") {
    return (
      <ValidationAlert
        message={
          <div>
            <Title level={4}>Connection error</Title>
            <Paragraph>
              Couldn't connect to the provided Google Sheets URL. Please make
              sure the URL is correct and try again.
            </Paragraph>
            <Paragraph>
              You can get the URL in the "File" {">"} "Share" {">"} "Publish to
              web" menu in Google Sheets.
            </Paragraph>
            <Paragraph>
              <img src={formatImageUrl("https://unicorn-cdn.b-cdn.net/bb86d1c6-429e-4370-bb8b-807ba8eb308a/")} />
            </Paragraph>
          </div>
        }
        type={"error"}
        handleOnTryAgain={handleOnTryAgain}
      />
    );
  }

  if (validationData.type === "front:generic_url") {
    return (
      <ValidationAlert
        message={
          <div>
            <Title level={4}>Invalid URL</Title>
            <Paragraph>
              Please make sure you're using the correct source URL. It can be
              found in the "File" {">"} "Share" {">"} "Publish to web" menu in
              Google Sheets.
            </Paragraph>
            <Paragraph>
              <img src={formatImageUrl("https://unicorn-cdn.b-cdn.net/bb86d1c6-429e-4370-bb8b-807ba8eb308a/")} />
            </Paragraph>
          </div>
        }
        type={"error"}
      />
    );
  }

  if (
    validationData.type === "no_matching_data" ||
    validationData.type === "no_data"
  ) {
    return (
      <ValidationAlert
        message={
          <div>
            <Title level={4}>Spreadsheet is empty</Title>
            <Paragraph>
              This spreadsheet appears to be empty. It could be due to cache.
              Please wait a few moments and try again.
            </Paragraph>
            <Paragraph>
              If the problem persists, make sure your spreadsheet has the
              necessary data.
            </Paragraph>
          </div>
        }
        type={"error"}
        handleOnTryAgain={handleOnTryAgain}
      />
    );
  }

  if (validationData.status !== "success") {
    const message = validationData.details || "Internal server error";
    return (
      <ValidationAlert
        message={
          <div>
            <Title level={4}>Error</Title>
            <Paragraph>Error details: "{message}".</Paragraph>
            <Paragraph>
              Please try again. If the issue persists,{" "}
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  openChat();
                }}
              >
                {isChatLoading && <Icon type="loading" />} contact us
              </a>
              .
            </Paragraph>
          </div>
        }
        type={"error"}
        handleOnTryAgain={handleOnTryAgain}
      />
    );
  }

  const keys = validationData.keys || [];
  const keysJsx = keys.map((key: string, i) => {
    const buttonId = `field-button-${i}`;
    clipboardRef.current = new ClipboardJS(`#${buttonId}`);
    return (
      <li key={i}>
        <Button
          size="small"
          data-clipboard-target={`#${buttonId}`}
          id={buttonId}
          style={{ userSelect: "all" }}
          onClick={() => {
            antMessage.success("Copied to clipboard", 2);
          }}
        >
          {`{{$${key}}}`}
        </Button>
      </li>
    );
  });
  const pages = validationData.pages || [];
  const truncatedPages = pages.slice(0, MAX_PAGES_TO_SHOW);
  const pagesJsx = truncatedPages.map((page: string, i) => {
    let path = `${currentWebsitePage.url}/${page}`;
    if (currentWebsitePage.url) {
      path = `/${path}`;
    }
    if (!currentWebsitePage.is_published) {
      return (
        <li key={i}>
          <span>{path}</span>
        </li>
      );
    }
    return (
      <li key={i}>
        <a
          href={`https://${getActiveDomain(currentWebsite)}${path}/`}
          target="_blank"
          className="underlined_link"
        >
          {path}
        </a>
      </li>
    );
  });

  return (
    <>
      <ValidationAlert
        message={
          <div>
            <div style={{ marginBottom: "10px" }}>
              Validation successful. You can insert data from this sheet into
              the page by using the following syntax: {"{{$column_name}}"}.
              Available columns:
            </div>
            <ul
              style={{
                listStyle: "none",
                margin: 0,
                display: "flex",
                gap: "5px",
                flexWrap: "wrap",
              }}
            >
              {keysJsx}
            </ul>
          </div>
        }
        type={"success"}
        noButtons
      />
      <ValidationAlert
        message={
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <div>Successfully generated inner pages:</div>
            <ul
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                listStyle: "none",
                margin: 0,
              }}
            >
              {pagesJsx}
            </ul>
            {truncatedPages.length < pages.length && (
              <div>...and {pages.length - truncatedPages.length} more.</div>
            )}
            {!currentWebsitePage.is_published && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                  alignItems: "flex-end",
                }}
              >
                <div>
                  Please publish the parent page to make the inner pages
                  available.
                </div>
                <Button
                  loading={websitesPages.isWaitingForPublishPageResponse}
                  size="small"
                  type="primary"
                  onClick={() => {
                    publishPage(true, currentWebsitePage.url, currentWebsite);
                  }}
                >
                  Publish{" "}
                  {trimStringTo(
                    currentWebsitePage.url
                      ? `"/${currentWebsitePage.url}"`
                      : "home page",
                    30
                  )}
                </Button>
              </div>
            )}
          </div>
        }
        type={"success"}
        noButtons
      />
      <ValidationAlert
        message={
          <div>
            Successfully updated{" "}
            <a
              href={`https://${getActiveDomain(currentWebsite)}/sitemap.xml`}
              target="_blank"
              className="underlined_link"
            >
              sitemap.xml
            </a>
            .
          </div>
        }
        type={"success"}
        noButtons
      />
    </>
  );
};

const UpdateApiSourceForm = (props: Props): JSX.Element => {
  const {
    websitesPages,
    getCurrentStoreData,
    changePageItemGlobalData,
    changeWebsitePagesStateGlobalData,
    auth,
    editApiSourceValidationData,
    fetchAndSaveApiValidationData,
    value,
    onChangeValue,
    onPressEnter,
  } = props;
  const { currentWebsitePage, currentWebsite } = getCurrentStoreData();
  const cancelTokenSourceRef = useRef<CancelTokenSource>(null);

  const isValidationLoading: boolean = _.get(
    currentWebsitePage,
    "apiSourceValidationData.isLoading",
    false
  );
  const validationData = _.get(
    currentWebsitePage,
    "apiSourceValidationData.data"
  );

  const handleOnTryAgain = (successMessage?: string) => {
    editApiSourceValidationData({
      pageId: currentWebsitePage.id,
      newData: {
        data: null,
      },
    });
    fetchAndSaveApiValidationData(value, {}, successMessage);
  };

  useEffect(() => {
    if (isValidationLoading || !!validationData) return;
    fetchAndSaveApiValidationData(value, {});
  }, []);

  return (
    <div>
      <Input
        name="api_source"
        id="api_source"
        required={false}
        placeholder="https://docs.google.com/spreadsheets/d/e/2PACX-1vRpvYGp8InTZEKzDkoG_vSm2qri6Jt0pjgEuWJqpNSy9_TMqd19c-YJk6duRHF084BWHSI9o-KyPAhs/pub?output=csv"
        type="text"
        value={value}
        onChange={(e) => {
          onChangeValue(e.target.value);
        }}
        onPressEnter={onPressEnter}
      />
      {isValidationLoading && (
        <div
          style={{
            marginTop: "10px",
            display: "flex",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <Icon type="loading" style={{ color: "var(--ant)" }} />
          Validating...
        </div>
      )}
      {!!validationData && validationData.type !== "hide_validation" && (
        <div className="update-api-source-form__validation-message-container">
          <ValidationMessage
            validationData={validationData}
            isValidateLoading={isValidationLoading}
            handleOnTryAgain={handleOnTryAgain}
            {...props}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  websitesPages: state.websitesPages,
  auth: state.auth,
});
export default connect(mapStateToProps, {
  getCurrentStoreData,
  changePageItemGlobalData,
  changeWebsitePagesStateGlobalData,
  editApiSourceValidationData,
  fetchAndSaveApiValidationData,
})(UpdateApiSourceForm);
