import {companyLogos, publicUrl} from "../../data/urls";
import { formatImageUrl } from "../strings/formatImageUrl";

let getIntegrationLogoSourceFromTitle = (integrationTitle:string|undefined) => {
    let integrationLogoPreifx = publicUrl + companyLogos.prefix;

    switch (integrationTitle) {
        case "mailchimp":
            return integrationLogoPreifx + 'mailchimp-small.svg';
        case "zapier":
            return integrationLogoPreifx + 'zapier-small.svg';
        case "salesforce":
            return integrationLogoPreifx + 'salesforce.svg';
        case "ifttt":
            return integrationLogoPreifx + 'ifttt-small.svg';
        case "pipedrive":
            return integrationLogoPreifx + 'pipedrive-small.png';
        case "slack":
            return integrationLogoPreifx + 'slack-small.svg';
        case "telegram":
            return integrationLogoPreifx + 'telegram-small.svg';
        case "airtable":
            return integrationLogoPreifx + 'airtable-small.svg';
        case "hubspot":
            return integrationLogoPreifx + 'hubspot.svg';
        case "mailerlite":
            return integrationLogoPreifx + 'mailerlite.svg';
        case "google-sheet":
            return integrationLogoPreifx + 'google-sheets-small.svg';
        case "webhook":
            return integrationLogoPreifx + 'webhook.svg';
        case "email":
            return integrationLogoPreifx + 'email.svg';
        case "cms":
            return formatImageUrl("https://unicorn-cdn.b-cdn.net/f9af5dfb-7938-4faa-a77f-2f0d809fac27/cms-outline.svg");
        default:
            return integrationLogoPreifx + 'brick.svg';
    }
};

export default getIntegrationLogoSourceFromTitle;
