import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Alert, Button, Divider, Empty, Icon, Popover, Tabs } from "antd";
import SpinnerBox from "./SpinnerBox";
import { getCurrentWebsitePageUrl } from "../../helpers/getCurrentWebsitePageUrl";
import SettingsBox from "./SettingsBox";
import UpdateUrlForm from "./UpdateUrlForm";
import UpdateMetaTitleForm from "./UpdateMetaTitleForm";
import UpdateMetaDescriptionForm from "./UpdateMetaDescriptionForm";
import PublishPageForm from "./PublishPageForm";
import { getCurrentWebsite } from "../../helpers/getCurrentWebsite";
import { getCurrentWebsitePage } from "../../helpers/getCurrentWebsitePage";
import trimStringTo from "../../helpers/strings/trimStringTo";
import AddPageHeadCustomCodeForm from "./AddPageHeadCustomCodeForm";
import ExcludePageFromSitemapSwitch from "./ExcludePageFromSitemapSwitch";
import OpenGraphImageUploader from "./OpenGraphImageUploader";
import { api, apiUrlBase, urls } from "../../data/urls";
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_ERROR_TITLE,
  indexWebsitePageDashboardUrl,
  notificationDuration,
} from "../../data/constants";
import { connect } from "react-redux";
import {
  pageUrlUpdateWaitingForServer,
  pageUrlUpdateToggleInvalidAttempt,
  pageUrlUpdateErrorMessage,
  pageMetaTitleUpdateWaitingForServer,
  pageMetaTitleUpdateToggleInvalidAttempt,
  pageMetaTitleUpdateErrorMessage,
  pageMetaDescriptionUpdateWaitingForServer,
  pageMetaDescriptionUpdateToggleInvalidAttempt,
  pageMetaDescriptionUpdateErrorMessage,
  publishPageToggleWaitingForServer,
  toggleWaitingForCommonWebsitePageUpdate,
  changeWebsitePagesStateGlobalData,
  changePageItemGlobalData,
  editApiSourceValidationData,
} from "../../store/websitePages/actions";
import { ActiveMenu } from "./SettingsDrawerContent";
import GooglePreview from "./GooglePreview";
import {
  getCurrentStoreData,
  saveToLocalBackup,
  saveWebsiteBackup,
} from "../../store/websites/thunks";
import { GetCurrentStoreData } from "../../store/websites/types";
import UpdateApiSourceForm from "./UpdateApiSourceForm";
import EnableDynamicRouteSwitch from "./EnableDynamicRouteSwitch";
import CMSMenu from "../editor/ContentEditionBox/CMSMenu";
import { CmsType } from "../../enums/enums";
import _ from "lodash";
import {
  ChangePageItemGlobalData,
  ChangeWebsitePagesStateGlobalData,
  EditApiSourceValidationData,
} from "../../store/websitePages/types";
import { updatePageAsync } from "../../helpers/async/updatePageAsync";
import { openNotification } from "../../helpers/openNotification";
import { fetchAndSaveApiValidationData, fetchAndSaveCollectionKeys } from "../../store/websitePages/thunks";
import axios, { CancelTokenSource } from "axios";
import returnAuthHeaderForAJAX from "../../helpers/auth/returnAuthHeaderForAJAX";

interface Props {
  websitesPages: any;
  websites: any;
  auth: any;
  user: any;
  pageUrlUpdateWaitingForServer: any;
  pageUrlUpdateToggleInvalidAttempt: any;
  pageMetaTitleUpdateWaitingForServer: any;
  pageUrlUpdateErrorMessage: any;
  pageMetaTitleUpdateToggleInvalidAttempt: any;
  pageMetaTitleUpdateErrorMessage: any;
  pageMetaDescriptionUpdateWaitingForServer: any;
  pageMetaDescriptionUpdateToggleInvalidAttempt: any;
  pageMetaDescriptionUpdateErrorMessage: any;
  publishPageToggleWaitingForServer: any;
  toggleWaitingForCommonWebsitePageUpdate: any;

  saveWebsitePageDataInStore: any;
  publishPage: any;
  history: any;
  saveImageInfoInDB: any;
  removeImageFromDB: any;
  currentMenuTitle: ActiveMenu;
  getCurrentStoreData: GetCurrentStoreData;
  saveWebsiteBackup: any;
  changeWebsitePagesStateGlobalData: ChangeWebsitePagesStateGlobalData;
  changePageItemGlobalData: ChangePageItemGlobalData;

  editApiSourceValidationData: EditApiSourceValidationData;
  fetchAndSaveApiValidationData: any;
  saveToLocalBackup: any;
  fetchAndSaveCollectionKeys: any;
}
interface MyState {
  meta_title: string;
  meta_description: string;
  url: string;

  cmsType: CmsType;
  customApiUrl: string;
  unicornCollectionId: string;
  cmsUrl: string;
  cmsHasUnsavedChanges: boolean;
}
class WebsitePageSettings extends Component<Props, MyState> {
  constructor(props: Props) {
    super(props);
    const { currentWebsitePage } = this.props.getCurrentStoreData();

    this.state = {
      meta_title: currentWebsitePage.meta_title,
      meta_description: currentWebsitePage.meta_description,
      url: currentWebsitePage.url,

      cmsType: _.get(
        currentWebsitePage,
        "cms.cmsType",
        currentWebsitePage.api_source_url ? CmsType.GOOGLE_SHEETS : undefined
      ),
      cmsUrl: _.get(
        currentWebsitePage,
        "cms.googleSheets.url",
        currentWebsitePage.api_source_url
      ),
      customApiUrl: _.get(currentWebsitePage, "cms.customApi.url"),
      unicornCollectionId: _.get(
        currentWebsitePage,
        "cms.unicorn.collectionId"
      ),
      cmsHasUnsavedChanges: false,
    };
  }

  setMetaTitle = (meta_title: string) => {
    this.setState({ meta_title });
  };
  setMetaDescription = (meta_description: string) => {
    this.setState({ meta_description });
  };
  setUrl = (url: string) => {
    this.setState({ url });
  };

  componentDidMount(): void {
    //We want to remove all errors from inputs on render.
    this.clearUpdatePageUrl();
    this.clearUpdatePageMetaTitle();
    this.clearUpdatePageMetaDescription();
  }

  clearUpdatePageUrl = (): void => {
    //Remove all errors and make inputs normal colour (not red).
    this.props.pageUrlUpdateToggleInvalidAttempt(false);
    this.props.pageUrlUpdateErrorMessage(undefined);
  };

  clearUpdatePageMetaTitle = (): void => {
    //Remove all errors and make inputs normal colour (not red).
    this.props.pageMetaTitleUpdateToggleInvalidAttempt(false);
    this.props.pageMetaTitleUpdateErrorMessage(undefined);
  };

  clearUpdatePageMetaDescription = (): void => {
    //Remove all errors and make inputs normal colour (not red).
    this.props.pageMetaDescriptionUpdateToggleInvalidAttempt(false);
    this.props.pageMetaDescriptionUpdateErrorMessage(undefined);
  };

  render() {
    const currentWebsite = getCurrentWebsite(this.props.websites.items);

    if (this.props.websites.dataFetched) {
      // After the form is rendered, it fills in default values for the inputs. That means if user entered something and don't save that, his changes may disappear.
      let currentWebsite = getCurrentWebsite(this.props.websites.items);

      if (currentWebsite === undefined) {
        // If no such website, offer to go to the main dashboard screen.
        return (
          <div style={{ paddingTop: "40px", paddingBottom: "40px" }}>
            <Empty description={<span>Website not found</span>}>
              <Link to="/">
                <Button type="primary">Back to your websites</Button>
              </Link>
            </Empty>
          </div>
        );
      }

      // After we check the website, check the page:
      let websitesPagesArray = this.props.websitesPages.items;
      let currentWebsitePageUrl = getCurrentWebsitePageUrl(
        currentWebsite,
        websitesPagesArray
      );
      let currentWebsitePageUrlToDisplay = currentWebsitePageUrl;
      if (currentWebsitePageUrlToDisplay === "") {
        currentWebsitePageUrlToDisplay = "home";
      } else {
        currentWebsitePageUrlToDisplay =
          "/" + trimStringTo(currentWebsitePageUrlToDisplay, 30);
      }
      let currentWebsitePage = getCurrentWebsitePage(
        currentWebsitePageUrl,
        websitesPagesArray,
        currentWebsite
      );

      if (currentWebsitePage === undefined) {
        // If no such website page, offer to go to the website pages list.
        return (
          <div style={{ paddingTop: "40px", paddingBottom: "40px" }}>
            <Empty description={<span>Website page not found</span>}>
              <Link to={"/" + currentWebsite.subdomain}>
                <Button type="primary">
                  {'View all pages of "' + currentWebsite.subdomain + '"'}
                </Button>
              </Link>
            </Empty>
          </div>
        );
      }

      let websitePageUrlToServer = currentWebsitePage.url;
      if (websitePageUrlToServer === "") {
        websitePageUrlToServer = indexWebsitePageDashboardUrl;
      }

      const { currentMenuTitle } = this.props;

      const handleOnSave = () => {
        this.props.changeWebsitePagesStateGlobalData({
          data: {
            isWaitingForUpdatePageSourceApiResponse: true,
          },
        });
        this.props.editApiSourceValidationData({
          pageId: currentWebsitePage.id,
          newData: {
            isLoading: false,
          },
        });
        const data = {
          cms: {
            ...currentWebsitePage.cms,
            cmsType: this.state.cmsType,
            googleSheets: {
              url: this.state.cmsUrl,
            },
            customApi: {
              url: this.state.customApiUrl,
            },
            unicorn: {
              collectionId: this.state.unicornCollectionId,
            },
          },
        };
        updatePageAsync({
          accessToken: this.props.auth.accessToken,
          subdomain: currentWebsite.subdomain,
          pageUrl: currentWebsitePage.url,
          data,
        })
          .then(() => {
            this.props.changePageItemGlobalData({
              pageId: currentWebsitePage.id,
              data,
            });
            this.props.editApiSourceValidationData({
              pageId: currentWebsitePage.id,
              newData: {
                data: null,
              },
            });
            if (data.cms.cmsType === "googleSheets") {
              this.props.fetchAndSaveApiValidationData(
                data.cms.googleSheets.url,
                {}
              );
            }
            if (data.cms.cmsType === "unicorn") {
              if (data.cms.unicorn.collectionId) {
                this.props.fetchAndSaveCollectionKeys(
                  data.cms.unicorn.collectionId
                );
              } else {
                this.props.editApiSourceValidationData({
                  pageId: currentWebsitePage.id,
                  newData: {
                    data: {
                      type: "hide_validation",
                    },
                  },
                });
              }
            }
            if (data.cms.cmsType === "custom" || data.cms.cmsType === "none") {
              this.props.editApiSourceValidationData({
                pageId: currentWebsitePage.id,
                newData: {
                  data: {
                    type: "hide_validation",
                  },
                },
              });
            }
          })
          .catch((error) => {
            console.error(error);
            openNotification(
              DEFAULT_ERROR_TITLE,
              DEFAULT_ERROR_MESSAGE,
              "OK",
              "error",
              notificationDuration.medium
            );
          })
          .finally(() => {
            this.props.changeWebsitePagesStateGlobalData({
              data: {
                isWaitingForUpdatePageSourceApiResponse: false,
              },
            });
            this.setState({ cmsHasUnsavedChanges: false });
          });
      };

      if (currentMenuTitle === "pageSettings") {
        return (
          <div className="settings__container">
            <SettingsBox titleText="Page settings">
              <div className="settings-box__input">
                <div className="settings-box__input_label">
                  <div>
                    <Popover
                      content={
                        <div>
                          How your website page will be <br />
                          accessible for visitors.{" "}
                          <span style={{ opacity: 0.7 }}>
                            Set it blank and
                            <br />
                            the page will become a homepage.
                          </span>
                        </div>
                      }
                    >
                      <Icon type="info-circle" />
                    </Popover>
                  </div>
                  <div className="settings-box__input_info_text">Page url:</div>
                </div>

                <UpdateUrlForm
                  currentWebsite={currentWebsite}
                  currentWebsitePage={currentWebsitePage}
                  websites={this.props.websites}
                  clearUpdatePageUrl={this.clearUpdatePageUrl}
                  auth={this.props.auth}
                  websitesPages={this.props.websitesPages}
                  history={this.props.history}
                  saveWebsitePageDataInStore={
                    this.props.saveWebsitePageDataInStore
                  }
                  pageUrlUpdateWaitingForServer={
                    this.props.pageUrlUpdateWaitingForServer
                  }
                  pageUrlUpdateErrorMessage={
                    this.props.pageUrlUpdateErrorMessage
                  }
                  pageUrlUpdateToggleInvalidAttempt={
                    this.props.pageUrlUpdateToggleInvalidAttempt
                  }
                  url={this.state.url}
                  setUrl={this.setUrl}
                />
              </div>

              <div className="settings-box__input">
                <div className="settings-box__input_label">
                  <div>
                    <Popover
                      content={
                        <div>
                          Publish your page to make it accessible for visitors.
                          <br />
                        </div>
                      }
                    >
                      <Icon type="info-circle" />
                    </Popover>
                  </div>
                  <div className="settings-box__input_info_text">
                    Publish page:
                  </div>
                </div>

                <PublishPageForm
                  currentWebsite={currentWebsite}
                  currentWebsitePage={currentWebsitePage}
                  websites={this.props.websites}
                  auth={this.props.auth}
                  websitesPages={this.props.websitesPages}
                  publishPageToggleWaitingForServer={
                    this.props.publishPageToggleWaitingForServer
                  }
                  publishPage={this.props.publishPage}
                />
              </div>

              <Divider className="settings__divider" />

              <div className="settings-box__input publish-page-form__input-box">
                <div
                  className="settings-box__input_label"
                  style={{ marginBottom: 0, marginTop: "4px" }}
                >
                  <div>
                    <Popover
                      content={
                        <div>
                          Meta title of this page.
                          <br />
                          Optimal length is 50-60 characters.
                          <br />
                          {this.state.meta_title
                            ? ` Yours is ${this.state.meta_title.length} characters.`
                            : ""}
                        </div>
                      }
                    >
                      <Icon type="info-circle" />
                    </Popover>
                  </div>
                  <div className="settings-box__input_info_text">Title:</div>
                </div>

                <UpdateMetaTitleForm
                  currentWebsite={currentWebsite}
                  currentWebsitePage={currentWebsitePage}
                  websites={this.props.websites}
                  clearUpdatePageMetaTitle={this.clearUpdatePageMetaTitle}
                  auth={this.props.auth}
                  websitesPages={this.props.websitesPages}
                  saveWebsitePageDataInStore={
                    this.props.saveWebsitePageDataInStore
                  }
                  pageMetaTitleUpdateWaitingForServer={
                    this.props.pageMetaTitleUpdateWaitingForServer
                  }
                  pageMetaTitleUpdateToggleInvalidAttempt={
                    this.props.pageMetaTitleUpdateToggleInvalidAttempt
                  }
                  pageMetaTitleUpdateErrorMessage={
                    this.props.pageMetaTitleUpdateErrorMessage
                  }
                  meta_title={this.state.meta_title}
                  setMetaTitle={this.setMetaTitle}
                />
              </div>

              <div className="settings-box__input publish-page-form__input-box">
                <div
                  className="settings-box__input_label"
                  style={{ marginBottom: 0, marginTop: "4px" }}
                >
                  <div>
                    <Popover
                      content={
                        <div>
                          Meta description of this page.
                          <br />
                          Optimal length is 120-158 characters.
                          <br />
                          {this.state.meta_description
                            ? ` Yours is ${this.state.meta_description.length} characters.`
                            : ""}
                        </div>
                      }
                    >
                      <Icon type="info-circle" />
                    </Popover>
                  </div>
                  <div className="settings-box__input_info_text">
                    Description:
                  </div>
                </div>

                <UpdateMetaDescriptionForm
                  currentWebsite={currentWebsite}
                  currentWebsitePage={currentWebsitePage}
                  websites={this.props.websites}
                  clearUpdatePageMetaDescription={
                    this.clearUpdatePageMetaDescription
                  }
                  auth={this.props.auth}
                  websitesPages={this.props.websitesPages}
                  saveWebsitePageDataInStore={
                    this.props.saveWebsitePageDataInStore
                  }
                  pageMetaDescriptionUpdateWaitingForServer={
                    this.props.pageMetaDescriptionUpdateWaitingForServer
                  }
                  pageMetaDescriptionUpdateToggleInvalidAttempt={
                    this.props.pageMetaDescriptionUpdateToggleInvalidAttempt
                  }
                  pageMetaDescriptionUpdateErrorMessage={
                    this.props.pageMetaDescriptionUpdateErrorMessage
                  }
                  meta_description={this.state.meta_description}
                  setMetaDescription={this.setMetaDescription}
                />
              </div>

              <GooglePreview
                overrideTitle={this.state.meta_title}
                overrideDescription={this.state.meta_description}
                urlsArray={[this.state.url]}
                type="page"
              />

              <Divider className="settings__divider" />

              <div className="settings-box__input">
                <div className="settings-box__input_label">
                  <div>
                    <Popover
                      content={
                        <div style={{ width: 325 }}>
                          Displayed when this page is shared on social media. If
                          not set, we'll use the image from General settings.
                          Recommended size: 1200x630px.
                          <br />
                          <br />
                          If you don't specify any image, we'll look for one in
                          your site's content.
                        </div>
                      }
                    >
                      <Icon type="info-circle" />
                    </Popover>
                  </div>
                  <div className="settings-box__input_info_text">
                    Page social image:{" "}
                    <span className="settings-box__input_info_subtext">
                      a.k.a. Open Graph image
                    </span>
                  </div>
                </div>

                <OpenGraphImageUploader
                  settingsType="page"
                  saveWebsitePageDataInStore={
                    this.props.saveWebsitePageDataInStore
                  }
                  auth={this.props.auth}
                  currentWebsitePage={currentWebsitePage}
                  currentWebsite={currentWebsite}
                  websitesPages={this.props.websitesPages}
                  saveImageInfoInDB={this.props.saveImageInfoInDB}
                  removeImageFromDB={this.props.removeImageFromDB}
                  apiUrl={
                    api.websitePages.updateWebsitePage.prefix +
                    currentWebsite.subdomain +
                    "/" +
                    websitePageUrlToServer +
                    api.websitePages.updateWebsitePage.postfix
                  }
                  currentMetaTitle={this.state.meta_title}
                  currentMetaDescription={this.state.meta_description}
                  disableLiveUpdate
                />
              </div>

              <Divider className="settings__divider" />

              <div className="settings-box__input">
                <div className="settings-box__input_label">
                  <div>
                    <Popover
                      content={
                        <div style={{ width: 375 }}>
                          Specify the CMS that will supply dynamic data for this
                          page in JSON format. You can access this data using
                          the following syntax: "{"Hello {{$name}}"}
                          ".
                        </div>
                      }
                    >
                      <Icon type="info-circle" />
                    </Popover>
                  </div>
                  <div className="settings-box__input_info_text">
                    Data source:
                  </div>
                </div>
                {/* <UpdateApiSourceForm
                  publishPage={this.props.publishPage}
                /> */}
                <CMSMenu
                  cmsType={this.state.cmsType}
                  customApiUrl={this.state.customApiUrl}
                  unicornCollectionId={this.state.unicornCollectionId}
                  cmsUrl={this.state.cmsUrl}
                  onSelectCollection={(v) => {
                    this.setState({
                      unicornCollectionId: v,
                      cmsHasUnsavedChanges: true,
                    });
                  }}
                  onChangeCustomApiUrl={(v) => {
                    this.setState({
                      customApiUrl: v,
                      cmsHasUnsavedChanges: true,
                    });
                  }}
                  onChangeGoogleSheetsUrl={(v) => {
                    this.setState({ cmsUrl: v, cmsHasUnsavedChanges: true });
                  }}
                  onChangeCmsType={(v) => {
                    this.setState({ cmsType: v, cmsHasUnsavedChanges: true });
                  }}
                  hasUnsavedChanges={this.state.cmsHasUnsavedChanges}
                  onSaveChanges={handleOnSave}
                  hideLabels
                  allowCmsClear
                  className="is-no-inputs"
                  inputDebounceTime={1}
                  saveButtonLoading={
                    this.props.websitesPages
                      .isWaitingForUpdatePageSourceApiResponse
                  }
                  customGoogleSheetsInputJsx={
                    <UpdateApiSourceForm
                      publishPage={this.props.publishPage}
                      value={this.state.cmsUrl}
                      onChangeValue={(v) => {
                        this.setState({
                          cmsUrl: v,
                          cmsHasUnsavedChanges: true,
                        });
                      }}
                      onPressEnter={handleOnSave}
                    />
                  }
                />
              </div>

              <Divider className="settings__divider" />

              <div className="settings-box__input">
                <div className="settings-box__input_label">
                  <div>
                    <Popover
                      content={
                        <div>
                          Exclude utility pages from your sitemap.xml. <br />
                          Example of a utility page: successful subscription
                          confirmation.
                          <br />
                        </div>
                      }
                    >
                      <Icon type="info-circle" />
                    </Popover>
                  </div>
                  <div className="settings-box__input_info_text">
                    Exclude the page from sitemap.xml:
                  </div>
                </div>

                <ExcludePageFromSitemapSwitch
                  currentWebsite={currentWebsite}
                  currentWebsitePage={currentWebsitePage}
                  websites={this.props.websites}
                  auth={this.props.auth}
                  websitesPages={this.props.websitesPages}
                  saveWebsitePageDataInStore={
                    this.props.saveWebsitePageDataInStore
                  }
                />
              </div>

              <Divider className="settings__divider" />

              <div className="settings-box__input">
                <div className="settings-box__input_label">
                  <div className="settings-box__input_info_text">
                    Custom{" "}
                    <code>
                      <span style={{ opacity: 0.4 }}>&lt;</span>head
                      <span style={{ opacity: 0.4 }}>&gt;</span>
                    </code>{" "}
                    code:
                  </div>
                </div>
                <AddPageHeadCustomCodeForm
                  currentWebsite={currentWebsite}
                  currentWebsitePage={currentWebsitePage}
                  websites={this.props.websites}
                  auth={this.props.auth}
                  user={this.props.user}
                  websitesPages={this.props.websitesPages}
                  waitingForServer={
                    this.props.toggleWaitingForCommonWebsitePageUpdate
                  }
                  saveWebsitePageDataInStore={
                    this.props.saveWebsitePageDataInStore
                  }
                  isWaitingForServer={
                    this.props.websitesPages
                      .isWaitingForCommonWebsitePageUpdateResponse
                  }
                  saveWebsiteBackup={this.props.saveWebsiteBackup}
                  saveToLocalBackup={this.props.saveToLocalBackup}
                />
              </div>
            </SettingsBox>
          </div>
        );
      }
      return null;
    } else {
      return <SpinnerBox text="Page settings are loading..." />;
    }
  }
}

const mapStateToProps = (props) => {
  return {
    websitesPages: props.websitesPages,
    websites: props.websites,
    auth: props.auth,
    user: props.user,
  };
};
const mapActionsToProps = {
  pageUrlUpdateWaitingForServer,
  pageUrlUpdateToggleInvalidAttempt,
  pageUrlUpdateErrorMessage,
  pageMetaTitleUpdateWaitingForServer,
  pageMetaTitleUpdateToggleInvalidAttempt,
  pageMetaTitleUpdateErrorMessage,
  pageMetaDescriptionUpdateWaitingForServer,
  pageMetaDescriptionUpdateToggleInvalidAttempt,
  pageMetaDescriptionUpdateErrorMessage,
  publishPageToggleWaitingForServer,
  toggleWaitingForCommonWebsitePageUpdate,
  getCurrentStoreData,
  saveWebsiteBackup,

  changeWebsitePagesStateGlobalData,
  changePageItemGlobalData,

  editApiSourceValidationData,
  fetchAndSaveApiValidationData,
  saveToLocalBackup,
  fetchAndSaveCollectionKeys,
};
export default connect(mapStateToProps, mapActionsToProps)(WebsitePageSettings);
