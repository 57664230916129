import DebouncedInput from "../../../DebouncedInput";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { InputProps } from "../interfaces";
import { useCurrentInputData } from "../useCurrentInputData";
import {
  Button,
  Dropdown,
  Icon,
  Menu,
  Select,
  Switch,
  Tooltip,
  Typography,
} from "antd";
import _ from "lodash";
import DebouncedInputNumber from "../../../DebouncedInputNumber";
import { connect } from "react-redux";
import { GetCurrentStoreData } from "../../../../store/websites/types";
import { getCurrentStoreData } from "../../../../store/websites/thunks";
import { CancelTokenSource } from "axios";
import { fetchAndSaveApiValidationData, fetchAndSaveCollectionKeys } from "../../../../store/websitePages/thunks";
import { WebsitePagesState } from "../../../../store/websitePages/types";
import { getCurrentWebsitesDynamicPages } from "../../../../helpers/getCurrentWebsitesDynamicPages";
import { checkIfStringContainsProtocol } from "../../../../helpers/checkIfStringContainsProtocol";
import { ValidationAlert } from "../../../dashboard/UpdateApiSourceForm";
import { urls } from "../../../../data/urls";
import { getActiveDomain } from "../../../../helpers/websites/getActiveDomain";
import { slugifyNew } from "../../../../helpers/strings/slugifyNew";
import ContentInputLabel from "../../../ui_components/ContentInputLabel";
import Cluster from "../../../ui_components/Cluster";
import HorInputGroup from "../../../ui_components/HorInputGroup";
import VerInputGroup from "../../../ui_components/VerInputGroup";
import InputTitle from "../../../ui_components/InputTitle";
import { FilterData } from "../../../../helpers/types/globalTypes";
import DebouncedTextarea from "../../../DebouncedTextarea";
import { checkIfPageIsDynamic } from "../../../../helpers/checkIfPageIsDynamic";
import { CmsType } from "../../../../enums/enums";

const { Paragraph, Title } = Typography;

interface Props extends InputProps {
  getCurrentStoreData: GetCurrentStoreData;
  fetchAndSaveApiValidationData: any;
  websitesPages: WebsitePagesState;
  fetchAndSaveCollectionKeys: any;
}

interface UrlWarningProps extends Props {
  currentWebsitesDynamicPages: any[];
  dynamicUrlPrefix: string;
  sampleRowsHaveAbsolutePath: boolean;
  isHomePageDynamic: boolean;
  currentRowsSample: any[];
  dynamicUrl: string;
}
const UrlWarning = (props: UrlWarningProps) => {
  const {
    currentWebsitesDynamicPages,
    dynamicUrlPrefix,
    sampleRowsHaveAbsolutePath,
    isHomePageDynamic,
    getCurrentStoreData,
    websitesPages,
    currentRowsSample,
    dynamicUrl,
  } = props;

  const { currentWebsitePage, currentWebsite } = getCurrentStoreData();
  if (
    !!currentWebsitePage &&
    checkIfPageIsDynamic(currentWebsitePage) &&
    dynamicUrlPrefix &&
    dynamicUrlPrefix.toString().startsWith("{{")
  ) {
    return null;
  }

  const currentWebsitePages = websitesPages.items.filter((item) => {
    return (
      currentWebsite && item.website === currentWebsite.id && !item.is_deleted
    );
  });
  const prefixPage = currentWebsitePages.find((page) => {
    return page.url === (dynamicUrlPrefix || "");
  });

  const isNoPrefix = !dynamicUrlPrefix || dynamicUrlPrefix === "no_prefix";
  const isDynamicPagesExist = currentWebsitesDynamicPages.length > 0;
  const isPageNotExist = !prefixPage;
  const isPrefixPageNotDynamic = !!prefixPage && !checkIfPageIsDynamic(prefixPage);

  let message = null;
  let title = "Invalid URLs";
  let type: "success" | "info" | "error" | "warning" = "warning";

  if (isNoPrefix && !isHomePageDynamic) {
    if (isDynamicPagesExist) {
      message =
        "These URLs seem to be leading nowhere because you didn't select a dynamic page. Please select an existing dynamic page in the prefix dropdown or create a new one.";
    } else {
      message =
        "These URLs seem to be leading nowhere because you didn't select a dynamic page. Please create a dynamic page and select it in the prefix dropdown.";
    }
  }

  if (!isNoPrefix && isPageNotExist) {
    if (isDynamicPagesExist) {
      message = `These URLs seem to be leading nowhere because the page "/${
        dynamicUrlPrefix || ""
      }" doesn't exist. Please select an existing dynamic page in the prefix dropdown or create a new one.`;
    } else {
      message = `These URLs seem to be leading nowhere because the page "/${
        dynamicUrlPrefix || ""
      }" doesn't exist. Please create a dynamic page and select it in the prefix dropdown.`;
    }
  }

  if (!isNoPrefix && isPrefixPageNotDynamic) {
    message = `These URLs seem to be leading nowhere because the page "/${
      dynamicUrlPrefix || ""
    }" is not dynamic. Please add the Google Sheets source URL in this page's settings.`;
  }

  if (!message || sampleRowsHaveAbsolutePath) {
    type = "success";
    title = "URLs are valid";
    message = "Directory items will open the following dynamic pages on click:";
  }

  if (sampleRowsHaveAbsolutePath) {
    message = "Directory items will open the following URLs on click:";
  }

  let sampleRowValues = currentRowsSample
    .map((row) => row[dynamicUrl])
    .slice(0, 4);
  sampleRowValues = [...new Set(sampleRowValues)];

  return (
    <div
      className="update-api-source-form__validation-message-container"
      style={{ marginRight: 0 }}
    >
      <ValidationAlert
        message={
          <div>
            <Title level={4}>{title}</Title>
            <Paragraph style={{ textWrap: "pretty" } as any}>
              {message}
            </Paragraph>
            {type === "success" && (
              <Paragraph style={{ textWrap: "pretty" } as any}>
                <ul
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    listStyle: "none",
                    margin: 0,
                  }}
                >
                  {sampleRowValues.map((val, i) => {
                    if (sampleRowValues.length > 3 && i === 2) {
                      return (
                        <li
                          key={i}
                          style={{ listStyleType: "none", margin: 0 }}
                        >
                          ...
                        </li>
                      );
                    }
                    let value = (val || "").toString();
                    let domainPrefix = "";
                    let urlPrefix = "";
                    if (!checkIfStringContainsProtocol(value)) {
                      value = slugifyNew(value);
                      if (!value.startsWith("/")) {
                        value = `/${value}`;
                      }
                      domainPrefix = `https://${getActiveDomain(
                        currentWebsite
                      )}`;
                      urlPrefix =
                        dynamicUrlPrefix && dynamicUrlPrefix !== "no_prefix"
                          ? `/${dynamicUrlPrefix}`
                          : "";
                    }
                    return (
                      <li
                        key={i}
                        style={
                          {
                            listStyleType: "none",
                            margin: 0,
                            maxWidth: "100%",
                            textWrap: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          } as any
                        }
                      >
                        <a
                          href={`${domainPrefix}${urlPrefix}${value}`}
                          target="_blank"
                          className="underlined_link"
                        >
                          {urlPrefix}
                          {value}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </Paragraph>
            )}
            {!sampleRowsHaveAbsolutePath && (
              <Paragraph>
                Learn more about dynamic pages in{" "}
                <a
                  href={urls.guides.dynamicDataGoogleSheets}
                  className="underlined_link"
                  target="_blank"
                >
                  our guide
                </a>
                .
              </Paragraph>
            )}
          </div>
        }
        type={type}
        noButtons={true}
      />
    </div>
  );
};

interface FilterProps extends Props {
  onChangeHandler: (value: any, callback: any) => void;
  setSearchValue: (value: string) => void;
  availableColumnsJsx: any;
  isValidationLoading: boolean;
  availableCmsProps: string[];
  searchValue: string;
  selectContainerRef: React.RefObject<HTMLDivElement>;

  filterData: FilterData;
  index: number;
  itemsLength: number;
}
const Filter = (props: FilterProps) => {
  const {
    content,
    onChangeHandler,
    generator,
    setSearchValue,
    availableColumnsJsx,
    isValidationLoading,
    availableCmsProps,
    filterData,
    searchValue,
    selectContainerRef,
    index,
    itemsLength,
  } = props;

  const categoryColumn = _.get(content, "categoryColumn", "category");
  const filteredTagsMode: "all" | "any" = _.get(
    content,
    "filteredTagsMode",
    "all"
  );
  const showByFilters = _.get(filterData, "showByFilters", {
    items: "",
    mode: "any",
  });

  const tagFilterOptions = [...filterData.filters, ...availableCmsProps].map(
    (tag) => {
      if (
        (tag || "").toString().startsWith("{{") &&
        !searchValue.startsWith("{")
      ) {
        return null;
      }
      if (showByFilters.items.includes(tag)) {
        return null;
      }
      return (
        <Option key={tag} value={tag}>
          {tag}
        </Option>
      );
    }
  );

  return (
    <Cluster
      index={index}
      itemsLength={itemsLength}
      onMoveUp={() => {
        onChangeHandler(filterData.id, generator.moveDirectoryFilterV2Up);
      }}
      onMoveDown={() => {
        onChangeHandler(filterData.id, generator.moveDirectoryFilterV2Down);
      }}
      noHover
      onDelete={() => {
        onChangeHandler(filterData.id, generator.deleteDirectoryFilterV2);
      }}
    >
      <HorInputGroup>
        <HorInputGroup>
          <div>
            <ContentInputLabel>Source</ContentInputLabel>
            <Select
              showSearch
              placeholder="Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option.props.children as string)
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              getPopupContainer={() => selectContainerRef.current}
              value={filterData.column}
              onChange={(value) => {
                onChangeHandler(
                  {
                    id: filterData.id,
                    column: value,
                  },
                  generator.changeDirectoryFiltersV2
                );
              }}
              className="content-input__select"
              onSearch={setSearchValue}
              onBlur={() => setSearchValue("")}
              onSelect={() => setSearchValue("")}
              style={{ width: "100%" }}
            >
              {availableColumnsJsx}
              {isValidationLoading && (
                <Option value="loading" disabled>
                  Loading global properties...
                </Option>
              )}
            </Select>
          </div>
        </HorInputGroup>
        <div>
          <ContentInputLabel>Label</ContentInputLabel>
          <DebouncedInput
            placeholder="Categories"
            defaultValue={filterData.label}
            onChange={(value) => {
              onChangeHandler(
                {
                  id: filterData.id,
                  label: value,
                },
                generator.changeDirectoryFiltersV2
              );
            }}
          />
        </div>
        <div>
          <ContentInputLabel>Sorting</ContentInputLabel>
          <Select
            style={{ width: "100%" }}
            value={filterData.sorting || "no_sorting"}
            onChange={(value) => {
              onChangeHandler(
                {
                  id: filterData.id,
                  sorting: value,
                },
                generator.changeDirectoryFiltersV2
              );
            }}
            onSearch={setSearchValue}
            onBlur={() => setSearchValue("")}
            onSelect={() => setSearchValue("")}
            getPopupContainer={() => selectContainerRef.current}
          >
            <Option value="no_sorting">No sorting</Option>
            <Option value="a-z">A-Z</Option>
            <Option value="z-a">Z-A</Option>
          </Select>
        </div>
      </HorInputGroup>
      {([...filterData.filters, ...availableCmsProps].length > 0 ||
        showByFilters.items.length > 0) && (
        <div>
          <ContentInputLabel
            title={`Keep only selected filters. The filters bar is hidden if only 1 filter is selected. Switch "All" and "Any" to change the logic for multiple filters.`}
          >
            Show items by filters
          </ContentInputLabel>
          <HorInputGroup gap="small" equalSpace={false}>
            <Select
              mode="multiple"
              placeholder="Select tags"
              value={showByFilters.items.split(";").filter((tag) => !!tag)}
              getPopupContainer={() => selectContainerRef.current}
              allowClear
              showArrow
              onChange={(value) => {
                onChangeHandler(
                  {
                    id: filterData.id,
                    showByFilters: {
                      ...showByFilters,
                      items: value.join(";"),
                    },
                  },
                  generator.changeDirectoryFiltersV2
                );
              }}
              onSearch={setSearchValue}
              onBlur={() => setSearchValue("")}
              onSelect={() => setSearchValue("")}
            >
              {tagFilterOptions}
              {isValidationLoading && (
                <Option value="loading" disabled>
                  Loading global properties...
                </Option>
              )}
            </Select>
            {showByFilters.items.split(";").length > 1 && (
              <Tooltip
                title={
                  showByFilters.mode === "all"
                    ? "Items are displayed if they match every filter."
                    : "Items are displayed if they match at least one filter."
                }
                mouseLeaveDelay={0}
                overlayStyle={{ width: 190 }}
                placement="topRight"
                getPopupContainer={() => selectContainerRef.current}
              >
                <Button
                  onClick={() => {
                    onChangeHandler(
                      {
                        id: filterData.id,
                        showByFilters: {
                          ...showByFilters,
                          mode: showByFilters.mode === "all" ? "any" : "all",
                        },
                      },
                      generator.changeDirectoryFiltersV2
                    );
                  }}
                >
                  {showByFilters.mode === "all" ? "All" : "Any"}
                </Button>
              </Tooltip>
            )}
          </HorInputGroup>
        </div>
      )}
      <div style={{ display: "flex", alignItems: "center", gap: 38 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 10,
          }}
        >
          <ContentInputLabel noMargin>Multiselect</ContentInputLabel>
          <Switch
            size="small"
            defaultChecked={filterData.multiselect === "on"}
            onChange={(value) => {
              onChangeHandler(
                {
                  id: filterData.id,
                  multiselect: value ? "on" : "off",
                },
                generator.changeDirectoryFiltersV2
              );
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 10,
          }}
        >
          <ContentInputLabel noMargin>Hide filters</ContentInputLabel>
          <Switch
            size="small"
            defaultChecked={filterData.isHidden === "hidden"}
            onChange={(value) => {
              onChangeHandler(
                {
                  id: filterData.id,
                  isHidden: value ? "hidden" : "visible",
                },
                generator.changeDirectoryFiltersV2
              );
            }}
          />
        </div>
      </div>
    </Cluster>
  );
};

interface ItemOnClickProps extends Props {
  searchValue: string;
  setSearchValue: (value: string) => void;
  selectContainerRef: React.RefObject<HTMLDivElement>;
  isValidationLoading: boolean;
  onChangeHandler: (value: any, callback: any) => void;
  availableColumns: string[];
  availableCmsProps: string[];
}
const ItemOnClick = (props: ItemOnClickProps) => {
  const {
    websitesPages,
    currentWebsite,
    onChangeHandler,
    content,
    availableColumns,
    availableCmsProps,
    searchValue,
    setSearchValue,
    generator,
    selectContainerRef,
    isValidationLoading,
  } = props;

  const currentWebsitesDynamicPages = getCurrentWebsitesDynamicPages(
    websitesPages,
    currentWebsite
  );
  const dynamicPageUrls = currentWebsitesDynamicPages
    .map((page) => page.url)
    .filter((url) => !!url);
  const isHomePageDynamic = !!currentWebsitesDynamicPages.find((page) => {
    return page.url === "";
  });
  const directoryItemHtml = _.get(content, "directoryItemHtml", "");
  const currentRowsSample = _.get(content, "currentRowsSample", []);
  const dynamicUrlPrefix = _.get(content, "dynamicUrlPrefix", "");
  const dynamicUrl = _.get(content, "dynamicUrl", "");
  const dynamicTarget = _.get(content, "dynamicTarget", "new_tab");
  const cmsType = _.get(content, "cmsType", CmsType.GOOGLE_SHEETS);
  const sampleRowsHaveAbsolutePath = useMemo(() => {
    return currentRowsSample.some((row) => {
      return checkIfStringContainsProtocol(row[dynamicUrl]);
    });
  }, [currentRowsSample, dynamicUrl]);
  const availableSortedUrlColumns = [...availableColumns, ...availableCmsProps]
    .filter((column) => {
      return column !== "slugified_title";
    })
    .filter((column) => {
      if (cmsType === CmsType.UNICORN && column !== "slug" && column !== "url") {
        return false;
      }
      return true;
    })
    .sort((a, b) => {
      const aCol = a.toString().toLowerCase();
      const bCol = b.toString().toLowerCase();
      if (aCol === "title") return -1;
      if (bCol === "title") return 1;
      if (aCol === "url") return -1;
      if (bCol === "url") return 1;
      if (aCol === "pageurl") return -1;
      if (bCol === "pageurl") return 1;
      if (aCol === "link") return -1;
      if (bCol === "link") return 1;
      if (aCol === "page") return -1;
      if (bCol === "page") return 1;
      if (aCol === "slug") return -1;
      if (bCol === "slug") return 1;
      return 0;
    })
    .map((column) => {
      const c = column.toString().toLowerCase();
      if (
        (c || "").toString().startsWith("{{") &&
        !searchValue.startsWith("{")
      ) {
        return null;
      }
      const isHighlighted =
        c === "title" ||
        c === "url" ||
        c === "pageurl" ||
        c === "link" ||
        c === "page" ||
        c === "slug";
      let sampleRowValues = currentRowsSample
        .map((row) => row[column])
        .slice(0, 3);
      sampleRowValues = [...new Set(sampleRowValues)];
      return (
        <Option
          key={column}
          value={column}
          style={{ textDecoration: isHighlighted && cmsType !== CmsType.UNICORN ? "underline" : "none" }}
        >
          <Tooltip
            title={
              !(column || "").toString().startsWith("{{") &&
              sampleRowValues.length > 0 && (
                <div>
                  {sampleRowValues.map((val, i) => {
                    let value = (val || "").toString();
                    if (!checkIfStringContainsProtocol(value)) {
                      value = slugifyNew(value);
                      if (!value.startsWith("/")) {
                        value = `/${value}`;
                      }
                    }
                    return (
                      <div
                        style={{
                          maxWidth: 200,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                        key={i}
                      >
                        {value}
                      </div>
                    );
                  })}
                </div>
              )
            }
            placement="left"
            mouseLeaveDelay={0}
          >
            <div className="content-input__select-option-wrapper">{column}</div>
          </Tooltip>
        </Option>
      );
    });

  const targetMenu = (
    <Menu
      selectedKeys={[dynamicTarget]}
      onClick={({ key }) => {
        onChangeHandler(key, generator.changeDirectoryDynamicTarget);
      }}
    >
      <Menu.Item key="new_tab">New tab</Menu.Item>
      <Menu.Item key="same_tab">Same tab</Menu.Item>
    </Menu>
  );

  const dynamicTargetTitleMap = {
    new_tab: {
      title: "New tab",
      tooltip: "Links will open in a new browser tab.",
    },
    same_tab: {
      title: "Same tab",
      tooltip: "Links will open in the same browser tab.",
    },
  };

  const isPrefixInputVisible = !sampleRowsHaveAbsolutePath;

  const isDynamicTargetInputVisible = useMemo(() => {
    return (directoryItemHtml || "").toString().includes("{{dynamicTarget}}");
  }, [directoryItemHtml]);
  const isDynamicUrlInputsVisible = useMemo(() => {
    return (directoryItemHtml || "").toString().includes("{{dynamicUrl}}");
  }, [directoryItemHtml]);

  const dynamicPageOptions = [...dynamicPageUrls, ...availableCmsProps].map(
    (url) => {
      const currentUrl = (url || "").toString();
      if (currentUrl.startsWith("{{") && !searchValue.startsWith("{")) {
        return null;
      }
      return (
        <Option key={url} value={url}>
          {currentUrl.startsWith("{{") ? currentUrl : `/${currentUrl}`}
        </Option>
      );
    }
  );

  let dynamicUrlInputLabel = "Items on click";
  let dynamicUrlInputTooltip =
    "Specify which URLs will be opened when you click on the directory items. Choose the field in your CMS that contains the URL. Add a prefix to open a dynamic page (e.g. /tools/unicornplatform).";
  if (!isDynamicUrlInputsVisible && isDynamicTargetInputVisible) {
    dynamicUrlInputLabel = "Items URL target";
    dynamicUrlInputTooltip =
      "Specify where the URLs will be opened when you click on the directory items.";
  }

  return (
    <>
      {(isDynamicUrlInputsVisible || isDynamicTargetInputVisible) && (
        <div>
          <ContentInputLabel title={dynamicUrlInputTooltip}>
            {dynamicUrlInputLabel}
          </ContentInputLabel>
          <HorInputGroup gap="small" equalSpace={false}>
            {isDynamicUrlInputsVisible && (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  gap: 10,
                  alignItems: "center",
                }}
              >
                {isPrefixInputVisible && (
                  <Select
                    showSearch
                    placeholder="Select prefix"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option.props.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    className="content-input__select"
                    getPopupContainer={() => selectContainerRef.current}
                    style={{ width: 136 }}
                    value={dynamicUrlPrefix}
                    onChange={(value) => {
                      onChangeHandler(
                        value,
                        generator.changeDirectoryDynamicUrlPrefix
                      );
                    }}
                    onSearch={setSearchValue}
                    onBlur={() => setSearchValue("")}
                    onSelect={() => setSearchValue("")}
                  >
                    <Option key="no_prefix">
                      {isHomePageDynamic ? "Home page" : "No prefix"}
                    </Option>
                    {dynamicPageOptions}
                    {isValidationLoading && (
                      <Option value="loading" disabled>
                        Loading global properties...
                      </Option>
                    )}
                  </Select>
                )}
                {isPrefixInputVisible && (
                  <span style={{ color: "rgba(0,0,0,0.45)" }}>/</span>
                )}
                <Select
                  showSearch
                  placeholder="Select field"
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return (
                      (option.props.value || "")
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  className="content-input__select"
                  getPopupContainer={() => selectContainerRef.current}
                  onChange={(value) => {
                    onChangeHandler(value, generator.changeDirectoryDynamicUrl);
                  }}
                  dropdownClassName="content-input__select-dropdown-with-tooltips"
                  value={dynamicUrl}
                  style={{ width: 136 }}
                  onSearch={setSearchValue}
                  onBlur={() => setSearchValue("")}
                  onSelect={() => setSearchValue("")}
                >
                  {availableSortedUrlColumns}
                  {isValidationLoading && (
                    <Option value="loading" disabled>
                      <div className="content-input__select-option-wrapper">
                        Loading global properties...
                      </div>
                    </Option>
                  )}
                </Select>
              </div>
            )}
            {isDynamicTargetInputVisible && (
              <Tooltip
                title={dynamicTargetTitleMap[dynamicTarget].tooltip}
                mouseLeaveDelay={0}
                placement={isDynamicUrlInputsVisible ? "topRight" : "right"}
                getPopupContainer={() => selectContainerRef.current}
                overlayStyle={{ maxWidth: 200 }}
              >
                <Dropdown
                  overlay={targetMenu}
                  trigger={["click"]}
                  getPopupContainer={() => selectContainerRef.current}
                >
                  <Button>
                    {dynamicTargetTitleMap[dynamicTarget].title}{" "}
                    <Icon type="down" />
                  </Button>
                </Dropdown>
              </Tooltip>
            )}
          </HorInputGroup>
          {isDynamicUrlInputsVisible && (
            <UrlWarning
              {...props}
              currentWebsitesDynamicPages={currentWebsitesDynamicPages}
              dynamicUrlPrefix={dynamicUrlPrefix}
              sampleRowsHaveAbsolutePath={sampleRowsHaveAbsolutePath}
              isHomePageDynamic={isHomePageDynamic}
              currentRowsSample={currentRowsSample}
              dynamicUrl={dynamicUrl}
            />
          )}
        </div>
      )}
    </>
  );
};

interface SearchBarProps extends Props {
  onChangeHandler: (value: any, callback: any) => void;
}
const SearchBar = (props: SearchBarProps) => {
  const { onChangeHandler, generator, content } = props;
  return (
    <DebouncedInput
      onChange={(value) => {
        onChangeHandler(value, generator.changeDirectorySearchPlaceholder);
      }}
      placeholder="Search among {{amount}} items"
      defaultValue={content.searchPlaceholder}
      id={`directory-searchPlaceholder`}
    />
  );
};

interface NoResultsProps extends Props {
  onChangeHandler: (value: any, callback: any) => void;
}
const NoResults = (props: NoResultsProps) => {
  const { onChangeHandler, generator, content } = props;
  return (
    <DebouncedTextarea
      onChange={(value) => {
        onChangeHandler(value, generator.changeDirectoryNotfoundPlaceholder);
      }}
      placeholder="No results found"
      defaultValue={content.notFoundPlaceholder}
      id={`directory-notFoundPlaceholder`}
      autosize={{ minRows: 1, maxRows: 32 }}
    />
  );
};

interface ShowMoreProps extends Props {
  onChangeHandler: (value: any, callback: any) => void;
}
const ShowMore = (props: ShowMoreProps) => {
  const { onChangeHandler, generator, content } = props;
  return (
    <HorInputGroup>
      <div>
        <ContentInputLabel title='Sets the maximum number of items to display initially. Additional items will be accessible via a "Show all" button.'>
          Item limit
        </ContentInputLabel>
        <DebouncedInputNumber
          onChange={(value) => {
            onChangeHandler(value, generator.changeDirectoryMaxItems);
          }}
          placeholder="4"
          defaultValue={content.maxItems}
          id={`directory-maxItems`}
          min={1}
          max={1000}
          style={{ width: "100%" }}
        />
      </div>
      <div>
        <ContentInputLabel title="The text displayed on the button that reveals all items when clicked. Leave empty to hide the button.">
          Button text
        </ContentInputLabel>
        <DebouncedInput
          onChange={(value) => {
            onChangeHandler(value, generator.changeDirectoryShowMoreButtonText);
          }}
          placeholder="Show all"
          defaultValue={content.showMoreButtonText}
          id={`directory-showMoreButtonText`}
        />
      </div>
    </HorInputGroup>
  );
};

interface SortingProps extends Props {
  onChangeHandler: (value: any, callback: any) => void;
  selectContainerRef: React.RefObject<HTMLDivElement>;
  setSearchValue: (value: string) => void;
  availableColumnsJsx: any;
  isValidationLoading: boolean;
}
const Sorting = (props: SortingProps) => {
  const {
    selectContainerRef,
    onChangeHandler,
    content,
    generator,
    setSearchValue,
    availableColumnsJsx,
    isValidationLoading,
  } = props;
  const sortingField: string = _.get(content, "sortingField", "");
  const sortingOrder: "ascending" | "descending" = _.get(
    content,
    "sortingOrder",
    "ascending"
  );
  return (
    <HorInputGroup equalSpace={false} gap="small">
      <Select
        showSearch
        placeholder="Select field"
        optionFilterProp="children"
        filterOption={(input, option) =>
          (option.props.children as string)
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        }
        getPopupContainer={() => selectContainerRef.current}
        value={sortingField}
        onChange={(value) => {
          onChangeHandler(value, generator.changeDirectorySortingField);
        }}
        className="content-input__select"
        onSearch={setSearchValue}
        onBlur={() => setSearchValue("")}
        onSelect={() => setSearchValue("")}
      >
        <Option value="no_sorting">No sorting</Option>
        <Option value="random_sorting">Random</Option>
        {availableColumnsJsx}
        {isValidationLoading && (
          <Option value="loading" disabled>
            Loading global properties...
          </Option>
        )}
      </Select>
      {!!sortingField &&
        sortingField !== "no_sorting" &&
        sortingField !== "random_sorting" && (
          <Tooltip
            title={
              sortingOrder === "ascending"
                ? "Ascending order."
                : "Descending order."
            }
            mouseLeaveDelay={0}
            placement="topRight"
            getPopupContainer={() => selectContainerRef.current}
          >
            <Button
              icon={`sort-${sortingOrder}`}
              onClick={() => {
                onChangeHandler(
                  sortingOrder === "ascending" ? "descending" : "ascending",
                  generator.changeDirectorySortingOrder
                );
              }}
            />
          </Tooltip>
        )}
    </HorInputGroup>
  );
};

interface ItemVisibilityProps extends Props {
  selectContainerRef: React.RefObject<HTMLDivElement>;
  setSearchValue: (value: string) => void;
  isValidationLoading: boolean;
  onChangeHandler: (value: any, callback: any) => void;
  generator: any;
  content: any;
  availableColumns: string[];
  availableCmsProps: string[];
  searchValue: string;
}
const ItemVisibility = (props: ItemVisibilityProps) => {
  const {
    selectContainerRef,
    setSearchValue,
    isValidationLoading,
    onChangeHandler,
    generator,
    content,
    availableColumns,
    availableCmsProps,
    searchValue,
  } = props;

  const filterFieldsMode: "all" | "any" = _.get(
    content,
    "filterFieldsMode",
    "all"
  );

  const filterFieldsArray: string[] = _.get(content, "filterFields", "")
    .toString()
    .split(";")
    .map((t) => t.trim())
    .filter((tag) => tag);

  const handleOnFilterFieldChange = (newFilterFields: string[]) => {
    onChangeHandler(
      newFilterFields.join(";"),
      generator.changeDirectoryFilterFields
    );
  };

  const fieldFilterOptions = [...availableColumns, ...availableCmsProps].map(
    (column) => {
      if (
        (column || "").toString().startsWith("{{") &&
        !searchValue.startsWith("{")
      ) {
        return null;
      }
      if (
        filterFieldsArray.includes(column) ||
        filterFieldsArray.includes(`-${column}`)
      ) {
        return null;
      }
      return (
        <OptGroup key={column}>
          <Option value={column} label={`"${column}" exists`}>
            Visible if <b>{column}</b> is TRUE
          </Option>
          <Option value={`-${column}`} label={`"${column}" doesn't exist`}>
            Visible if <b>{column}</b> is FALSE
          </Option>
        </OptGroup>
      );
    }
  );

  return (
    <HorInputGroup gap="small" equalSpace={false}>
      <Select
        mode="multiple"
        placeholder="Select fields"
        value={filterFieldsArray}
        getPopupContainer={() => selectContainerRef.current}
        allowClear
        showArrow
        onChange={handleOnFilterFieldChange}
        onSearch={setSearchValue}
        onBlur={() => setSearchValue("")}
        onSelect={() => setSearchValue("")}
      >
        {fieldFilterOptions}
        {isValidationLoading && (
          <Option value="loading" disabled>
            Loading global properties...
          </Option>
        )}
      </Select>
      {filterFieldsArray.length > 1 && (
        <Tooltip
          title={
            filterFieldsMode === "all"
              ? "Items are displayed if they match every condition."
              : "Items are displayed if they match some of the conditions."
          }
          mouseLeaveDelay={0}
          overlayStyle={{ width: 225 }}
          placement="topRight"
          getPopupContainer={() => selectContainerRef.current}
        >
          <Button
            onClick={() => {
              onChangeHandler(
                filterFieldsMode === "all" ? "any" : "all",
                generator.changeDirectoryFilterFieldsMode
              );
            }}
          >
            {filterFieldsMode === "all" ? "All" : "Any"}
          </Button>
        </Tooltip>
      )}
    </HorInputGroup>
  );
};

const { Option, OptGroup } = Select;
const Directory: FC<any> = (props: Props) => {
  const {
    saveContentInStore,
    generator,
    contentType,
    currentInputData,
    toggleNewEditionsDetected,
    content,
    getCurrentStoreData,
    fetchAndSaveApiValidationData,
  } = props;
  const { currentWebsitePage } = getCurrentStoreData();

  const currentInputDataRef = useCurrentInputData(currentInputData);

  const selectContainerRef = useRef(null);
  const cancelTokenSourceRef = useRef<CancelTokenSource>(null);
  const [searchValue, setSearchValue] = useState("");

  const isValidationLoading: boolean = _.get(
    currentWebsitePage,
    "apiSourceValidationData.isLoading",
    false
  );
  const validationData = _.get(
    currentWebsitePage,
    "apiSourceValidationData.data"
  );

  useEffect(() => {
    if (isValidationLoading || !!validationData) return;
    const cmsType = _.get(currentWebsitePage, "cms.cmsType");
    const collectionId = _.get(currentWebsitePage, "cms.unicorn.collectionId");
    const googleSheetsUrl = _.get(currentWebsitePage, "cms.googleSheets.url");
    if (!cmsType) {
      fetchAndSaveApiValidationData(
        currentWebsitePage.api_source_url,
        cancelTokenSourceRef
      );
    }
    if (cmsType === "unicorn" && !!collectionId) {
      props.fetchAndSaveCollectionKeys(collectionId);
    }
    if (cmsType === "googleSheets" && !!googleSheetsUrl) {
      fetchAndSaveApiValidationData(
        googleSheetsUrl,
        cancelTokenSourceRef
      );
    }
  }, []);

  let onChangeHandler = (value: any, callback) => {
    saveContentInStore(
      callback({ [contentType]: currentInputDataRef.current }, value)
    );
    toggleNewEditionsDetected(true);
  };
  const availableColumns: string[] = _.get(
    content,
    "availableColumns",
    []
  ).filter((c) => !!c);

  const availableCmsProps: string[] = _.get(
    currentWebsitePage,
    "apiSourceValidationData.data.keys",
    []
  ).map((key: string) => `{{$${key}}}`);

  const availableColumnsJsx = [...availableColumns, ...availableCmsProps].map(
    (column) => {
      if (
        (column || "").toString().startsWith("{{") &&
        !searchValue.startsWith("{")
      ) {
        return null;
      }
      return (
        <Option key={column} value={column}>
          {column}
        </Option>
      );
    }
  );

  const filters = content.filtersV2.items.map((filterData: FilterData, i) => {
    return (
      <Filter
        {...props}
        key={filterData.id}
        onChangeHandler={onChangeHandler}
        setSearchValue={setSearchValue}
        availableColumnsJsx={availableColumnsJsx}
        isValidationLoading={isValidationLoading}
        availableCmsProps={availableCmsProps}
        filterData={filterData}
        searchValue={searchValue}
        selectContainerRef={selectContainerRef}
        index={i}
        itemsLength={content.filtersV2.items.length}
      />
    );
  });

  return (
    <VerInputGroup gap="medium" containerRef={selectContainerRef}>
      <div>
        <InputTitle>Search & Filters</InputTitle>
        <div style={{ display: "flex", flexDirection: "column", gap: 23 }}>
          <div>
            <ContentInputLabel title="Placeholder text for the search bar. Use {{amount}} to display the total number of items in the directory. Leave empty to hide the search bar.">
              Search placeholder
            </ContentInputLabel>
            <SearchBar {...props} onChangeHandler={onChangeHandler} />
          </div>
          <div>
            <ContentInputLabel>Filters</ContentInputLabel>
            {filters}
            <Button
              size="small"
              icon="plus"
              onClick={() => {
                onChangeHandler(undefined, generator.addDirectoryFilterV2);
              }}
            >
              Add filters
            </Button>
          </div>
        </div>
      </div>
      <div>
        <InputTitle>Items</InputTitle>
        <div style={{ display: "flex", flexDirection: "column", gap: 23 }}>
          <div>
            <ShowMore {...props} onChangeHandler={onChangeHandler} />
          </div>
          <div>
            <ContentInputLabel title="Text displayed when no items are matching search or filters.">
              Text for empty state
            </ContentInputLabel>
            <NoResults {...props} onChangeHandler={onChangeHandler} />
          </div>
          <div>
            <ContentInputLabel title="Specify the column to sort the items by. Select ascending order to display the items from A to Z or from 0 to 9. Select descending order to display the items from Z to A or from 9 to 0.">
              Sort items
            </ContentInputLabel>
            <Sorting
              {...props}
              onChangeHandler={onChangeHandler}
              selectContainerRef={selectContainerRef}
              setSearchValue={setSearchValue}
              availableColumnsJsx={availableColumnsJsx}
              isValidationLoading={isValidationLoading}
            />
          </div>
          <div>
            <ContentInputLabel
              title={`Display items only if they match certain conditions. The value is considered FALSE if it's empty or 'false'. Otherwise, it's TRUE. Switch "All" and "Any" to change the logic for multiple conditions.`}
            >
              Show by columns
            </ContentInputLabel>
            <ItemVisibility
              {...props}
              selectContainerRef={selectContainerRef}
              setSearchValue={setSearchValue}
              isValidationLoading={isValidationLoading}
              onChangeHandler={onChangeHandler}
              generator={generator}
              content={content}
              availableColumns={availableColumns}
              availableCmsProps={availableCmsProps}
              searchValue={searchValue}
            />
          </div>
          <div>
            <ItemOnClick
              {...props}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              selectContainerRef={selectContainerRef}
              isValidationLoading={isValidationLoading}
              onChangeHandler={onChangeHandler}
              availableColumns={availableColumns}
              availableCmsProps={availableCmsProps}
            />
          </div>
        </div>
      </div>
    </VerInputGroup>
  );
};

export const mapStateToProps = (state: any) => {
  return {
    websitesPages: state.websitesPages,
  };
};
export default connect(mapStateToProps, {
  getCurrentStoreData,
  fetchAndSaveApiValidationData,
  fetchAndSaveCollectionKeys,
})(Directory);
