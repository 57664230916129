import React, { useEffect, useRef } from "react";
import { Alert, Button, Icon, message } from "antd";
import { connect } from "react-redux";
import { getCurrentStoreData } from "../../store/websites/thunks";
import { WebsitePagesState } from "../../store/websitePages/types";
import { GetCurrentStoreData } from "../../store/websites/types";
import { checkIfPageIsDynamic } from "../../helpers/checkIfPageIsDynamic";
import _ from "lodash";
import {
  fetchAndSaveApiValidationData,
  fetchAndSaveCollectionKeys,
} from "../../store/websitePages/thunks";
import ClipboardJS from "clipboard";
import { CmsType } from "../../enums/enums";

interface Props {
  websitesPages: WebsitePagesState;
  getCurrentStoreData: GetCurrentStoreData;
  fetchAndSaveApiValidationData: any;
  fetchAndSaveCollectionKeys: any;
}

const AvailableGlobalProps = (props: Props): JSX.Element => {
  const {
    websitesPages,
    getCurrentStoreData,
    fetchAndSaveApiValidationData,
    fetchAndSaveCollectionKeys,
  } = props;
  const { currentWebsitePage } = getCurrentStoreData();
  const clipboardRef = useRef(null);

  const cms = _.get(currentWebsitePage, "cms", {});

  const isValidationLoading: boolean = _.get(
    currentWebsitePage,
    "apiSourceValidationData.isLoading",
    false
  );
  const validationData = _.get(
    currentWebsitePage,
    "apiSourceValidationData.data"
  );

  useEffect(() => {
    if (
      isValidationLoading ||
      !!validationData ||
      !checkIfPageIsDynamic(currentWebsitePage)
    )
      return;
    if (
      cms.cmsType === "googleSheets" &&
      cms.googleSheets &&
      cms.googleSheets.url
    ) {
      fetchAndSaveApiValidationData(cms.googleSheets.url, {});
    }
    if (!cms.cmsType && currentWebsitePage.api_source_url) {
      fetchAndSaveApiValidationData(currentWebsitePage.api_source_url, {});
    }
    if (cms.cmsType === "unicorn" && cms.unicorn && cms.unicorn.collectionId) {
      fetchAndSaveCollectionKeys(cms.unicorn.collectionId);
    }
  }, []);

  if (!currentWebsitePage || !checkIfPageIsDynamic(currentWebsitePage)) {
    return null;
  }

  const showAvailableProps =
    !isValidationLoading && cms.cmsType !== CmsType.CUSTOM;

  const keys = _.get(validationData, "keys", []);
  const keysJsx = keys.map((key: string, i) => {
    const buttonId = `field-button-page-${i}`;
    clipboardRef.current = new ClipboardJS(`#${buttonId}`);
    return (
      <li key={i}>
        <Button
          size="small"
          data-clipboard-target={`#${buttonId}`}
          id={buttonId}
          style={{ userSelect: "all" }}
          onClick={() => {
            message.success("Copied to clipboard", 2);
          }}
        >
          {`{{$${key}}}`}
        </Button>
      </li>
    );
  });

  return (
    <Alert
      style={{
        maxWidth: 640,
        margin: "0 auto",
        marginBottom: 24,
        marginTop: -26,
      }}
      message={
        <div>
          <div
            style={{
              marginBottom: 0,
            }}
          >
            This is a dynamic page <a target="_blank" href="https://help.unicornplatform.com/en/article/dynamic-data-from-api-source-fazl04/">(<span style={{textDecoration: "none"}}>?</span>)</a>
          </div>
          {isValidationLoading && (
            <div style={{ marginBottom: 0, marginTop: "1em" }}>
              Available properties:{" "}
              <Icon style={{ color: "#1890ff", marginLeft: 5 }} type="loading" />
            </div>
          )}
          {showAvailableProps && (
            <div style={{ marginBottom: 0, marginTop: "1em" }}>
              Available properties:
              <ul
                style={{
                  listStyle: "none",
                  margin: 0,
                  display: "flex",
                  gap: "5px",
                  flexWrap: "wrap",
                  marginTop: 6,
                }}
              >
                {keysJsx}
              </ul>
            </div>
          )}
        </div>
      }
      type="success"
    />
  );
};

const mapStateToProps = (state) => {
  return {
    websitesPages: state.websitesPages,
  };
};
export default connect(mapStateToProps, {
  getCurrentStoreData,
  fetchAndSaveApiValidationData,
  fetchAndSaveCollectionKeys,
})(AvailableGlobalProps);
