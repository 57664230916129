import React, {Component} from "react";
import { Button, Input } from 'antd';
import {api} from "../../data/urls";
import axios from "axios";
import returnAuthHeaderForAJAX from "../../helpers/auth/returnAuthHeaderForAJAX";
import {openNotification} from "../../helpers/openNotification";
import {getErrorValue} from "../../helpers/editor/getErrorValue";
import fireAnalyticsEvent from "../../helpers/editor/fireAnalyticsEvent";
import {getErrorKey} from "../../helpers/editor/getErrorKey";
import {compareEditedValueWithCurrent} from "../../helpers/editor/compareEditedValueWithCurrent";
import { handleBadWebsiteError } from "../../helpers/handleBadWebsiteError";
import { CrispEvents } from "../../enums/AnalyticsEventsEnums";
import _ from "lodash";

interface Props {
    websites: any,
    blogs: any,
    blogPosts: any,
    clearUpdateBlogMetaTitle: any,
    auth: any,

    currentWebsite: any,
    currentBlog: any,

    saveBlogDataInStore: any,

    blogMetaTitleUpdateWaitingForServer: any,
    blogMetaTitleUpdateToggleInvalidAttempt: any,
    blogMetaTitleUpdateErrorMessage: any,
    meta_title: string,
    setMetaTitle: (meta_title: string) => void,
}
type MyState = {};
class UpdateBlogMetaTitleForm extends Component<Props, MyState>{
    componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any): void {
        // We set the new state only when we change a settings active website or page.
        // We want to set up an initial value of a new picked website or page.
        if((this.props.currentWebsite.subdomain !== nextProps.currentWebsite.subdomain) || (this.props.currentBlog.url !== nextProps.currentBlog.url)){
            this.props.setMetaTitle(nextProps.currentBlog.meta_title);
            // We also clear errors when switch a website or a page.
            this.props.clearUpdateBlogMetaTitle();
        }
    };

    handleMetaTitleUpdate = (e:any):void => {
        e.preventDefault();

        this.props.blogMetaTitleUpdateWaitingForServer(true);

        //We want to remove all errors from inpu  ts on a new request.
        this.props.clearUpdateBlogMetaTitle();


        let newMetaTitle = this.props.meta_title;


        let accessToken = this.props.auth.accessToken;
        let apiUrl = api.blogs.updateBlog.prefix + this.props.currentBlog.id + api.blogs.updateBlog.postfix;
        axios.patch(apiUrl, {meta_title: newMetaTitle, subdomain: this.props.currentWebsite.subdomain}, {...returnAuthHeaderForAJAX(accessToken)})
            .then(() => {
                // Server gives the object of the saved website page. Throw it in the store.
                const dataToStore = {
                  data: {
                    ...this.props.currentBlog,
                    meta_title: newMetaTitle
                  }
                }
                this.props.saveBlogDataInStore(dataToStore, this.props.currentBlog.id);

                let successMessage = <span>Successfully changed the blog home page title to <b>{newMetaTitle}</b>.</span>;
                if(newMetaTitle === ''){
                    successMessage = <span>Successfully removed the blog home page title. Now it's blank.</span>;
                }
                
                fireAnalyticsEvent.fireCrisp(CrispEvents.changeBlogMetaTitle, {
                  subdomain: this.props.currentWebsite.subdomain,
                  blog_id: this.props.currentBlog.id,
                  meta_title: newMetaTitle,
                });
            })
            .catch(error => {
                if(error.response){
                    let errorData = error.response.data;
                    handleBadWebsiteError(errorData);

                    if (errorData.detail !== undefined){
                        let message = errorData.detail;

                        let errorObjectKey = getErrorKey(errorData);
                        let errorObjectValue = getErrorValue(errorData, errorObjectKey);

                        fireAnalyticsEvent.fireCrisp(CrispEvents.changeBlogMetaTitleError, {
                          error_type: errorObjectKey,
                          error_message: errorObjectValue,
                          subdomain: this.props.currentWebsite.subdomain,
                          blog_id: this.props.currentBlog.id,
                          meta_title: newMetaTitle,
                        }, true);

                        // This happens when user tries to change a website which doesn't exist or doesn't belog to the user.
                        openNotification('Server error', 'Error message: "' + message + '". This should not have happened. Please contact us.', 'OK', 'error');
                    }

                    if (errorData.locked !== undefined){
                        let message = errorData.locked;
                        openNotification('Denied', message, 'OK', 'warn');
                    }

                    if (errorData.meta_title !== undefined){
                        let message = errorData.meta_title.join(' ');
                        this.props.blogMetaTitleUpdateErrorMessage(message);
                        this.props.blogMetaTitleUpdateToggleInvalidAttempt(true);
                    }
                }
            })
            .then(response => {
                // always executed
                this.props.blogMetaTitleUpdateWaitingForServer(false);
            });
    };

    handleInputChange = (e:any) => {
        this.props.setMetaTitle(e.target.value);
    };

    render(){
        return (
            <form onSubmit={this.handleMetaTitleUpdate} className="settings-box__form" style={{display: "flex", gap: "10px", width: "300px", alignItems: "flex-start"}}>
                <div className="settings-box__input_box" style={{margin: 0, flexGrow: 1}}>
                  
                      <Input name="meta_title"
                            id="meta_title"
                            required={false}
                            placeholder="Enter meta title"
                            type="text"
                            value={this.props.meta_title}
                            onChange={this.handleInputChange}
                            style={{width: "100%"}}
                      />
                  
                  <div className="settings-box__error_message" style={{opacity: this.props.blogs.blogMetaTitleUpdateErrorMessage === undefined ? 0 : 1, marginTop: !this.props.blogs.blogMetaTitleUpdateErrorMessage ? 0 : "3px"}}>
                      {this.props.blogs.blogMetaTitleUpdateErrorMessage}
                  </div>
                </div>
                <Button
                    className="settings-box__form_save"
                    icon='save'
                    type="primary"
                    loading={this.props.blogs.isWaitingForBlogMetaTitleUpdateResponse}
                    disabled={this.props.blogs.isWaitingForBlogMetaTitleUpdateResponse}
                    htmlType="submit"
                    hidden={compareEditedValueWithCurrent(this.props.currentBlog.meta_title, this.props.meta_title)}
                    style={{bottom: 0}}
                />
            </form>
        )
    }
}

export default UpdateBlogMetaTitleForm;
