import React, { useState } from "react";
import "./ImagePicker.css";
import { Button, Icon, Modal, Tooltip } from "antd";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import { openNotification } from "../../helpers/openNotification";
import fireAnalyticsEvent from "../../helpers/editor/fireAnalyticsEvent";
import DebouncedInput from "../DebouncedInput";
import generateImageAltFromFileName from "../../helpers/editor/generateImageAltFromFileName";
import { CrispEvents } from "../../enums/AnalyticsEventsEnums";
import { formatImageUrl } from "../../helpers/strings/formatImageUrl";
import classNames from "classnames";
import { apiUrlBase } from "../../data/urls";
import { connect } from "react-redux";
import { AuthState } from "../../store/auth/types";
import { getCurrentStoreData } from "../../store/websites/thunks";
import { GetCurrentStoreData } from "../../store/websites/types";
import _ from "lodash";
import { checkIsProPlan } from "../../helpers/user/checkIsProPlan";
import { PlansState } from "../../store/plans/types";
import { UserState } from "../../store/user/types";
import isWebsiteSharedWithCurrentUser from "../../helpers/shareAccess/isWebsiteSharedWithCurrentUser";
import { SharedAccessesState } from "../../store/sharedAccesses/types";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize
);

interface Props {
  auth: AuthState;
  user: UserState;
  plans: PlansState;
  sharedAccesses: SharedAccessesState;
  currentImgSrc: string;
  saveImageInReduxStore: any;
  inputId: any;
  saveImageInfoInDB: any;
  currentImgUUID: any;
  removeImageFromDB: any;
  currentImgAlt?: string;
  saveImageAltInReduxStore?: any;
  height?: number;
  getCurrentStoreData: GetCurrentStoreData;
}

const ImagePicker: React.FC<Props> = (props) => {
  const [previewModalVisible, setPreviewModalVisible] = useState(false);
  const [removeConfirmVisible, setRemoveConfirmVisible] = useState(false);

  const showPreviewModal = () => setPreviewModalVisible(true);
  const handlePreviewCancel = () => setPreviewModalVisible(false);

  const handleImageAltUpdate = (newAlt: string) => {
    if (props.saveImageAltInReduxStore) {
      props.saveImageAltInReduxStore(newAlt);
    }
  };

  const handleSetImageAltWhenUpload = (imageName: string) => {
    if (imageName && imageName !== "") {
      let newAlt = generateImageAltFromFileName(imageName);
      if (props.saveImageAltInReduxStore) {
        props.saveImageAltInReduxStore(newAlt);
      }
    }
  };

  const handleOnImageUrlChange = (value: string) => {
    props.saveImageInReduxStore(value, undefined, undefined, undefined);
  };

  const displayImageAltEditor = (
    label: string,
    tooltip: string,
    onChangeHandler: (value: string) => void,
    defaultValue: string,
    placeholder: string,
    isVisible = true
  ) => {
    if (isVisible) {
      return (
        <div className="image-picker__alt_box">
          <DebouncedInput
            addonBefore={
              <div>
                <div className="content-input__tooltip_box">
                  <Tooltip placement="top" title={tooltip}>
                    <Icon
                      className="content-input__tooltip_icon"
                      type="info-circle"
                      style={{ marginRight: 3 }}
                    />
                  </Tooltip>
                  {label}
                </div>
              </div>
            }
            onChange={onChangeHandler}
            size="small"
            placeholder={placeholder}
            defaultValue={defaultValue}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  const displayEmptyImageSrcInput = () => {
    return (
      <>
        <span style={{ color: "#595959", fontSize: "12px" }}>or</span>
        <DebouncedInput
          onChange={handleOnImageUrlChange}
          placeholder="Enter image URL"
          defaultValue={props.currentImgSrc}
        />
      </>
    );
  };

  if (props.currentImgSrc === "") {
    const { currentWebsite } = props.getCurrentStoreData();
    const websiteId = _.get(currentWebsite, "id")
    const isProPlan = checkIsProPlan(props.user, props.plans)
    const isShared = isWebsiteSharedWithCurrentUser(props.sharedAccesses, websiteId, props.user.data.email).isTrue;
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          width: "100%",
          flexDirection: "column",
        }}
        className="image-picker__filepond"
      >
        <FilePond
          allowMultiple={false}
          credits={false}
          acceptedFileTypes={["image/*"]}
          labelFileTypeNotAllowed="Only images are allowed"
          maxFileSize={isProPlan || isShared ? "5MB" : "1MB"}
          labelMaxFileSizeExceeded="File is too large"
          server={{
            url: `${apiUrlBase}/api/v1/images/user_upload_to_s3?website_id=${websiteId}`,
            headers: {
              Authorization: `Token ${props.auth.accessToken}`,
            },
            process: {
              method: "POST",
              // @ts-ignore
              onload: (response) => {
                const responseData = JSON.parse(response).file;
                const uploadedImgSrc = responseData.url;
                if (uploadedImgSrc) {
                  props.saveImageInReduxStore(
                    uploadedImgSrc,
                    responseData.uuid,
                    responseData.width,
                    responseData.height
                  );
                  props.saveImageInfoInDB(responseData.meta_data);
                  handleSetImageAltWhenUpload(responseData.filename);

                  fireAnalyticsEvent.fireCrisp(
                    CrispEvents.uploadcareImageUpload,
                    {
                      image_url: uploadedImgSrc,
                      uuid: responseData.uuid,
                      size_bytes: responseData.size,
                    }
                  );
                } else {
                  fireAnalyticsEvent.fireCrisp(
                    CrispEvents.uploadcareImageUploadError,
                    {
                      error_type: "image_upload",
                      error_message: "ImagePicker.tsx upload error",
                    },
                    true
                  );

                  openNotification(
                    "Error :(",
                    "There was an unknown error during uploading the file. Please refresh the page and try again.",
                    "OK",
                    "warn"
                  );
                }
              },
              onerror: (error) => {
                console.error("FilePond error:", error);
                let errorJson: any = {};
                let message = "There was an error during file upload. Please try again.";
                try {
                  errorJson = JSON.parse(error);
                  message = errorJson.message || errorJson.detail;
                } catch (e) {
                  console.error("Error parsing error:", e);
                }
                openNotification(
                  "Error",
                  message,
                  "OK",
                  "error"
                );
              },
            },
          }}
          name="file"
          labelIdle="Upload image"
        />
        {displayEmptyImageSrcInput()}
      </div>
    );
  } else {
    return (
      <div style={{ width: "100%" }}>
        <div className="image-picker checkered-bg" style={{ width: "100%" }}>
          <div className="image-picker__controls_box">
            {!removeConfirmVisible && (
              <div
                className={classNames("image-picker__controls", {
                  "image-picker__controls--small":
                    props.saveImageAltInReduxStore === undefined,
                })}
              >
                <Button
                  onClick={showPreviewModal}
                  type="primary"
                  className="image-picker__button"
                  icon="arrows-alt"
                >
                  View
                </Button>
                <Button
                  onClick={() => setRemoveConfirmVisible(true)}
                  type="danger"
                  className="image-picker__button"
                  icon="delete"
                  htmlType="button"
                />
              </div>
            )}

            {removeConfirmVisible && (
              <div
                className={classNames("image-picker__controls", {
                  "image-picker__controls--small":
                    props.saveImageAltInReduxStore === undefined,
                })}
              >
                <Button
                  onClick={() => {
                    props.saveImageInReduxStore("", "");
                    props.removeImageFromDB(props.currentImgUUID);
                    setRemoveConfirmVisible(false);
                  }}
                  type="danger"
                  className="image-picker__button"
                  icon="warning"
                  htmlType="button"
                >
                  Delete
                </Button>

                <Button
                  onClick={() => setRemoveConfirmVisible(false)}
                  type="primary"
                  className="image-picker__button"
                  htmlType="button"
                >
                  Back
                </Button>
              </div>
            )}
          </div>
          <img
            height={props.height ? props.height : ""}
            className="image-picker__img"
            src={formatImageUrl(props.currentImgSrc)}
            alt={props.currentImgAlt}
          />

          {displayImageAltEditor(
            "ALT:",
            "The alt (alternative) text attribute describes an image. It is used by screen readers and search engine crawlers.",
            handleImageAltUpdate,
            props.currentImgAlt,
            "Explain the image",
            props.saveImageAltInReduxStore !== undefined
          )}
          {displayImageAltEditor(
            "URL:",
            "Change the image URL if you want to set it directly.",
            handleOnImageUrlChange,
            props.currentImgSrc,
            "Enter direct image URL"
          )}
        </div>

        <Modal
          visible={previewModalVisible}
          onCancel={handlePreviewCancel}
          width={900}
          footer={null}
        >
          <div style={{ padding: "30px 0 10px 0", textAlign: "center" }}>
            <div className="checkered-bg" style={{ padding: "20px 10px" }}>
              <img
                src={formatImageUrl(props.currentImgSrc)}
                style={{ maxWidth: "100%", maxHeight: "100%" }}
                alt={props.currentImgAlt}
              />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.user,
  plans: state.plans,
  sharedAccesses: state.sharedAccesses,
});

export default connect(mapStateToProps, { getCurrentStoreData })(ImagePicker);
