import React, { Component } from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import _ from "lodash";
import "./Other03.scss";

interface Props {
  componentItem: any;
  checkIsEmptyContent: any;
  saveContentInStore: any;
  connectDefaultFormIntegration: any;
  isDarkBg: boolean;
}

class Other03 extends Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: true,
      cacheBustString: Math.floor(Math.random() * 1000000)
    };
  }
  generator = new generateContentDataObject();

  componentWillMount(): void {
    const { isDefaultEmpty } = this.props.componentItem;
    if (this.props.checkIsEmptyContent()) {
      this.props.saveContentInStore(
        this.generator.setUpRapidForms({
          isDefaultEmpty,
        })
      );
    }
  }

  componentDidMount(): void {
    let script = document.querySelector(
      `script[src="https://app.rapidforms.co/embed/index.js"]`
    ) as HTMLScriptElement;
    if (script) return;
    script = document.createElement("script");
    script.src = "https://app.rapidforms.co/embed/index.js";
    document.body.append(script);
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<any>,
    snapshot?: any
  ): void {
    const prevSrc = _.get(
      prevProps.componentItem,
      "componentData.rapidForms.content.src",
      ""
    );
    const currentSrc = _.get(
      this.props.componentItem,
      "componentData.rapidForms.content.src",
      ""
    );
    if (prevSrc !== currentSrc) {
      this.setState({ isLoading: true });
    }
  }

  render() {
    const { id } = this.props.componentItem;
    const widgetSrc = _.get(
      this.props.componentItem,
      "componentData.rapidForms.content.src",
      ""
    );
    const isDemo = _.get(
      this.props.componentItem,
      "componentData.rapidForms.content.isDemo",
      false
    );

    return (
      <section>
        <div className="other-03">
          <div className="other-03__widget-container">
            {this.state.isLoading && <div className="other-03__spinner"></div>}
            <iframe
              onLoad={() => {
                if (!window.iFrameResize) return;
                window.iFrameResize({ log: false }, `#rapidforms-iframe-${id}`);
                this.setState({ isLoading: false });
              }}
              id={`rapidforms-iframe-${id}`}
              src={`${widgetSrc}?type=platform${isDemo ? "&isDemo=true" : ""}&cache=${this.state.cacheBustString}`}
              width="100%"
              height="0px"
              frameBorder="0"
              marginHeight={0}
              marginWidth={0}
              // @ts-ignore
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </section>
    );
  }
}

export default Other03;
