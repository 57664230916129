import React, {Component} from "react";
import {Button, Input} from "antd";
import {api} from "../../data/urls";
import {getCurrentWebsiteSubdomain} from "../../helpers/getCurrentWebsiteSubdomain";
import axios from "axios";
import returnAuthHeaderForAJAX from "../../helpers/auth/returnAuthHeaderForAJAX";
import {openNotification} from "../../helpers/openNotification";
import fireAnalyticsEvent from "../../helpers/editor/fireAnalyticsEvent";
import {getErrorValue} from "../../helpers/editor/getErrorValue";
import {getErrorKey} from "../../helpers/editor/getErrorKey";
import {compareEditedValueWithCurrent} from "../../helpers/editor/compareEditedValueWithCurrent";
import { handleBadWebsiteError } from "../../helpers/handleBadWebsiteError";
import { CrispEvents } from "../../enums/AnalyticsEventsEnums";
import trimStringTo from "../../helpers/strings/trimStringTo";
import _ from "lodash";
const { TextArea } = Input;

interface Props {
    websites: any,
    history: any
    user: any,
    auth: any,
    waitingForServer: any,
    toggleInvalidAttempt: any,
    errorMessage: any,
    saveWebsiteDataInStore: any,
    currentWebsite: any,
    clearInput: any,
    html_code_field: string,
    isWaitingForServer: boolean,
    saveWebsiteBackup: any;
    saveToLocalBackup: any;
}
type MyState = { current_custom_html_code: string };
class AddWebsiteCustomCodeForm extends Component<Props, MyState>{
    componentWillMount(): void {
        // We want to set up an initial value of a website custom_html_code.
        this.setState({current_custom_html_code: this.props.currentWebsite[this.props.html_code_field]});
    }

    componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any): void {
        // We set the new state only when we change a settings active website.
        // We want to set up an initial value of a new picked website.
        if(this.props.currentWebsite.subdomain !== nextProps.currentWebsite.subdomain){
            this.setState({current_custom_html_code: nextProps.currentWebsite[this.props.html_code_field]});
            // We also clear errors when switch a website.
            this.props.clearInput();
        }
    }

    handleUpgradeToUnlock = (e:any):void => {
        this.props.history.push('/plan');
    };

    handleCustomDomainUpdate = async (e:any) => {
        e.preventDefault();

        this.props.waitingForServer(true);

        try {
          this.props.saveWebsiteBackup("website");
        } catch (e) {
          console.error(_.get(e, "response.data"));
        }

        //We want to remove all errors from inputs on a new request.
        this.props.clearInput();

        let accessToken = this.props.auth.accessToken;
        let apiUrl = api.websites.updateWebsite.prefix + getCurrentWebsiteSubdomain(this.props.websites.items) + api.websites.updateWebsite.postfix;
        const code = this.state.current_custom_html_code;
        axios.patch(apiUrl, {[this.props.html_code_field]: code}, {...returnAuthHeaderForAJAX(accessToken)})
            .then(() => {
                const dataToStore = {
                  data: {
                    ...this.props.currentWebsite,
                    [this.props.html_code_field]: code
                  }
                }
                this.props.saveWebsiteDataInStore(dataToStore, this.props.currentWebsite.subdomain);
                this.props.saveToLocalBackup("website");
                fireAnalyticsEvent.fireCrisp(
                  this.props.html_code_field === 'custom_html_code' ? 
                  CrispEvents.changeWebsiteHeadCustomCode : 
                  CrispEvents.changeWebsiteBodyCustomCode, {
                    subdomain: this.props.currentWebsite.subdomain,
                    code: trimStringTo(this.state.current_custom_html_code, 100),
                  });
            })
            .catch(error => {
                // handle error
                if(error.response){
                    let errorData = error.response.data;

                    let errorObjectKey = getErrorKey(errorData);
                    let errorObjectValue = getErrorValue(errorData, errorObjectKey);

                    fireAnalyticsEvent.fireCrisp(
                      this.props.html_code_field === 'custom_html_code' ? 
                      CrispEvents.changeWebsiteHeadCustomCodeError : 
                      CrispEvents.changeWebsiteBodyCustomCodeError, {
                        error_type: errorObjectKey,
                        error_message: errorObjectValue,
                        subdomain: this.props.currentWebsite.subdomain,
                        code: trimStringTo(this.state.current_custom_html_code, 100),
                      }, true);

                    handleBadWebsiteError(errorData);
                    if (errorData.not_pro !== undefined){
                        let message = errorData.not_pro;
                        openNotification('Denied', message, 'OK', 'warn');
                    }

                    if (errorData.locked !== undefined){
                        let message = errorData.locked;
                        openNotification('Denied', message, 'OK', 'warn');
                    }

                    if (errorData.detail !== undefined){
                        let message = errorData.detail;

                        // This happens when user tries to change a website which doesn't exist or doesn't belong to the user.
                        openNotification('Server error', 'Error message: "' + message + '". This should not have happened. Please contact us.', 'OK', 'error');
                    }

                    if (errorData.custom_html_code !== undefined){
                        let message = errorData.custom_html_code.join(' ');
                        this.props.errorMessage(message);
                        this.props.toggleInvalidAttempt(true);
                    }
                }
            })
            .then(response => {
                // always executed
                this.props.waitingForServer(false);
            });
    };

    handleInputChange = (e:any) => {
        this.setState({current_custom_html_code: e.target.value});
    };

    render(){
        let returnCustomCodeField = () => {
            // Only pro users can upgrade to custom domains
            // if(this.props.user.isPro){
            return (
                <div className="settings-box__input_box settings-box__input_box--large">
                        <TextArea
                            name={this.props.html_code_field}
                            id={this.props.html_code_field}
                            required={false}
                            value={this.state.current_custom_html_code}
                            placeholder='<script src="https://www.googletagmanager.com/gtag/js?id=UA-XXX-X"></script>'
                            onChange={this.handleInputChange}
                            autosize={{ minRows: 4, maxRows: 30 }}
                            style={{fontFamily: 'monospace'}}
                        />
                    <div className="settings-box__error_message" style={{opacity: this.props.websites.errorMessage === undefined ? 0 : 1}}>
                        {this.props.websites.errorMessage}
                    </div>
                </div>
            );
            // }
            // else{
            //     return (
            {/*<Button onClick={this.handleUpgradeToUnlock} icon='star' type="primary" size="large" htmlType="button">Upgrade to unlock</Button>*/}
            // )
            // }
        };

        // We hide the save button in 2 cases:
        // 1) when the form has not yet received its initial value (and the initial value === undefined)
        // 2) when the initial value is the same as the current value (e.g. nothing to save)
        let isSaveButtonVisible = this.state.current_custom_html_code !== undefined && (compareEditedValueWithCurrent(this.props.currentWebsite[this.props.html_code_field], this.state.current_custom_html_code) === false);

        return (
            <form onSubmit={this.handleCustomDomainUpdate} className="settings-box__form">
                {returnCustomCodeField()}
                <Button
                    className="settings-box__form_save"
                    icon='save'
                    type="primary"
                    loading={this.props.isWaitingForServer}
                    htmlType="submit"
                    hidden={!isSaveButtonVisible}
                />
            </form>
        )
    }
}

export default AddWebsiteCustomCodeForm;
