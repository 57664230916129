import React, { Component } from "react";
import { Button, Icon, Input, Menu, Select, Tag, Tooltip } from "antd";
import "./WebsiteLanguageSettings.css";
import { api, urls } from "../../data/urls";
import axios from "axios";
import returnAuthHeaderForAJAX from "../../helpers/auth/returnAuthHeaderForAJAX";
import { openNotification } from "../../helpers/openNotification";
import { getErrorKey } from "../../helpers/editor/getErrorKey";
import { checkIfThisWebsiteLanguageOptionOfAnotherWebsite } from "../../helpers/editor/checkIfThisWebsiteLanguageOptionOfAnotherWebsite";
import { getErrorValue } from "../../helpers/editor/getErrorValue";
import generateRandomNumerousId from "../../helpers/generateRandomNumerousId";
import { handleBadWebsiteError } from "../../helpers/handleBadWebsiteError";
import { popularLanguagesWithEmoji } from "../../data/popularLanguagesWithEmoji";
import { distanceInWords } from "date-fns";
import { checkIfObjectEmpty } from "../../helpers/checkIfObjectEmpty";
import capitalizeString from "../../helpers/strings/capitalizeString";
import { getCurrentWebsiteLiveUrl } from "../../helpers/getCurrentWebsiteLiveUrl";
import { getWebsiteObjectFromID } from "../../helpers/getWebsiteObjectFromID";
import _ from "lodash";
import { projectUrl } from "../../data/constants";

interface Props {
  auth: any;
  user: any;
  websites: any;
  currentWebsite: any;
  saveWebsiteDataInStore: any;
  toggleWaitingForWebsiteLanguagesUpdate: any;
}

class WebsiteLanguageSettings extends Component<Props> {
  componentDidMount(): void {}

  generateThisWebsiteDefaultObject = () => {
    // When a user sets a language for a website, we generate and store this kind of object:
    /*
        {
            "items": [ // this website language options. default = []
                {
                    "id": "lang-38346",
                    "connectedOn": 1640358859378,
                    "code": "de",
                    "english": "German",
                    "flag": "🇩🇪",
                    "native": "Deutsch",
                    "websiteId": 1242, // the id of the website which is a language version
                    "websiteHomePageURL": "https://de.unicornplatform.com/"
                }
            ],
            "settings": {
                "code": "en", // 639-1 code of a language of this website (https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes)
                "english": "English", // english title of the language
                "flag": "🇺🇸🇬🇧", // flag of this language (used on the languages selector widget)
                "native": "English", // the title of the language on the native language
            }
        }
        * */
    return {
      items: [],
      settings: {
        code: "none", // should be always string value because Option (ant.d component) accepts only strings and numbers
        english: undefined,
        flag: undefined,
        native: undefined,
      },
    };
  };
  getLanguageDataFromCode = (languageCode: string) => {
    // get languageCode, returns object like this:
    /*
     * {"english": "ukrainian", "native": "Українська", "flag": "🇺🇦"},
     *
     * */

    let english = undefined;
    let native = undefined;
    let flag = undefined;

    let arrayOfAllLanguages = popularLanguagesWithEmoji;
    for (let i = 0; i < arrayOfAllLanguages.length; i++) {
      if (arrayOfAllLanguages[i]["code"] === languageCode) {
        english = arrayOfAllLanguages[i]["english"];
        native = arrayOfAllLanguages[i]["native"];
        flag = arrayOfAllLanguages[i]["flag"];
        break;
      }
    }

    return {
      english: english,
      native: native,
      flag: flag,
    };
  };
  generateThisWebsiteNewLanguageObject = (languageCode: string) => {
    let oldLanguagesObject = this.props.currentWebsite.languages;
    // if only the language of this website should be changed.
    if (checkIfObjectEmpty(oldLanguagesObject)) {
      // if the languages object is {}, create a default one.
      oldLanguagesObject = this.generateThisWebsiteDefaultObject();
    }
    let languageObject = this.getLanguageDataFromCode(languageCode);
    return {
      items: [...oldLanguagesObject.items],
      settings: {
        code: languageCode, // always string. see about why (here: generateThisWebsiteDefaultObject)
        english: languageObject["english"],
        flag: languageObject["flag"],
        native: languageObject["native"],
      },
    };
  };
  saveLanguagesObjectOnServer = (newLanguagesObject: any) => {
    this.props.toggleWaitingForWebsiteLanguagesUpdate(true);

    let accessToken = this.props.auth.accessToken;

    let apiUrl =
      api.websites.updateWebsite.prefix +
      this.props.currentWebsite.subdomain +
      api.websites.updateWebsite.postfix;
    axios
      .patch(
        apiUrl,
        { languages: newLanguagesObject },
        { ...returnAuthHeaderForAJAX(accessToken) }
      )
      .then(() => {
        const dataToStore = {
          data: {
            ...this.props.currentWebsite,
            languages: newLanguagesObject
          }
        }
        this.props.saveWebsiteDataInStore(
          dataToStore,
          this.props.currentWebsite.subdomain
        );
        // Tell a user that the operation is successful.
      })
      .catch((error) => {
        // handle error
        if (error.response) {
          let errorData = error.response.data;

          let errorObjectKey = getErrorKey(errorData);
          let errorObjectValue = getErrorValue(errorData, errorObjectKey);
          handleBadWebsiteError(errorData);
          if (errorData.not_pro !== undefined) {
            let message = errorData.not_pro;
            openNotification("Denied", message, "OK", "warn");
          }

          if (errorData.locked !== undefined) {
            let message = errorData.locked;
            openNotification("Denied", message, "OK", "warn");
          }

          if (errorData.detail !== undefined) {
            let message = errorData.detail;

            // This happens when user tries to change a website which doesn't exist or doesn't belong to the user.
            openNotification(
              "Server error",
              'Error message: "' +
                message +
                '". This should not have happened. Please contact us.',
              "OK",
              "error"
            );
          }
        }
      })
      .then((response) => {
        // always executed
        this.props.toggleWaitingForWebsiteLanguagesUpdate(false);
      });
  };
  generateNewLanguageOptionObject = (
    code: string,
    english: string | null,
    flag: string | null,
    native: string | null,
    websiteId: number | "none",
    websiteHomePageURL: string | null
  ) => {
    return {
      id: "lang-" + generateRandomNumerousId().toString(),
      connectedOn: Date.now(),
      code: code, // always string. see about why (here: generateThisWebsiteDefaultObject)
      english: english,
      flag: flag,
      native: native,
      websiteId: websiteId, // always string too. Same reason: because the Option only accepts string and number
      websiteHomePageURL: websiteHomePageURL,
    };
  };
  addNewEmptyLanguageOptionItem = () => {
    let newLanguagesObject = { ...this.props.currentWebsite.languages };
    if (newLanguagesObject.items) {
      let newLanguageOptionItemObject = this.generateNewLanguageOptionObject(
        "none",
        null,
        null,
        null,
        "none",
        null
      );
      newLanguagesObject.items.push(newLanguageOptionItemObject); //when a new item is added, the item has no data set yet and all the arguments are null
      this.saveLanguagesObjectOnServer(newLanguagesObject);
    }
  };
  deleteLanguageOptionItem = (itemToDeleteId: number) => {
    let newLanguagesObject = this.props.currentWebsite.languages;
    let newLanguagesList = newLanguagesObject.items;
    if (newLanguagesList) {
      //delete from the array
      for (let i = 0; i < newLanguagesList.length; i++) {
        if (newLanguagesList[i].id === itemToDeleteId) {
          newLanguagesList.splice(i, 1);
          break;
        }
      }

      this.saveLanguagesObjectOnServer(newLanguagesObject);
    } else {
      openNotification(
        "Bang!",
        "You tried to delete a language option which does not exist yet. This should not have happened. Please contact us.",
        "Okay",
        "error"
      );
    }
  };
  editLanguagesOptionItemLanguageSettings = (
    itemToEditId: number,
    code: string,
    english: string | null,
    flag: string | null,
    native: string | null
  ) => {
    // change the language settings (code, flag, title etc) of a language option
    let newLanguagesObject = { ...this.props.currentWebsite.languages };
    let newLanguagesList = newLanguagesObject.items;
    if (newLanguagesList) {
      //find in the array and edit
      for (let i = 0; i < newLanguagesList.length; i++) {
        if (newLanguagesList[i].id === itemToEditId) {
          newLanguagesList[i]["code"] = code; // always string. see about why (here: generateThisWebsiteDefaultObject)
          newLanguagesList[i]["english"] = english;
          newLanguagesList[i]["flag"] = flag;
          newLanguagesList[i]["native"] = native;
          break;
        }
      }
      newLanguagesObject = { ...newLanguagesObject, items: newLanguagesList };
      this.saveLanguagesObjectOnServer(newLanguagesObject);
    } else {
      openNotification(
        "Whoa!",
        "You tried to edit a language option which does not exist yet. This should not have happened. Please contact us.",
        "Okay",
        "error"
      );
    }
  };
  editLanguagesOptionItemWebsiteSettings = (
    itemToEdit: number,
    websiteId: number | "none"
  ) => {
    // change the website settings (website id, home page) of a language option
    let newLanguagesObject = { ...this.props.currentWebsite.languages };
    let newLanguagesList = newLanguagesObject.items;
    if (newLanguagesList) {
      //find in the array and edit
      let websiteURL = undefined;
      let websiteObject;
      if (websiteId !== "none") {
        // we had to use 'none' because the ant.d Option accepts only numbers and strings
        websiteObject = getWebsiteObjectFromID(
          websiteId,
          this.props.websites.items
        );
        websiteURL = getCurrentWebsiteLiveUrl(websiteObject);
      }
      for (let i = 0; i < newLanguagesList.length; i++) {
        if (newLanguagesList[i].id === itemToEdit["id"]) {
          newLanguagesList[i]["websiteId"] = websiteId;
          newLanguagesList[i]["websiteHomePageURL"] = websiteURL;
          break;
        }
      }
      // save the new lang option of this website on the server
      this.saveLanguagesObjectOnServer(newLanguagesObject);

      // set the language as this website language for the website with ID=websiteId
      // we need this to be able to set the <html lang=""> attribute to the option website in the template
      // otherwise we would need to make extra DB requests to find our the option website lang code.
      // currently: not done, ask users to set the website language in the settings
      // let newLanguagesObjectOfTheLanguageOptionWebsite = {...websiteObject.languages};
      // this.saveLanguagesObjectOnServer(newLanguagesObject);
    } else {
      openNotification(
        "Whoa!",
        "You tried to edit a language option which does not exist yet. This should not have happened. Please contact us.",
        "Okay",
        "error"
      );
    }
  };

  displayMainLanguageSetting = () => {
    // change language of this website
    let changeLanguageOfThisWebsite = (languageCode: string) => {
      let newLanguagesObject = this.generateThisWebsiteNewLanguageObject(
        languageCode
      );
      this.saveLanguagesObjectOnServer(newLanguagesObject);
    };
    let currentCodeValue = "none"; // always string. see about why (here: generateThisWebsiteDefaultObject)
    if (checkIfObjectEmpty(this.props.currentWebsite.languages) === false) {
      currentCodeValue = this.props.currentWebsite.languages.settings.code;
    }
    return this.displayLanguagesDropdown(currentCodeValue, (languageCode) =>
      changeLanguageOfThisWebsite(languageCode)
    );
  };
  displayLanguagesOptionsSetting = (languageOptionsItem: any) => {
    // change a language option
    let changeLanguageOption = (languageCode) => {
      let languageObject = this.getLanguageDataFromCode(languageCode);
      this.editLanguagesOptionItemLanguageSettings(
        languageOptionsItem.id,
        languageCode,
        languageObject["english"],
        languageObject["flag"],
        languageObject["native"]
      );
    };
    return this.displayLanguagesDropdown(
      languageOptionsItem.code,
      (languageCode) => changeLanguageOption(languageCode)
    );
  };
  displayLanguageItems = () => {
    const { Option } = Select;
    let websitesList = this.props.websites.items.filter((item: any) => {
      //all websites excluding the website which settings are being edited
      return item.id !== this.props.currentWebsite.id;
    });

    let returnWebsitesOptionsJSX = () => {
      let websitesJSX = [
        <Option value={"none"} key={"random-key-456"}>
          Not selected
        </Option>,
      ];
      for (let i = 0; i < websitesList.length; i++) {
        let websiteItem = websitesList[i];
        let websiteDomainJSX = null;
        if (
          websiteItem["is_custom_domain_active"] &&
          websiteItem["custom_domain"] &&
          websiteItem["custom_domain"] !== ""
        ) {
          websiteDomainJSX = <span>{websiteItem["custom_domain"]}</span>;
        } else {
          websiteDomainJSX = (
            <span>
              {websiteItem["subdomain"]}
              {<span style={{ opacity: 0.5 }}>.{(websiteItem.free_root_domain || projectUrl)}</span>}
            </span>
          );
        }
        websitesJSX.push(
          <Option value={websiteItem["id"]} key={i}>
            {websiteDomainJSX}
          </Option>
        );
      }
      return websitesJSX;
    };
    let clientCurrentTime = new Date();

    let languageVersionsJSX = [];
    let languageOptionsItemsArray = this.props.currentWebsite.languages.items; // this.props.currentWebsite.languages is valid because we check it - shouldDisplayLanguagesOptions
    for (let i = 0; i < languageOptionsItemsArray.length; i++) {
      let difference =
        "Added " +
        distanceInWords(
          clientCurrentTime.getTime(),
          new Date(languageOptionsItemsArray[i].connectedOn).getTime()
        ) +
        " ago.";
      let versionJSX = (
        <div
          className="website-languages__version"
          key={languageOptionsItemsArray[i]["id"]}
        >
          <div className="website-languages__version_form">
            {this.displayLanguagesOptionsSetting(languageOptionsItemsArray[i])}
            <Select
              onChange={(websiteId: number | "none") =>
                this.editLanguagesOptionItemWebsiteSettings(
                  languageOptionsItemsArray[i],
                  websiteId
                )
              }
              style={{ width: 220 }}
              defaultValue={languageOptionsItemsArray[i]["websiteId"]}
              className={"website-languages__select_website"}
            >
              {returnWebsitesOptionsJSX()}
            </Select>
          </div>
          <Button
            onClick={(event: any) => {
              this.deleteLanguageOptionItem(languageOptionsItemsArray[i]["id"]);
            }}
            htmlType="button"
            // loading={isWaitingForServerResponse}
            className="website-languages__delete"
            shape="circle"
            title="Delete this language option"
            icon="cross"
            loading={this.props.websites.isWaitingForLanguagesUpdateResponse}
            size="small"
          />
        </div>
      );
      languageVersionsJSX.push(versionJSX);
    }
    return (
      <div>
        {languageVersionsJSX}
        <Button
          className="website-languages__add_new_version_button"
          onClick={() => {
            this.addNewEmptyLanguageOptionItem();
          }}
          loading={this.props.websites.isWaitingForLanguagesUpdateResponse}
          size={"small"}
          icon={"plus"}
        >
          Add a language version
        </Button>
      </div>
    );
  };
  displayLanguagesDropdown = (currentCodeValue: string, callback: any) => {
    const { Option } = Select;

    //popularLanguagesWithEmoji - some popular languages we picked.
    //check out also languagesIso6391 - it contains all languages.
    let languagesArray = popularLanguagesWithEmoji;
    let dropdownItemsJSX = [
      <Option value={"none"} key={"random-key-123"}>
        <span>Undefined 🏳</span>
      </Option>,
    ];

    for (let i = 0; i < languagesArray.length; i++) {
      let languageTitle =
        capitalizeString(languagesArray[i]["english"]) +
        " " +
        languagesArray[i]["flag"];
      let languageCode = languagesArray[i]["code"];
      let menuItemJSX = (
        <Option
          title={languagesArray[i]["native"]}
          value={languageCode}
          key={languageCode}
        >
          <span>{languageTitle}</span>
        </Option>
      );
      dropdownItemsJSX.push(menuItemJSX);
    }

    return (
      <Select
        disabled={this.props.websites.isWaitingForLanguagesUpdateResponse}
        loading={this.props.websites.isWaitingForLanguagesUpdateResponse}
        className={"website-languages__language_dropdown"}
        style={{ maxWidth: 205, minWidth: 150 }}
        value={currentCodeValue}
        onSelect={(languageCode) => callback(languageCode)}
      >
        {dropdownItemsJSX}
      </Select>
    );
  };

  render() {
    let currentWebsiteDomainJSX;
    if (
      this.props.currentWebsite["is_custom_domain_active"] &&
      this.props.currentWebsite["custom_domain"] &&
      this.props.currentWebsite["custom_domain"] !== ""
    ) {
      currentWebsiteDomainJSX = this.props.currentWebsite["custom_domain"];
    } else {
      currentWebsiteDomainJSX =
        this.props.currentWebsite["subdomain"] + "." + (this.props.currentWebsite.free_root_domain || projectUrl);
    }

    let shouldDisplayLanguagesOptions = false;

    // if the website is a language option of another website, it can not have languages options
    let isThisWebsiteLanguageOptionOfAnotherWebsite = checkIfThisWebsiteLanguageOptionOfAnotherWebsite(
      this.props.websites,
      this.props.currentWebsite
    )["isTrue"];

    if (
      checkIfObjectEmpty(this.props.currentWebsite.languages) === false &&
      isThisWebsiteLanguageOptionOfAnotherWebsite === false
    ) {
      // after initiating the website default languages object, display the languages options
      shouldDisplayLanguagesOptions = true;
    }

    return (
      <div className="settings-box__input website-languages">
        <div className="settings-box__input_label">
          <div>
            <Tooltip
              title={
                <div>
                  Connect multiple language version into one website.
                  <br />
                  <br />
                  How to add a new language. A quick guide:
                  <ol>
                    <li>
                      1. Create a new website in addition to this website.
                    </li>
                    <li>
                      2. Fill in the new one with the content on another
                      language.
                    </li>
                    <li>
                      3. Add this new website as another language version here.
                    </li>
                  </ol>
                  Read best practices and hints in{" "}
                  <a
                    target="_blank"
                    style={{
                      color: "white",
                      textDecoration: "underline",
                    }}
                    href={urls.guides.multilanguage}
                  >
                    our guide <Icon type={"link"} />
                  </a>
                  .
                </div>
              }
            >
              <Icon type="info-circle" />
            </Tooltip>
          </div>
          <div className="settings-box__input_info_text">
            Languages:
            <span
              style={{ paddingRight: 3 }}
              className="settings-box__input_info_subtext"
            >
              Set a default language or add more versions
            </span>
          </div>
        </div>

        <div className="website-languages__items_box">
          <div className="website-languages__box">
            <span className="website-languages__title">
              <Icon type="global" /> Set this website language:
            </span>
            <div
              className={"website-languages__version"}
              style={{ marginBottom: 0 }}
            >
              {this.displayMainLanguageSetting()}
              <Input
                className={"website-languages__current_website_input"}
                disabled={true}
                value={currentWebsiteDomainJSX}
                style={{ width: 220 }}
              ></Input>
            </div>
          </div>
          {shouldDisplayLanguagesOptions && (
            <div className="website-languages__box">
              <span className="website-languages__title">
                <Icon type="global" /> Add other language version of this
                website:
              </span>
              <div>{this.displayLanguageItems()}</div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default WebsiteLanguageSettings;
