import React, {Component} from "react";
import {BrowserRouter as Router, Route, Link, Switch, Redirect} from "react-router-dom";
import {Button, Form, Input, Tooltip, message} from "antd";
import setInputStatus from "../../helpers/setInputStatus";
import {api} from "../../data/urls";
import {getCurrentWebsiteSubdomain} from "../../helpers/getCurrentWebsiteSubdomain";
import axios from "axios";
import returnAuthHeaderForAJAX from "../../helpers/auth/returnAuthHeaderForAJAX";
import {indexWebsitePageDashboardUrl, projectUrl} from "../../data/constants";
import {openNotification} from "../../helpers/openNotification";
import {messages} from "../../data/messages";
import fireAnalyticsEvent from "../../helpers/editor/fireAnalyticsEvent";
import {getErrorValue} from "../../helpers/editor/getErrorValue";
import {getErrorKey} from "../../helpers/editor/getErrorKey";
import { handleBadWebsiteError } from "../../helpers/handleBadWebsiteError";
import { CrispEvents, PosthogEvents } from "../../enums/AnalyticsEventsEnums";
import { getActiveDomain } from "../../helpers/websites/getActiveDomain";
import _ from "lodash";
import { checkIfGoogleSheetUrl } from "../../helpers/checkIfGoogleSheetUrl";
import { formatToURLPath } from "../../helpers/strings/formatToURLPath";
import { checkIfPageIsDynamic } from "../../helpers/checkIfPageIsDynamic";

interface Props {
    websites: any,
    websitesPages: any,
    clearUpdatePageUrl: any,
    auth: any,
    history: any,

    currentWebsite: any,
    currentWebsitePage: any,

    saveWebsitePageDataInStore: any,

    pageUrlUpdateWaitingForServer: any,
    pageUrlUpdateErrorMessage: any,
    pageUrlUpdateToggleInvalidAttempt: any,
    url: string,
    setUrl: (url: string) => void,
}
type MyState = {};
class UpdateUrlForm extends Component<Props, MyState>{
    componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any): void {
        // We set the new state only when we change a settings active website or page.
        // We want to set up an initial value of a new picked website or page.
        if((this.props.currentWebsite.subdomain !== nextProps.currentWebsite.subdomain) || (this.props.currentWebsitePage.url !== nextProps.currentWebsitePage.url)){
            this.props.setUrl(nextProps.currentWebsitePage.url);
            // We also clear errors when switch a website or a page.
            this.props.clearUpdatePageUrl();
        }
    }

    handleUrlUpdate = (e:any):void => {
        e.preventDefault();

        this.props.pageUrlUpdateWaitingForServer(true);

        //We want to remove all errors from inputs on a new request.
        this.props.clearUpdatePageUrl();


        // Our server API expects the 'indexWebsitePageDashboardUrl' as an URL kwarg because a URL of a home page ('') is in appropriate for API and the dashboard.
        let newUrl = formatToURLPath(this.props.url);
        this.props.setUrl(newUrl);

        let websitePageUrlToServer = this.props.currentWebsitePage.url;
        if(websitePageUrlToServer === ''){
            websitePageUrlToServer = indexWebsitePageDashboardUrl;
        }

        let accessToken = this.props.auth.accessToken;
        let apiUrl = api.websitePages.updateWebsitePage.prefix + this.props.currentWebsite.subdomain + '/' + websitePageUrlToServer + api.websitePages.updateWebsitePage.postfix;
        axios.patch(apiUrl, {url: newUrl, subdomain: this.props.currentWebsite.subdomain}, {...returnAuthHeaderForAJAX(accessToken)})
            .then(() => {
                const dataToStore = {
                  data: {
                    ...this.props.currentWebsitePage,
                    url: newUrl
                  }
                }

                window.posthog.capture(PosthogEvents.SET_PAGE_URL, {
                  page_url: newUrl,
                });

                this.props.saveWebsitePageDataInStore(dataToStore, this.props.currentWebsite, this.props.websitesPages.items, this.props.currentWebsitePage.url);

                fireAnalyticsEvent.fireCrisp(CrispEvents.changePageUrl, {
                  subdomain: this.props.currentWebsite.subdomain,
                  page_url: this.props.currentWebsitePage.url,
                });

                // Server gives the object of the saved website page.
                let newUrlFromServer = this.props.currentWebsitePage.url;
                if(newUrlFromServer === ''){
                    newUrlFromServer = indexWebsitePageDashboardUrl;
                }

                // Tell a user that the operation is successful.
                let currentWebsiteUrl = 'https://';
                if(this.props.currentWebsite.is_custom_domain_active){
                    currentWebsiteUrl = currentWebsiteUrl + this.props.currentWebsite.custom_domain;
                }else{
                    currentWebsiteUrl = currentWebsiteUrl + this.props.currentWebsite.subdomain + '.' + (this.props.currentWebsite.free_root_domain || projectUrl);
                }

                let newPageUrl = currentWebsiteUrl + '/' + newUrl;
                let newUrlMessage = (<span>Successfully changed the page url. The page can be accessed here:&nbsp;<a target="_blank" href={newPageUrl}>{newPageUrl}</a></span>);

                if(newUrl === '' || newUrl === ' '){
                    newUrlMessage = (<span>Successfully changed the page url. This page is now a home page and it can be accessed here:&nbsp;<a target="_blank" href={newPageUrl}>{newPageUrl}</a></span>);
                }
            })
            .catch(error => {
                if(error.response){
                    let errorData = error.response.data;
                    handleBadWebsiteError(errorData);
                    
                    if (errorData.detail !== undefined){
                        let message = errorData.detail;

                        let errorObjectKey = getErrorKey(errorData);
                        let errorObjectValue = getErrorValue(errorData, errorObjectKey);

                        fireAnalyticsEvent.fireCrisp(CrispEvents.changePageUrlError, {
                          error_type: errorObjectKey,
                          error_message: errorObjectValue,
                          subdomain: this.props.currentWebsite.subdomain,
                          page_url: newUrl,
                        }, true);

                        // This happens when user tries to change a website which doesn't exist or doesn't belog to the user.
                        openNotification('Server error', 'Error message: "' + message + '". This should not have happened. Please contact us.', 'OK', 'error');
                    }

                    if (errorData.locked !== undefined){
                        let message = errorData.locked;
                        openNotification('Denied', message, 'OK', 'warn');
                    }

                    if (errorData.url !== undefined){
                        let message = errorData.url.join(' ');
                        this.props.pageUrlUpdateErrorMessage(message);
                        this.props.pageUrlUpdateToggleInvalidAttempt(true);
                    }
                }
            })
            .then(response => {
                // always executed
                this.props.pageUrlUpdateWaitingForServer(false);
            });
    };

    handleInputChange = (e:any) => {
        this.props.setUrl(e.target.value);
    };

    render(){
        let currentWebsiteUrl = 'https://';
        if(this.props.currentWebsite.is_custom_domain_active){
            currentWebsiteUrl = currentWebsiteUrl + this.props.currentWebsite.custom_domain;
        }else{
            currentWebsiteUrl = currentWebsiteUrl + this.props.currentWebsite.subdomain + '.' + (this.props.currentWebsite.free_root_domain || projectUrl);
        }
        const displayedDomain = getActiveDomain(this.props.currentWebsite);
        const isPageDynamic = checkIfPageIsDynamic(this.props.currentWebsitePage)

        return (
            <form onSubmit={this.handleUrlUpdate} className="settings-box__form">
                <div className="settings-box__input_box full-width">
                    <Input name="url"
                           id="url"
                           required={false}
                           placeholder="about-us"
                           type="text"
                           value={this.props.url}
                           onChange={this.handleInputChange}
                           addonBefore={<div style={{
                            maxWidth: "150px",
                            direction: "rtl",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                           }}
                           >{displayedDomain}/&lrm;</div>}
                           addonAfter={isPageDynamic &&
                            <Tooltip 
                              title='This page can be accessed with a dynamic path. Available dynamic paths are generated automatically from your source Google Sheet.'
                              placement="bottomLeft"
                            >
                              <div>
                                {"/{{pageUrl}}"}
                              </div>
                            </Tooltip>
                          }
                    />
                    <div className="settings-box__error_message" style={{opacity: this.props.websitesPages.pageUrlUpdateErrorMessage === undefined ? 0 : 1}}>
                        {this.props.websitesPages.pageUrlUpdateErrorMessage}
                    </div>
                </div>
                <Button
                    className="settings-box__form_save"
                    icon='save'
                    type="primary"
                    loading={this.props.websitesPages.isWaitingForPageUrlUpdateResponse}
                    htmlType="submit"
                    hidden={(this.props.currentWebsitePage.url === this.props.url)}
                />
            </form>
        )
    }
}

export default UpdateUrlForm;
