export const getImageQuerystring = (
  width: number,
  height: number,
  density: number = 1,
  imageUrl: string = "",
) => {
  if ((imageUrl || "").toString().includes("?")) {
    return `&width=${Math.ceil(width * density)}&height=${Math.ceil(
      height * density
    )}`;
  }
  return `?width=${Math.ceil(width * density)}&height=${Math.ceil(
    height * density
  )}`;
};
