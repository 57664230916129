import axios, { AxiosResponse } from "axios";
import { api } from "../../data/urls";
import returnAuthHeaderForAJAX from "../auth/returnAuthHeaderForAJAX";
import { fetchConnectedIntegrationsAsync } from "./fetchConnectedIntegrationsAsync";

export const renameIntegrationAsync = (
  accessToken: string,
  integrationId: string,
  newName: string,
  currentWebsite: any
): Promise<AxiosResponse<any>> => {
  return new Promise((resolve, reject) => {
    fetchConnectedIntegrationsAsync(accessToken, currentWebsite.id)
      .then((response) => {
        const apiUrl =
          api.websites.updateWebsite.prefix +
          currentWebsite.subdomain +
          api.websites.updateWebsite.postfix;
        const integrationsToServer = {
          ...response,
          connected: [...response.connected].map((integration) => {
            if (integration.id === integrationId) {
              return {
                ...integration,
                name: newName
              };
            }
            return integration;
          }),
        };
        axios
          .patch(
            apiUrl,
            {
              connected_form_integrations: integrationsToServer,
            },
            { ...returnAuthHeaderForAJAX(accessToken) }
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });
};
