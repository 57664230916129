import React from "react";
import { Button, Icon, Popconfirm, Tooltip } from "antd";
import { getCurrentPlan } from "../../../../../helpers/plans/getCurrentPlan";
import capitalizeString from "../../../../../helpers/strings/capitalizeString";
import { connect } from "react-redux";
import { cancelPlanAsync } from "../../../../../helpers/plans/cancelPlanAsync";
import { openNotification } from "../../../../../helpers/openNotification";
import {
  fetchCurrentUserInfo,
  toggleUserPro,
  setPaymentHistoryDataStatus,
  setPaymentMethodDataStatus,
  toggleCancellingPro,
  toggleSwitchingProPlanInProgress,
  toggleUpgradingFromFreeToProInProgress,
  saveUserEventsInStore,
} from "../../../../../store/user/actions";
import fireAnalyticsEvent from "../../../../../helpers/editor/fireAnalyticsEvent";
import {
  CrispEvents,
  FacebookEvents,
  GoogleTagEvents,
  PosthogEvents,
} from "../../../../../enums/AnalyticsEventsEnums";
import { BillingDataStatus } from "../../../../../enums/BillingDataStatus";
import { getPaymentErrorMessage } from "../../../../../helpers/getPaymentErrorMessage";
import _ from "lodash";
import {
  SaveUserEventsInStore,
  SetPaymentHistoryDataStatus,
  SetPaymentMethodDataStatus,
} from "../../../../../store/user/types";
import { changeProPlanAsync } from "../../../../../helpers/plans/changeProPlanAsync";
import { showConfetti } from "../../../../../helpers/plans/showConfetti";
import { upgradeFromFreeAsync } from "../../../../../helpers/plans/upgradeFromFreeAsync";
import {
  DISCORD_COLOR_PURPLE,
  freePlanTitle,
  makerPlanTitle,
} from "../../../../../data/constants";
import { generatePriceString } from "../../../../../helpers/strings/generatePriceString";
import axios from "axios";
import { urls } from "../../../../../data/urls";
import { UserEvents } from "../../../../../enums/UserEvents";
import { setUserEventsAsync } from "../../../../../helpers/user/setUserEventsAsync";
import { sendToDiscordAsync } from "../../../../../helpers/sendToDiscordAsync";

interface Props {
  user: any;
  plans: any;
  payment: any;
  auth: any;

  is_active: boolean;
  monthly_price: number;
  activePricingPeriod: "monthly" | "yearly";
  title: string;
  isHighlighted: boolean;
  plan_id: number;
  paddle_plan_id: number;
  userHasSubscription: boolean;
  showCancellationModal: () => void;

  toggleCancellingPro: (isCancellingProInProgress: boolean) => void;
  fetchCurrentUserInfo: (successCallback?: any) => void;
  toggleUserPro: (isPro: boolean) => void;
  setPaymentHistoryDataStatus: SetPaymentHistoryDataStatus;
  setPaymentMethodDataStatus: SetPaymentMethodDataStatus;
  toggleSwitchingProPlanInProgress: (
    isSwitchingProPlanInProgress: boolean
  ) => void;
  toggleUpgradingFromFreeToProInProgress: (
    isUpgradingFromFreeToProInProgress: boolean
  ) => void;
  saveUserEventsInStore: SaveUserEventsInStore;
}

const PlanCTA = (props: Props): JSX.Element => {
  const {
    user,
    is_active,
    monthly_price,
    activePricingPeriod,
    title,
    isHighlighted,
    plans,
    payment,
    plan_id,
    auth,
    paddle_plan_id,
    userHasSubscription,
    showCancellationModal,
  } = props;

  const handleConfirmCancel = () => {
    props.toggleCancellingPro(true);

    cancelPlanAsync(auth.accessToken)
      .then((response) => {
        openNotification(
          "Success",
          "Your plan has been successfully canceled. You can upgrade to another plan or stay on the free plan.",
          "OK, thanks",
          "info",
          10
        );

        // our ajax request has changed somehow the user object, let's get the fresh one from the DB.
        props.fetchCurrentUserInfo();

        fireAnalyticsEvent.fireCrisp(CrispEvents.successfullyCancelPlan, {
          plan_title: title,
          plan_price: monthly_price,
          plan_period: activePricingPeriod,
          plan_id,
        });

        window.posthog.capture(PosthogEvents.CANCEL_PLAN, {
          plan: title,
          plan_period: activePricingPeriod,
          $set: {
            plan: freePlanTitle,
            plan_period: "monthly",
          },
        });

        props.setPaymentHistoryDataStatus(BillingDataStatus.notLoaded);
        props.setPaymentMethodDataStatus(BillingDataStatus.notLoaded);
        showCancellationModal();
      })
      .catch((error) => {
        const errorMessage = getPaymentErrorMessage(error);
        const errorStatusCode = _.get(error, "response.data.error.code");

        const message = (
          <div>
            There was an error during cancelling your plan:{" "}
            <b>{errorMessage}</b>. Sorry. Please refresh the page and retry. If
            the error is still there, contact us.
          </div>
        );
        fireAnalyticsEvent.fireCrisp(
          CrispEvents.cancelToPlanError,
          {
            plan_title: title + " " + activePricingPeriod,
            plan_price: monthly_price,
            plan_id,
            error_type: "plan_cancel",
            error_message: errorMessage,
          },
          true
        );

        openNotification("Uh oh! :(", message, "Close", "error", 20);

        console.error(
          "An error during cancelling the plan.",
          `Code: ${errorStatusCode}, Message: "${errorMessage}"`
        );
      })
      .then((response) => {
        props.toggleCancellingPro(false);
      });
  };

  const handleConfirmSwitch = () => {
    props.toggleSwitchingProPlanInProgress(true);

    changeProPlanAsync(auth.accessToken, paddle_plan_id)
      .then((response) => {
        openNotification(
          "Congratulations!",
          'You have successfully switched your plan to the "' +
            capitalizeString(title) +
            " " +
            activePricingPeriod +
            '" plan. Enjoy!',
          "Great!",
          "success",
          10,
          <Icon type="smile" style={{ color: "var(--grass)" }} />
        );

        // our ajax request has changed somehow the user object, let's get the fresh one from the DB.
        const previousPlan = getCurrentPlan(user, plans);
        props.fetchCurrentUserInfo();
        const newPrice =
          activePricingPeriod === "monthly"
            ? monthly_price
            : monthly_price * 12;
        if (previousPlan.price < newPrice) {
          window.posthog.capture(PosthogEvents.UPGRADE_PLAN, {
            previous_plan: previousPlan.title,
            previous_plan_period: previousPlan.period,
            new_plan: title,
            new_plan_period: activePricingPeriod,
          });
          try {
            sendToDiscordAsync({
              embeds: [
                {
                  title: `Upgrade subscription (+ $${newPrice - previousPlan.price})`,
                  description: `
              Plan: ${capitalizeString(previousPlan.title)} ${capitalizeString(
                    previousPlan.period
                  )} -> ${capitalizeString(title)} ${capitalizeString(
                    activePricingPeriod
                  )}
              User email: ${_.get(user, "data.email")}
              User name: ${_.get(user, "data.first_name")}
              User id: ${_.get(user, "data.id")}`,
                  color: 5763719,
                },
              ],
            });
          } catch (error) {
            console.error("Error while sending data to Discord", error);
          }
        }
        if (previousPlan.price > newPrice) {
          window.posthog.capture(PosthogEvents.DOWNGRADE_PLAN, {
            previous_plan: previousPlan.title,
            previous_plan_period: previousPlan.period,
            new_plan: title,
            new_plan_period: activePricingPeriod,
          });
          try {
            sendToDiscordAsync({
              embeds: [
                {
                  title: `Downgrade subscription (- $${previousPlan.price - newPrice})`,
                  description: `
              Plan: ${capitalizeString(previousPlan.title)} ${capitalizeString(
                    previousPlan.period
                  )} -> ${capitalizeString(title)} ${capitalizeString(
                    activePricingPeriod
                  )}
              User email: ${_.get(user, "data.email")}
              User name: ${_.get(user, "data.first_name")}
              User id: ${_.get(user, "data.id")}`,
                  color: 16776960,
                },
              ],
            });
          } catch (error) {
            console.error("Error while sending data to Discord", error);
          }
        }

        fireAnalyticsEvent.fireCrisp(CrispEvents.switchPlan, {
          title,
          monthly_price,
          activePricingPeriod,
          plan_id,
        });

        window.posthog.capture(PosthogEvents.CHANGE_PLAN, {
          previous_plan: previousPlan.title,
          previous_plan_period: previousPlan.period,
          new_plan: title,
          new_plan_period: activePricingPeriod,
          $set: {
            plan: title,
            plan_period: activePricingPeriod,
          },
        });

        showConfetti();

        props.setPaymentHistoryDataStatus(BillingDataStatus.notLoaded);
        props.setPaymentMethodDataStatus(BillingDataStatus.notLoaded);
      })
      .catch((error) => {
        const errorMessage = getPaymentErrorMessage(error);
        const errorStatusCode = _.get(error, "response.data.error.code");

        const message = (
          <div>
            There was an error during changing your plan: <b>{errorMessage}</b>.
            Really sorry. Please contact us, we will help you quickly.
          </div>
        );

        openNotification("Oh-h! :/", message, "Close", "error", 20);

        console.error(
          "An error during switching the plan.",
          `Code: ${errorStatusCode}, Message: "${errorMessage}"`
        );

        fireAnalyticsEvent.fireCrisp(
          CrispEvents.switchPlanError,
          {
            title,
            monthly_price,
            activePricingPeriod,
            plan_id,
            errorMessage,
          },
          true
        );

        fireAnalyticsEvent.fireCrisp(CrispEvents.paymentFailed, {}, true);
      })
      .then((response) => {
        props.toggleSwitchingProPlanInProgress(false);
      });
  };

  const handleConfirmUpgradeFromFree = () => {
    let upgradeToProPlan = (eventData) => {
      // call this function after the payment is successful. This request marks the user a pro in the backend.
      // openNotification('Payment success', 'Payment is successful. Upgrading your account in the platform...', 'OK', 'info', 5);
      const userEmail: string = _.get(eventData, ["user", "email"], null);

      props.toggleUpgradingFromFreeToProInProgress(true);

      upgradeFromFreeAsync(auth.accessToken, paddle_plan_id, userEmail)
        .then((response) => {
          openNotification(
            "Congratulations!",
            'Your have been successfully upgraded to the "' +
              capitalizeString(title) +
              " " +
              activePricingPeriod +
              '" plan. Happy building!',
            "Awesome!",
            "success",
            10,
            <Icon type="smile" style={{ color: "var(--grass)" }} />
          );

          // our ajax request has changed somehow the user object, let's get the fresh one from the DB.
          props.fetchCurrentUserInfo();

          // set user isPro = true because upgrading to any plan from a free plan always results in becoming a pro.
          props.toggleUserPro(true);

          fireAnalyticsEvent.fireCrisp(CrispEvents.successfullyUpgradeToPlan, {
            plan_title: title,
            plan_price: monthly_price,
            plan_period: activePricingPeriod,
            plan_id,
          });
          fireAnalyticsEvent.fireFacebook(FacebookEvents.purchase, {
            value: monthly_price,
            currency: "USD",
          });
          fireAnalyticsEvent.fireGoogleTag(GoogleTagEvents.upgrade, {
            Plan: capitalizeString(title.toLowerCase()),
          });

          fireAnalyticsEvent.fireCrisp(
            title === makerPlanTitle
              ? CrispEvents.successfullyUpgradeToMakerPlan
              : CrispEvents.successfullyUpgradeToNonMakerPlan,
            {
              plan_title: title,
              plan_price: monthly_price,
              plan_period: activePricingPeriod,
              plan_id,
            }
          );

          window.posthog.capture(PosthogEvents.UPGRADE_FROM_FREE, {
            plan: title,
            plan_period: activePricingPeriod,
            $set: {
              plan: title,
              plan_period: activePricingPeriod,
            },
          });

          showConfetti();

          const price = generatePriceString(monthly_price, activePricingPeriod);
          try {
            sendToDiscordAsync({
              embeds: [
                {
                  title: "New Subscription to Unicorn Platform",
                  description: `Plan title: ${capitalizeString(title)}
              Billing period: ${capitalizeString(activePricingPeriod)}
              Price: ${price}
              User email: ${_.get(user, "data.email")}
              User name: ${_.get(user, "data.first_name")}
              User id: ${_.get(user, "data.id")}`,
                  color: 5763719,
                },
              ],
            });
          } catch (error) {
            console.error("Error while sending data to Discord", error);
          }

          let userEvent =
            title === makerPlanTitle
              ? UserEvents.UpgradedToMaker
              : UserEvents.UpgradedToNonMaker;

          let userEventTwoMonth =
            title === makerPlanTitle
              ? UserEvents.UpgradedToMakerTwoMonth
              : UserEvents.UpgradedToNonMakerTwoMonth;

          setUserEventsAsync(
            {
              [userEvent]: new Date(),
              [userEventTwoMonth]: new Date(),
            },
            auth.accessToken,
            user.data.events
          ).then((response) => {
            if (response) {
              props.saveUserEventsInStore(response.data);
            }
          });

          props.setPaymentHistoryDataStatus(BillingDataStatus.notLoaded);
          props.setPaymentMethodDataStatus(BillingDataStatus.notLoaded);
        })
        .catch((error) => {
          fireAnalyticsEvent.fireCrisp(
            CrispEvents.upgradeToPlanError,
            {
              plan_title: title + " " + activePricingPeriod,
              plan_price: monthly_price,
              plan_id,
              error_type: "plan_upgrade",
              error_message: "Error while upgrading a database record",
            },
            true
          );

          openNotification(
            "Bummer! :(",
            'There was an error during upgrading: "' +
              error +
              '". Sorry for that. Please contact us, we will help you quickly.',
            "Close",
            "error",
            20
          );

          fireAnalyticsEvent.fireCrisp(CrispEvents.paymentFailed, {}, true);
        })
        .then((response) => {
          props.toggleUpgradingFromFreeToProInProgress(false);
        });
    };

    if (window.Paddle) {
      fireAnalyticsEvent.fireCrisp(CrispEvents.openCheckoutWindow, {
        plan_title: title,
        plan_price: monthly_price,
        plan_period: activePricingPeriod,
        plan_id,
      });

      window.Paddle.Checkout.open({
        product: paddle_plan_id,
        successCallback: function (data: any) {
          upgradeToProPlan(data);
          fireAnalyticsEvent.fireCrisp(CrispEvents.successfullyPaid, {
            plan_title: title,
            plan_price: monthly_price,
            plan_period: activePricingPeriod,
            plan_id,
          });
        },
        // coupon: phDiscountCouponCode,
        email: user.data.email,
        customData: {
          user_id: user.data.id,
        },
      });
    } else {
      fireAnalyticsEvent.fireCrisp(
        CrispEvents.upgradeToPlanError,
        {
          plan_title: title + " " + activePricingPeriod,
          plan_price: monthly_price,
          plan_id,
          error_type: "paddle",
          error_message: "window.Paddle is undefined",
        },
        true
      );

      openNotification(
        "Error",
        "The payment processor seems to be unavailable. Please refresh the page and retry. If the problem still appears, contact us.",
        "OK",
        "warn",
        20,
        <Icon type="meh" style={{ color: "var(--blood)" }} />
      );
    }
  };

  if (is_active) {
    // If plan active - we don't offer a "Change to this plan" button
    if (monthly_price === 0) {
      // If the free plan is active - no button at all - just a signature.
      return null;
    } else {
      // If a pro (>$0) is active, we offer to cancel this plan.

      if (user.isLtd || user.isAppsumo) {
        return (
          <Button type="primary" disabled={true} icon="stop">
            Cancel
          </Button>
        );
      } else if (
        user.isCancellingProInProgress ||
        user.isSwitchingProPlanInProgress
      ) {
        return (
          <Button type="danger" loading={true} icon="loading">
            Loading
          </Button>
        );
      } else {
        let priceForAwholePeriod = monthly_price;
        if (activePricingPeriod === "yearly") {
          priceForAwholePeriod = priceForAwholePeriod * 12;
        }

        let confirmText = (
          <div style={{ width: 350 }}>
            Cancel the current plan (<b>{capitalizeString(title)}</b> &mdash; $
            {priceForAwholePeriod} {activePricingPeriod}) and revert to the free
            plan? Pro features like 'custom domain' and 'shared access' will be
            removed.
          </div>
        );
        return (
          <div>
            <Popconfirm
              title={confirmText}
              onConfirm={handleConfirmCancel}
              okText={"Yes, cancel"}
              cancelText="No, stay"
            >
              <Button type="danger" icon="exclamation-circle">
                Cancel
              </Button>
            </Popconfirm>
          </div>
        );
      }
    }
  } else {
    // User can upgrade to a plan which is NOT active at the moment.

    if (
      user.isLtd ||
      user.isAppsumo ||
      (user.isPro === true && monthly_price === 0)
    ) {
      // If LTD user or a Pro user and displaying the free plan, we can not Upgrade to it.
      /*
       * 1) LTD do not need to upgrade anymore - LTD is the most powerful plan.
       * 2) Pro users do not want to Upgrade to free plan, they can only cancel the current plan.
       * */
      return (
        <Button
          type={isHighlighted ? "default" : "primary"}
          disabled={true}
          icon="sync"
        >
          Upgrade
        </Button>
      );
    } else if (user.isPro === true && userHasSubscription) {
      let priceForAwholePeriod = monthly_price;
      if (activePricingPeriod === "yearly") {
        priceForAwholePeriod = priceForAwholePeriod * 12;
      }

      let currentPlan = getCurrentPlan(user, plans);
      let confirmText = (
        <div style={{ width: 350 }}>
          Cancel the current plan (<b>{capitalizeString(currentPlan.title)}</b>{" "}
          &mdash; ${currentPlan.price} {currentPlan.period})<br />
          and switch to the new plan (<b>{capitalizeString(title)}</b> &mdash; $
          {priceForAwholePeriod} {activePricingPeriod})? <br />
          <br />
          The credits you paid in the current payment period will be used for
          all future payments <Icon type="smile" theme="twoTone" />
        </div>
      );
      // add pro-rate calculation
      return (
        <div>
          <Tooltip
            title={
              <span>
                The credits you already paid will be used for the future
                payments.
              </span>
            }
          >
            <Icon
              style={{ opacity: 0.7, marginRight: 7 }}
              type="question-circle"
            />
          </Tooltip>
          <Popconfirm
            title={confirmText}
            onConfirm={handleConfirmSwitch}
            okText={"Let's do it!"}
            cancelText={
              'No, stay on "' +
              capitalizeString(currentPlan.title) +
              " " +
              currentPlan.period +
              '"'
            }
          >
            <Button
              loading={user.isSwitchingProPlanInProgress}
              type="primary"
              disabled={false}
              icon="sync"
            >
              Switch
            </Button>
          </Popconfirm>
        </div>
      );
    } else {
      // If he is on a free plan and want to start paying. This step includes payment. We must be sure that the payment processor is loaded and available. Before that the button is disabled.

      let isButtonLoading = false;
      if (
        !payment.isProcessingSystemReady ||
        user.isUpgradingFromFreeToProInProgress ||
        user.isSwitchingProPlanInProgress
      ) {
        // The upgrade from free plan to a pro plan button is disabled (loading) if the payment processor is not loaded and initiated yet OR if a request for upgrade is sent and we are waiting for the server response.
        isButtonLoading = true;
      }

      return (
        <div>
          <Button
            className="plan__upgrade_button"
            onClick={() => {
              handleConfirmUpgradeFromFree();
            }}
            loading={isButtonLoading}
            style={isHighlighted ? { backgroundColor: "#303030" } : {}}
            icon="star"
            type={"primary"}
          >
            Upgrade
          </Button>
        </div>
      );
    }
  }
};

const mapStateToProps = ({ user, payment, plans, auth }) => {
  return {
    user,
    payment,
    plans,
    auth,
  };
};
const mapActionsToProps = {
  fetchCurrentUserInfo,
  toggleUserPro,
  setPaymentHistoryDataStatus,
  setPaymentMethodDataStatus,
  toggleCancellingPro,
  toggleSwitchingProPlanInProgress,
  toggleUpgradingFromFreeToProInProgress,
  saveUserEventsInStore,
};
export default connect(mapStateToProps, mapActionsToProps)(PlanCTA);
