import React, { Component } from "react";
import { Button, Divider, Form, Icon, Input } from "antd";
import "./JoinForm.css";
import "../../data/urls";
import { Link, Redirect } from "react-router-dom";
import AuthForm from "./AuthForm";
import "./AuthForm.css";
import "./AuthFormHeader.css";
import AuthFormHeader from "./AuthFormHeader";
import setInputStatus from "../../helpers/setInputStatus";
import { urls, api } from "../../data/urls";
import axios from "axios";
import objectToFormData from "../../helpers/objectToFormData";
import {
  getAccessTokenCookie,
  setAccessTokenCookie,
} from "../../helpers/auth/accessTokenCookie";
import { openNotification } from "../../helpers/openNotification";
import { messages } from "../../data/messages";
import fireAnalyticsEvent from "../../helpers/editor/fireAnalyticsEvent";
import { DISCORD_COLOR_PURPLE, TURNSTILE_SITE_KEY } from "../../data/constants";
import _ from "lodash";
import { getMarketingParamsForDiscord } from "../../helpers/getMarketingParamsForDiscord";
import {
  FacebookEvents,
  GoogleTagEvents,
} from "../../enums/AnalyticsEventsEnums";
import { setUserEventsAsync } from "../../helpers/user/setUserEventsAsync";
import { UserEvents } from "../../enums/UserEvents";
import {
  SaveUserEventsInStore,
  SetUserStateData,
  UserState,
} from "../../store/user/types";
import { getAllQueryParams } from "../../helpers/getAllQueryParams";
import { Oauth2Apps } from "../../enums/Oauth2Apps";
import getUrlParameter from "../../helpers/getUrlParameter";
import { getFirstEmailPart } from "../../helpers/strings/getFirstEmailPart";
import { openGoogleLoginPage } from "../../helpers/openGoogleLoginPage";
import GoogleIcon from "./GoogleIcon";
import { createScript } from "../../helpers/createScript";

interface Props {
  auth: any;
  form: any;
  toggleAccessTokenIsValid: any;
  saveAccessTokenInState: any;
  accessTokenToggleServerResponded: any;
  registrationToggleWaitingForServer: any;
  registrationToggleInvalidEmail: any;
  registrationToggleInvalidPassword: any;
  registrationToggleInvalidPasswordRepeat: any;
  registrationSetEmailMessage: any;
  registrationSetPasswordMessage: any;
  registrationSetPasswordRepeatMessage: any;
  validateUserAccessTokenInAuth: any;
  getCaptchaTokenAndSubmitTheForm: any;
  whereUserCameFrom: any;
  toggleNeedsOnboarding: any;
  saveUserEventsInStore: SaveUserEventsInStore;
  setUserStateData: SetUserStateData;
  user: UserState;
}

class JoinForm extends Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isTurnstileVisible: false,
    }
  }
  componentWillUnmount(): void {
    if ((window as any).turnstile && (window as any).turnstile.remove) {
      (window as any).turnstile.remove();
    }
  }
  componentWillMount(): void {
    if (!getAccessTokenCookie()) {
      this.props.toggleAccessTokenIsValid(false);
      this.props.accessTokenToggleServerResponded(true);
      return;
    }
    this.props.validateUserAccessTokenInAuth();
  }

  clearFieldsMessages() {
    this.props.registrationToggleInvalidEmail(false);
    this.props.registrationToggleInvalidPassword(false);
    this.props.registrationToggleInvalidPasswordRepeat(false);
    this.props.registrationSetEmailMessage(undefined);
    this.props.registrationSetPasswordMessage(undefined);
    this.props.registrationSetPasswordRepeatMessage(undefined);
  }

  handleSubmit = (e: any, token?: string) => {
    if (e) {
      e.preventDefault();
    }
    if (!this.props.user.isCaptchaLoaded) {
      return;
    }

    this.clearFieldsMessages();

    this.props.form.validateFieldsAndScroll((err: object, values: object) => {
      const email = _.get(values, "email");
      if (typeof email === "string" && email.includes("+")) {
        this.props.registrationSetEmailMessage("Email can't contain '+'");
        this.props.registrationToggleInvalidEmail(true);
        return;
      }
      if (!err) {
        this.props.registrationToggleWaitingForServer(true);
        let dataSentToServer = objectToFormData({ ...values, 'g-recaptcha-response': token, version: 2 });
        axios
          .post(api.auth.registration, dataSentToServer)
          .then((response) => {
            const params = getMarketingParamsForDiscord();
            const name = _.get(values, "first_name");
            const email = _.get(values, "email");

            let accessToken = response.data.key;

            setAccessTokenCookie(accessToken);
            this.props.saveAccessTokenInState(accessToken);
            this.props.toggleAccessTokenIsValid(true);

            openNotification(
              "Welcome! 🦄",
              "Now please confirm your email (check the spam folder).",
              "OK",
              "success",
              30
            );

            this.props.toggleNeedsOnboarding(true);

            fireAnalyticsEvent.fireFacebook(FacebookEvents.registration);
            fireAnalyticsEvent.fireGoogleTag(GoogleTagEvents.signUp);
            window.twq("event", "tw-oeqwf-oeqws", {
              email_address: email,
            });

            setUserEventsAsync(
              {
                [UserEvents.SignedUp]: new Date(),
                [UserEvents.SignedUpWebsiteCount]: new Date(),
              },
              accessToken,
              {}
            ).then((response) => {
              if (response) {
                this.props.saveUserEventsInStore(response.data);
              }
            });

            this.props.setUserStateData({
              isSignedUp: true,
            });
            window.fpr("referral", { email });
          })
          .catch((error) => {
            if ((window as any).turnstile && (window as any).turnstile.reset) {
              (window as any).turnstile.reset();
              // @ts-ignore
              this.setState({ isTurnstileVisible: false });
            }
            const captchaError = _.get(error, 'response.data.captcha_error');
            if (captchaError) {
              openNotification('Recaptcha error', `Details: ${captchaError}`, 'OK', 'error');
            }
            try {
              let errorData = error.response.data;

              if (errorData.email !== undefined) {
                let message = errorData.email.join(" ");
                this.props.registrationSetEmailMessage(message);
                this.props.registrationToggleInvalidEmail(true);
              }

              if (errorData.password1 !== undefined) {
                let message = errorData.password1.join(" ");
                this.props.registrationSetPasswordMessage(message);
                this.props.registrationToggleInvalidPassword(true);
              }

              if (errorData.password2 !== undefined) {
                let message = errorData.password2.join(" ");
                this.props.registrationSetPasswordRepeatMessage(message);
                this.props.registrationToggleInvalidPasswordRepeat(true);
              }

              if (errorData.non_field_errors !== undefined) {
                let message = errorData.non_field_errors.join(" ");
                this.props.registrationSetPasswordMessage(message);
                this.props.registrationToggleInvalidPassword(true);
                this.props.registrationSetPasswordRepeatMessage(message);
                this.props.registrationToggleInvalidPasswordRepeat(true);
              }
            } catch (e) {
              openNotification(
                "Server error",
                messages.errors.other.serverUnavailable,
                "OK",
                "error"
              );
            }
          })
          .finally(() => {
            this.props.registrationToggleWaitingForServer(false);
          });
      }
    });
  };

  componentDidMount() {
    //@ts-ignore
    window.submitAuthForm = this.handleSubmit;
    //@ts-ignore
    window.captchaLoaded = () => {
      this.props.setUserStateData({
        isCaptchaLoaded: true,
      });
    };

    createScript("https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onCaptchaLoad");
    
    let script = document.querySelector(
      `script[id="captcha-callback"]`
    ) as HTMLScriptElement;
    if (!script) {
      script = document.createElement("script");
      script.id = "captcha-callback";
      script.innerHTML = `
        function onSuccess(token) {
          if (window.submitAuthForm) {
            window.submitAuthForm(null, token);
          }
        }
        function onCaptchaLoad() {
          if (window.captchaLoaded) {
            window.captchaLoaded();
          }
        }
      `;
      document.head.append(script);
    } 

    const captcha = document.querySelector('.g-recaptcha');
    if (captcha && (window as any).grecaptcha && (window as any).grecaptcha.render) {
      (window as any).grecaptcha.render(captcha);
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const queryString = getAllQueryParams();
    const appParam = getUrlParameter("oauth2_app") as Oauth2Apps;

    const emailParam = getUrlParameter("email");
    const userName = getFirstEmailPart(emailParam);
    const userEmail = emailParam || "";

    if (
      this.props.auth.accessTokenValidated === true &&
      this.props.auth.isAccessTokenValid === true
    ) {
      if (Object.values(Oauth2Apps).includes(appParam)) {
        return <Redirect to={`/permission/${appParam}${queryString}`} />;
      }
      return <Redirect to={`/${queryString}`} />;
    }

    const formItemLayout = {};

    return (
      <AuthForm>
        <div className="login-form">
          <Form
            {...formItemLayout}
            className="login-form__form"
            id="unicorn-auth-form"
            onSubmit={(e) => {
              e.preventDefault();
              if (!this.props.user.isCaptchaLoaded) return;
              if (this.props.auth.join.isWaitingForServer) return;
              // @ts-ignore
              window.turnstile.execute();
              this.setState({ isTurnstileVisible: true });
            }}
          >
            <AuthFormHeader title="Register" subtitle="No credit card required">
              <Icon type="lock" title="Secure form" style={{ opacity: 0.5 }} />
            </AuthFormHeader>

            <div>
              <Button onClick={openGoogleLoginPage} size="large" block>
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 10,
                    justifyContent: "center",
                    lineHeight: 1,
                  }}
                >
                  <GoogleIcon /> Continue with Google
                </span>
              </Button>
              <Divider style={{ margin: "24px 0 16px", fontSize: 14 }}>
                Or
              </Divider>
            </div>

            {this.props.whereUserCameFrom && (
              <Form.Item label="AppSumo sales ended">
                {getFieldDecorator("appsumo_code", {
                  rules: [
                    {
                      required: true,
                      message: "Please input your AppSumo code!",
                    },
                  ],
                })(
                  <Input
                    disabled
                    required={true}
                    value={"uni-"}
                    maxLength={8}
                    name="appsumo_code"
                    id="appsumo_code"
                    placeholder="uni-****"
                  />
                )}
              </Form.Item>
            )}

            <Form.Item
              label="Name"
              validateStatus={setInputStatus(
                this.props.auth.join.isInvalidFirstName
              )}
              help={this.props.auth.join.firstNameMessage}
            >
              {getFieldDecorator("first_name", {
                initialValue: userName,
              })(
                <Input
                  required={true}
                  name="first_name"
                  id="first_name"
                  placeholder="First name"
                />
              )}
            </Form.Item>

            <Form.Item
              label="E-mail"
              validateStatus={setInputStatus(
                this.props.auth.join.isInvalidEmail
              )}
              help={this.props.auth.join.emailMessage}
            >
              {getFieldDecorator("email", {
                initialValue: userEmail,
              })(
                <Input
                  name="email"
                  id="email"
                  required={true}
                  allowClear={true}
                  prefix={
                    <Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder="Email"
                  type="email"
                />
              )}
            </Form.Item>
            <Form.Item
              label="Password"
              validateStatus={setInputStatus(
                this.props.auth.join.isInvalidPassword
              )}
              help={this.props.auth.join.passwordMessage}
            >
              {getFieldDecorator("password1", {})(
                <Input
                  name="password1"
                  required={true}
                  prefix={
                    <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  type="password"
                  placeholder="Password"
                  autoFocus={!!emailParam}
                />
              )}
            </Form.Item>
            <Form.Item
              label="Repeat password"
              validateStatus={setInputStatus(
                this.props.auth.join.isInvalidPasswordRepeat
              )}
              help={this.props.auth.join.passwordRepeatMessage}
            >
              {getFieldDecorator("password2", {})(
                <Input
                  name="password2"
                  required={true}
                  prefix={
                    <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  type="password"
                  placeholder="Repeat password"
                />
              )}
            </Form.Item>
            <Form.Item style={{
              display: this.state.isTurnstileVisible ? "block" : "none",
              marginTop: 13,
              marginBottom: 13,
              height: 65
            }} className="turnstile-container">
              <div 
                className="cf-turnstile" 
                data-sitekey={TURNSTILE_SITE_KEY}
                data-callback="onSuccess"
                data-action="signup"
                data-theme="light"
                data-execution="execute"
              ></div>
            </Form.Item>
            <Form.Item>
              <Button
                size="large"
                type="primary"
                loading={this.props.auth.join.isWaitingForServer || !this.props.user.isCaptchaLoaded}
                htmlType="submit"
                className="login-form__button"
                block
              >
                Create an account
              </Button>
            </Form.Item>
          </Form>
          <div className="join-form__agreements">
            By creating an account, you agree to the{" "}
            <a href={urls.tos} target="_blank">
              terms of service
            </a>{" "}
            and{" "}
            <a href={urls.privacyPolicy} target="_blank">
              privacy policy
            </a>
            . We use emails to announce updates and important news.
          </div>
          <a
            href={`/auth/login${queryString}`}
            onClick={() => {
              this.clearFieldsMessages();
              window.open(`/auth/login${queryString}`, '_self');
            }}
          >
            <Button
              type="default"
              htmlType="button"
              className=""
            >
              Login
            </Button>
          </a>
        </div>
      </AuthForm>
    );
  }
}

export default Form.create<Props>({})(JoinForm);
