export const componentsVerticalPaddings:any = {
    // Each component has a default padding-top and padding-bottom value.
    // Users can change them to tune vertical space between components.

    header: {
      '04':{
        'top': 70,
        'bottom': 100,
      },
      '28':{
        'top': 70,
        'bottom': 70,
      },
      '61':{
        'top': undefined,
        'bottom': undefined,
      },
      '27':{
        'top': 50,
        'bottom': 70,
      },
      '35':{
        'top': 70,
        'bottom': 70,
      },
      '60':{
        'top': 70,
        'bottom': 70,
      },
      '65':{
        'top': 70,
        'bottom': 70,
      },
      '23':{
        'top': 70,
        'bottom': 70,
      },
      '63':{
        'top': undefined,
        'bottom': undefined,
      },
      '64':{
        'top': undefined,
        'bottom': undefined,
      },
      '62':{
        'top': 40,
        'bottom': 70,
      },
      '43':{
        'top': 70,
        'bottom': 70,
      },
      '29':{
        'top': 70,
        'bottom': 70,
      },
      '03':{
        'top': 70,
        'bottom': 70,
      },
      '44':{
        'top': 70,
        'bottom': 60,
      },
      '39':{
        'top': 70,
        'bottom': 70,
      },
      '49':{
        'top': 70,
        'bottom': undefined, //means this component has a css predefined padding-bottom which can not be tuned.
      },
    },
    tabs: {
      '01': {
        'top': 50,
        'bottom': 70,
      },
      '03': {
        'top': 50,
        'bottom': 50,
      },
      '04': {
        'top': 50,
        'bottom': 50,
      },
      '10': {
        'top': 50,
        'bottom': 50,
      },
    },
    features: {
      '01': {
        'top': 30,
        'bottom': 50,
      },
      '02': {
        'top': 30,
        'bottom': 50,
      },
      '03': {
        'top': 30,
        'bottom': 50,
      },
      '05': {
        'top': 30,
        'bottom': 50,
      },
      '09': {
        'top': 30,
        'bottom': 50,
      },
      '06': {
        'top': 0,
        'bottom': 0,
      },
      '07': {
        'top': 0,
        'bottom': 0,
      },
    },
    photos: {
      '01': {
        'top': 50,
        'bottom': 70,
      },
      '02': {
        'top': 50,
        'bottom': 70,
      },
      '03': {
        'top': 50,
        'bottom': 70,
      },
    },
    text: {
      '01': {
        'top': 70,
        'bottom': 70,
      },
      '02': {
        'top': 30,
        'bottom': 30,
      },
      '03': {
        'top': 70,
        'bottom': 70,
      },
      '04': {
        'top': 70,
        'bottom': 70,
      },
      '06': {
        'top': 70,
        'bottom': 70,
      },
      '07': {
        'top': 50,
        'bottom': 70,
      },
      '08': {
        'top': 50,
        'bottom': 70,
      },
    },
    other: {
      '01': {
        'top': 0,
        'bottom': 0,
      },
      '02': {
        'top': 20,
        'bottom': 20,
      },
      '03': {
        'top': 70,
        'bottom': 70,
      },
      '04': {
        'top': 30,
        'bottom': 30,
      },
    },
    team: {
      '01': {
        'top': 50,
        'bottom': 30,
      },
      '02': {
        'top': 50,
        'bottom': 70,
      },
      '03': {
        'top': 50,
        'bottom': 70,
      },
    },
    testimonials: {
      '01': {
        'top': 50,
        'bottom': undefined,
      },
      '02': {
        'top': 50,
        'bottom': 50,
      },
      '05': {
        'top': 50,
        'bottom': 70,
      },
    },
    slider: {
      '01': {
        'top': 50,
        'bottom': 70,
      },
      '04': {
        'top': 50,
        'bottom': 50,
      },
      '05': {
        'top': 50,
        'bottom': 10,
      },
      '07': {
        'top': 50,
        'bottom': 10,
      },
      '10': {
        'top': 50,
        'bottom': 50,
      },
    },
    posts: {
      '01': {
        'top': 50,
        'bottom': 50,
      },
      '02': {
        'top': 50,
        'bottom': 50,
      },
      '03': {
        'top': 50,
        'bottom': undefined,
      },
      '04': {
        'top': 50,
        'bottom': 50,
      },
      '05': {
        'top': 50,
        'bottom': 50,
      },
    },
    faq: {
      '01': {
        'top': 50,
        'bottom': 50,
      },
      '02': {
        'top': 50,
        'bottom': 50,
      },
      '03': {
        'top': 50,
        'bottom': 50,
      },
    },
    pricing: {
      '01': {
        'top': 50,
        'bottom': 70,
      },
      '02': {
        'top': 50,
        'bottom': 70,
      },
      '03': {
        'top': 50,
        'bottom': 70,
      },
      '04': {
        'top': 50,
        'bottom': 70,
      },
      '05': {
        'top': 50,
        'bottom': 50,
      },
    },
    contact: {
      '01': {
        'top': 50,
        'bottom': 70,
      },
      '02': {
        'top': 50,
        'bottom': 40,
      },
    },
    clients: {
      '01': {
        'top': 50,
        'bottom': 70,
      },
      '02': {
        'top': 50,
        'bottom': 50,
      },
      '03': {
        'top': 50,
        'bottom': 70,
      },
    },
    steps: {
      '01': {
        'top': 50,
        'bottom': 70,
      },
      '03': {
        'top': 50,
        'bottom': 70,
      },
    },
    code: {
      '01': {
        'top': 50,
        'bottom': 60,
      },
      '02': {
        'top': 50,
        'bottom': 70,
      },
    },
    video: {
      '01': {
        'top': 50,
        'bottom': 70,
      },
      '02': {
        'top': 50,
        'bottom': 70,
      },
      '03': {
        'top': 50,
        'bottom': 70,
      },
      '04': {
        'top': 50,
        'bottom': 70,
      },
    },
    cta_button: {
      '01': {
        'top': 50,
        'bottom': 70,
      },
      '21': {
        'top': 70,
        'bottom': 70,
      },
      '03': {
        'top': 70,
        'bottom': 70,
      },
      '12': {
        'top': 50,
        'bottom': 70,
      },
      '16': {
        'top': 70,
        'bottom': 70,
      },
      '25': {
        'top': 50,
        'bottom': 70,
      },
    },
    cta_form: {
      '01': {
        'top': 30,
        'bottom': 70,
      },
      '04': {
        'top': 70,
        'bottom': 70,
      },
      '03': {
        'top': 70,
        'bottom': 70,
      },
    },
    links: {
      '01': {
        'top': 50,
        'bottom': 70,
      },
      '02': {
        'top': 50,
        'bottom': 70,
      },
      '03': {
        'top': 50,
        'bottom': 70,
      },
      '04': {
        'top': 50,
        'bottom': 70,
      },
      '05': {
        'top': 50,
        'bottom': 70,
      },
      '06': {
        'top': 50,
        'bottom': 70,
      },
    },
    press: {
      '01': {
        'top': 50,
        'bottom': 70,
      },
      '02': {
        'top': 50,
        'bottom': 70,
      },
      '03': {
        'top': 50,
        'bottom': 70,
      },
    },
    popups: {
      '01': {
        'top': 70,
        'bottom': 70,
      },
    },
    directory: {
      '01': {
        'top': 70,
        'bottom': 70,
      },
      '02': {
        'top': 70,
        'bottom': 70,
      },
      '03': {
        'top': 70,
        'bottom': 70,
      },
      '04': {
        'top': 40,
        'bottom': 40,
      },
    }
};
