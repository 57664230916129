import React from "react";
import { SaveConnectedIntegrationsInServer } from "../../../../helpers/types/globalTypes";
import EmailInputs from "./EmailInputs";
import GoogleSheetInputs from "./GoogleSheetInputs";
import MailchimpInputs from "./MailchimpInputs";
import MailerLiteInputs from "./MailerLiteInputs";
import WebhookInputs from "./WebhookInputs";
import ZapierInputs from "./ZapierInputs";
import CmsInputs from "./CmsInputs";

interface Props {
  currentFormIntegrationTitle: string;
  currentFormIntegrationHumanTitle: string;
  currentWebsite: any;
  user: any;
  websites: any;
  componentItem: any;
  currentFormIntegrationWebhookMethod: string;

  setParentState: (newState: any) => void;
  saveConnectedIntegrationsInServer: SaveConnectedIntegrationsInServer;
}

const IntegrationInput = (props: Props): JSX.Element => {
  const {
    currentFormIntegrationTitle,
    currentWebsite,
    saveConnectedIntegrationsInServer,
    user,
    currentFormIntegrationHumanTitle,
    websites,
    componentItem,
    currentFormIntegrationWebhookMethod,
    setParentState,
  } = props;

  const mapInputsToIntegrationTitles: {
    [key in string]: JSX.Element;
  } = {
    mailerlite: (
      <MailerLiteInputs
        currentFormIntegrationTitle={currentFormIntegrationTitle}
        currentWebsite={currentWebsite}
        websites={websites}
        saveConnectedIntegrationsInServer={saveConnectedIntegrationsInServer}
      />
    ),
    mailchimp: (
      <MailchimpInputs
        currentWebsite={currentWebsite}
        currentFormIntegrationTitle={currentFormIntegrationTitle}
        websites={websites}
        saveConnectedIntegrationsInServer={saveConnectedIntegrationsInServer}
      />
    ),
    zapier: (
      <ZapierInputs
        currentFormIntegrationTitle={currentFormIntegrationTitle}
        currentWebsite={currentWebsite}
        saveConnectedIntegrationsInServer={saveConnectedIntegrationsInServer}
        websites={websites}
      />
    ),
    "google-sheet": (
      <GoogleSheetInputs
        currentFormIntegrationTitle={currentFormIntegrationTitle}
        currentFormIntegrationHumanTitle={currentFormIntegrationHumanTitle}
        currentWebsite={currentWebsite}
        componentItem={componentItem}
        user={user}
        saveConnectedIntegrationsInServer={saveConnectedIntegrationsInServer}
      />
    ),
    webhook: (
      <WebhookInputs
        currentFormIntegrationTitle={currentFormIntegrationTitle}
        currentWebsite={currentWebsite}
        websites={websites}
        currentFormIntegrationWebhookMethod={
          currentFormIntegrationWebhookMethod
        }
        saveConnectedIntegrationsInServer={saveConnectedIntegrationsInServer}
        setParentState={setParentState}
      />
    ),
    email: (
      <EmailInputs
        currentFormIntegrationTitle={currentFormIntegrationTitle}
        currentFormIntegrationHumanTitle={currentFormIntegrationHumanTitle}
        saveConnectedIntegrationsInServer={saveConnectedIntegrationsInServer}
        currentWebsite={currentWebsite}
        user={user}
        websites={websites}
      />
    ),
    cms: (
      <CmsInputs
        currentFormIntegrationTitle={currentFormIntegrationTitle}
        saveConnectedIntegrationsInServer={saveConnectedIntegrationsInServer}
        currentWebsite={currentWebsite}
        websites={websites}
      />
    ),
  };

  if (!(currentFormIntegrationTitle in mapInputsToIntegrationTitles)) {
    return null;
  }

  return (
    <ul style={{ listStyle: "none" }}>
      {mapInputsToIntegrationTitles[currentFormIntegrationTitle]}
    </ul>
  );
};

export default IntegrationInput;
