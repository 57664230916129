import { S3_BLOG, BUNNY_S3_CDN } from "../../data/constants";

export const formatImageUrl = (url: string, addOptimizer = true) => {
  if (!url || typeof url !== "string") {
    return url;
  }

  if (
    !url.includes("ucarecdn.com") &&
    !url.includes("unicorn-cdn.b-cdn.net") &&
    !url.includes(S3_BLOG)
  ) {
    return url;
  }

  if (url.includes(S3_BLOG)) {
    return url.replace(S3_BLOG, BUNNY_S3_CDN);
  }

  url = url.split("/").slice(0, 4).join("/");

  if (addOptimizer && !url.includes("?")) {
    url += "?optimizer=gif";
  }

  if (url.includes("unicorn-cdn.b-cdn.net")) {
    return url.replace("unicorn-cdn.b-cdn.net", "unicorn-images.b-cdn.net");
  }
  if (url.includes("ucarecdn.com")) {
    return url.replace("ucarecdn.com", "unicorn-images.b-cdn.net");
  }

  return url;
};
