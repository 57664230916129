import React from "react";
import { handleBadWebsiteError } from "../../../../helpers/handleBadWebsiteError";
import { openNotification } from "../../../../helpers/openNotification";
import IntegrationItem from "./IntegrationItem";
import { deleteIntegrationAsync } from "../../../../helpers/formIntegrations/deleteIntegrationAsync";
import { renameIntegrationAsync } from "../../../../helpers/formIntegrations/renameIntegrationAsync";
import { connect } from "react-redux";
import { renameIntegrationAction } from "../../../../store/websites/actions";

interface Props {
  currentWebsite: any;
  accessToken: string;
  websites: any;

  toggleWaitingForCommonWebsiteUpdate: (payload: boolean) => void;
  removeWebsiteFormIntegration: (payload: {
    id: string;
    currentWebsiteSubdomain: string;
  }) => void;

  renameIntegrationAction: any;
}

const AvailableIntegrationsList = (props: Props): JSX.Element => {
  const {
    currentWebsite,
    toggleWaitingForCommonWebsiteUpdate,
    accessToken,
    websites,
    removeWebsiteFormIntegration,
  } = props;

  const availableIntegrations =
    currentWebsite.connected_form_integrations.connected;

  if (!availableIntegrations || availableIntegrations.length === 0) return null;

  return availableIntegrations.map((item: any, key: number) => {
    let deleteIntegration = () => {
      toggleWaitingForCommonWebsiteUpdate(true);

      deleteIntegrationAsync(accessToken, item.id, currentWebsite)
        .then(() => {
          // Tell user that the integration is saved and can be reused over the website.
          // openNotification('Done', message, 'Close', 'success', 10);
        })
        .catch((error) => {
          // handle error
          if (error.response) {
            let errorData = error.response.data;

            handleBadWebsiteError(errorData);

            if (errorData.not_pro !== undefined) {
              let message = errorData.not_pro;
              openNotification("Denied", message, "OK", "warn");
            }

            if (errorData.locked !== undefined) {
              let message = errorData.locked;
              openNotification("Denied", message, "OK", "warn");
            }

            if (errorData.detail !== undefined) {
              let message = errorData.detail;

              // This happens when user tries to change a website which doesn't exist or doesn't belong to the user.
              openNotification(
                "Server error",
                'Error message: "' +
                  message +
                  '". This should not have happened. Please contact us.',
                "OK",
                "error"
              );
            }
          }
        })
        .then(() => {
          // always executed
          toggleWaitingForCommonWebsiteUpdate(false);
        });
    };

    let renameIntegration = (newName: string) => {
      toggleWaitingForCommonWebsiteUpdate(true);

      renameIntegrationAsync(accessToken, item.id, newName, currentWebsite)
        .then(() => {
          props.renameIntegrationAction({
            id: item.id,
            newName: newName,
            websiteId: currentWebsite.id,
          })
        })
        .catch((error) => {
          // handle error
          if (error.response) {
            let errorData = error.response.data;

            handleBadWebsiteError(errorData);

            if (errorData.not_pro !== undefined) {
              let message = errorData.not_pro;
              openNotification("Denied", message, "OK", "warn");
            }

            if (errorData.locked !== undefined) {
              let message = errorData.locked;
              openNotification("Denied", message, "OK", "warn");
            }

            if (errorData.detail !== undefined) {
              let message = errorData.detail;

              // This happens when user tries to change a website which doesn't exist or doesn't belong to the user.
              openNotification(
                "Server error",
                'Error message: "' +
                  message +
                  '". This should not have happened. Please contact us.',
                "OK",
                "error"
              );
            }
          }
        })
        .then(() => {
          // always executed
          toggleWaitingForCommonWebsiteUpdate(false);
        });
    };

    return (
      <IntegrationItem
        item={item}
        key={key}
        availableIntegrations={availableIntegrations}
        accessToken={accessToken}
        currentWebsite={currentWebsite}
        websites={websites}
        deleteIntegration={deleteIntegration}
        removeWebsiteFormIntegration={removeWebsiteFormIntegration}
        renameIntegration={renameIntegration}
      />
    );
  });
};

export default connect(null, { renameIntegrationAction })(AvailableIntegrationsList);
