import React, { Component } from "react";
import { Button, Divider, Form, Icon, Input } from "antd";
import "./LoginForm.css";
import "../../data/urls";
import { Link, Redirect } from "react-router-dom";
import AuthForm from "./AuthForm";
import "./AuthForm.css";
import "./AuthFormHeader.css";
import AuthFormHeader from "./AuthFormHeader";
import setInputStatus from "../../helpers/setInputStatus";
import axios from "axios";
import { api } from "../../data/urls";
import objectToFormData from "../../helpers/objectToFormData";
import {
  getAccessTokenCookie,
  setAccessTokenCookie,
} from "../../helpers/auth/accessTokenCookie";
import { openNotification } from "../../helpers/openNotification";
import { messages } from "../../data/messages";
import getUrlParameter from "../../helpers/getUrlParameter";
import { Oauth2Apps } from "../../enums/Oauth2Apps";
import { getAllQueryParams } from "../../helpers/getAllQueryParams";
import { notificationDuration, TURNSTILE_SITE_KEY } from "../../data/constants";
import { SetUserStateData, UserState } from "../../store/user/types";
import { openGoogleLoginPage } from "../../helpers/openGoogleLoginPage";
import GoogleIcon from "./GoogleIcon";
import { createScript } from "../../helpers/createScript";
import _ from "lodash";

interface Props {
  auth: any;
  form: any;
  user: UserState;
  saveAccessTokenInState: any;
  toggleAccessTokenIsValid: any;
  accessTokenToggleServerResponded: any;
  loginToggleWaitingForServer: any;

  loginToggleInvalidEmail: any;
  loginToggleInvalidPassword: any;

  loginSetEmailMessage: any;
  loginSetPasswordMessage: any;
  validateUserAccessTokenInAuth: any;
  setUserStateData: SetUserStateData;
}

class LoginForm extends Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isTurnstileVisible: false,
    }
  }
  componentWillUnmount(): void {
    if ((window as any).turnstile && (window as any).turnstile.remove) {
      (window as any).turnstile.remove();
    }
  }
  componentWillMount(): void {
    if (!getAccessTokenCookie()) {
      this.props.toggleAccessTokenIsValid(false);
      this.props.accessTokenToggleServerResponded(true);
      return;
    }
    this.props.validateUserAccessTokenInAuth();
  }

  clearFieldsMessages() {
    this.props.loginToggleInvalidEmail(false);
    this.props.loginToggleInvalidPassword(false);

    this.props.loginSetEmailMessage(undefined);
    this.props.loginSetPasswordMessage(undefined);
  }

  handleSubmit = (e: any, token?: string) => {
    if (e) {
      e.preventDefault();
    }
    if (!this.props.user.isCaptchaLoaded) {
      return;
    }


    // When submit the form - all red fields are back to normal.
    // When submit the form - all error messages are cleared.
    this.clearFieldsMessages();

    this.props.form.validateFieldsAndScroll((err: object, values: object) => {
      if (!err) {
        this.props.loginToggleWaitingForServer(true);
        const formData = {
          ...values,
          'g-recaptcha-response': token,
          version: 2
        };
        axios
          .post(api.auth.login, objectToFormData(formData))
          .then((response) => {
            // handle success
            // Django returns the access token.
            let accessToken = response.data.key;

            // We save the token in a Cookie to save the user session. When a user enter the app, we check this cookie. If the cookie valid - we fetch a user data and display it. If the cookie is invalid - we erase it and require to login/sign up.
            setAccessTokenCookie(accessToken);
            
            // After the token is in cookies, we can save the token in the state for further requests authorization.
            // IMPORTANT: save the token in cookies before changing the token validation process state because you may redirect user to the dashboard with non-set cookie and it will result in redirect back to login page and loop.
            this.props.saveAccessTokenInState(accessToken);

            // Since we just received a fresh token from the server, no need to validate it. We are sure it's valid.
            // We should keep in store that the token is valid.
            this.props.toggleAccessTokenIsValid(true);
            this.props.setUserStateData({
              isLoggedIn: true,
            });
            // this.props.accessTokenToggleServerResponded(true);
          })
          .catch((error) => {
            if ((window as any).turnstile && (window as any).turnstile.reset) {
              (window as any).turnstile.reset();
              // @ts-ignore
              this.setState({ isTurnstileVisible: false });
            }
            const captchaError = _.get(error, 'response.data.captcha_error');
            console.log('captchaError: ', captchaError);
            if (captchaError) {
              openNotification('Recaptcha error', `Details: ${captchaError}`, 'OK', 'error');
            }
            // handle error
            try {
              let errorData = error.response.data;

              if (errorData.email !== undefined) {
                let message = errorData.email.join(" ");
                this.props.loginSetEmailMessage(message);
                this.props.loginToggleInvalidEmail(true);
              }

              if (errorData.password !== undefined) {
                let message = errorData.password.join(" ");
                this.props.loginSetPasswordMessage(message);
                this.props.loginToggleInvalidPassword(true);
              }

              if (errorData.non_field_errors !== undefined) {
                // Usually "Unable to log in with provided credentials."
                let message = errorData.non_field_errors.join(" ");

                this.props.loginSetEmailMessage(message);
                this.props.loginToggleInvalidEmail(true);

                this.props.loginSetPasswordMessage(message);
                this.props.loginToggleInvalidPassword(true);
              }
            } catch (e) {
              openNotification(
                "Server error",
                messages.errors.other.serverUnavailable,
                "OK",
                "error"
              );
            }
          })
          .finally(() => {
            // always executed
            this.props.loginToggleWaitingForServer(false);
          });
      }
    });
  };

  componentDidMount() {
    //@ts-ignore
    window.submitAuthForm = this.handleSubmit;
    //@ts-ignore
    window.captchaLoaded = () => {
      this.props.setUserStateData({
        isCaptchaLoaded: true,
      });
    };
    

    createScript("https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onCaptchaLoad");
    
    let script = document.querySelector(
      `script[id="captcha-callback"]`
    ) as HTMLScriptElement;
    if (!script) {
      script = document.createElement("script");
      script.id = "captcha-callback";
      script.innerHTML = `
        function onSuccess(token) {
          if (window.submitAuthForm) {
            window.submitAuthForm(null, token);
          }
        }
        function onCaptchaLoad() {
          if (window.captchaLoaded) {
            window.captchaLoaded();
          }
        }
      `;
      document.head.append(script);
    } 

    const deletedAccountEmail = localStorage.getItem("deletedAccountEmail");
    if (!deletedAccountEmail) return;
    openNotification(
      "Account deleted",
      <>
        Account <b>{deletedAccountEmail}</b> and all associated data have been
        successfully deleted.
      </>,
      "OK",
      "success",
      notificationDuration.long
    );
    localStorage.removeItem("deletedAccountEmail");
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const appParam = getUrlParameter("oauth2_app") as Oauth2Apps;
    const queryString = getAllQueryParams();

    if (
      this.props.auth.accessTokenValidated === true &&
      this.props.auth.isAccessTokenValid === true
    ) {
      if (Object.values(Oauth2Apps).includes(appParam)) {
        return <Redirect to={`/permission/${appParam}${queryString}`} />;
      }
      return <Redirect to={`/${queryString}`} />;
    }

    return (
      <AuthForm>
        {/*<ValidateUser auth={this.props.auth}*/}
        {/*toggleAccessTokenIsValid={this.props.toggleAccessTokenIsValid}*/}
        {/*saveAccessTokenInState={this.props.saveAccessTokenInState}*/}
        {/*redirectToHome={true}*/}
        {/*redirectToLogin={false}*/}
        {/*accessTokenToggleServerResponded={this.props.accessTokenToggleServerResponded}*/}
        {/*/>*/}
        <div className="login-form">
          <Form 
            onSubmit={(e) => {
              e.preventDefault();
              if (!this.props.user.isCaptchaLoaded) return;
              if (this.props.auth.login.isWaitingForServer) return;
              // @ts-ignore
              window.turnstile.execute();
              this.setState({ isTurnstileVisible: true });
            }
          } className="login-form__form" id="unicorn-auth-form">
            <AuthFormHeader title="Sign in">
              <a
                className="login-form__forgot"
                href={`/auth/restore-pass${queryString}`}
                onClick={() => {
                  this.clearFieldsMessages();
                  window.open(`/auth/restore-pass${queryString}`, '_self');
                }}
              >
                Forgot password?
              </a>
            </AuthFormHeader>

            <div>
              <Button onClick={openGoogleLoginPage} size="large" block>
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 10,
                    justifyContent: "center",
                    lineHeight: 1,
                  }}
                >
                  <GoogleIcon /> Continue with Google
                </span>
              </Button>
              <Divider style={{ margin: "24px 0 24px", fontSize: 14 }}>
                Or
              </Divider>
            </div>

            <Form.Item
              validateStatus={setInputStatus(
                this.props.auth.login.isInvalidEmail
              )}
              help={this.props.auth.login.emailMessage}
            >
              {getFieldDecorator("email", {
                // rules: [{ required: true, message: 'Please input your email!' }],
              })(
                <Input
                  name="email"
                  id="email"
                  required={true}
                  allowClear={true}
                  prefix={
                    <Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder="Email"
                  type="email"
                />
              )}
            </Form.Item>
            <Form.Item
              validateStatus={setInputStatus(
                this.props.auth.login.isInvalidPassword
              )}
              help={this.props.auth.login.passwordMessage}
            >
              {getFieldDecorator("password", {
                // rules: [{ required: true, message: 'Please input your password!' }],
              })(
                <Input
                  name="password"
                  id="password"
                  required={true}
                  prefix={
                    <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  type="password"
                  placeholder="Password"
                />
              )}
            </Form.Item>
            <Form.Item style={{
              display: this.state.isTurnstileVisible ? "block" : "none",
              marginTop: 13,
              marginBottom: 13,
              height: 65
            }} className="turnstile-container">
              <div 
                className="cf-turnstile" 
                data-sitekey={TURNSTILE_SITE_KEY}
                data-callback="onSuccess"
                data-action="login"
                data-theme="light"
                data-execution="execute"
              ></div>
            </Form.Item>
            <Form.Item>
            <Button
              size="large"
              type="primary"
              loading={this.props.auth.login.isWaitingForServer || !this.props.user.isCaptchaLoaded}
              htmlType="submit"
              className="login-form__button"
              block
            >
              Sign in
            </Button>
            </Form.Item>
          </Form>
          <a
            href={`/auth/join${queryString}`}
            onClick={() => {
              this.clearFieldsMessages();
              window.open(`/auth/join${queryString}`, '_self');
            }}
          >
            <Button
              type="default"
              htmlType="button"
              className=""
            >
              Register for free
          </Button>
          </a>
        </div>
      </AuthForm>
    );
  }
}

export default Form.create<Props>({})(LoginForm);
