import React, { Component } from "react";
import { Button, Form, Icon, Input } from "antd";
import "./RestorePassForm.css";
import "../../data/urls";
import { Link } from "react-router-dom";
import AuthForm from "./AuthForm";
import "./AuthForm.css";
import "./AuthFormHeader.css";
import AuthFormHeader from "./AuthFormHeader";
import setInputStatus from "../../helpers/setInputStatus";
import { api, urls } from "../../data/urls";
import axios from "axios";
import objectToFormData from "../../helpers/objectToFormData";
import { openNotification } from "../../helpers/openNotification";
import { openChatWidget } from "../../helpers/editor/openChatWidget";
import { getAllQueryParams } from "../../helpers/getAllQueryParams";
import _ from "lodash";
import { DEFAULT_ERROR_MESSAGE, TURNSTILE_SITE_KEY } from "../../data/constants";
import { createScript } from "../../helpers/createScript";

interface Props {
  auth: any;
  form: any;
  user: any;

  restorePasswordToggleWaitingForServer: any;
  restorePasswordToggleInvalidAttempt: any;
  restorePasswordSetServerMessage: any;
  setUserStateData: any;
}

class RestorePassForm extends Component<Props> {
  state = {
    isChatLoading: false,
    isTurnstileVisible: false,
  };

  componentDidMount() {
    //@ts-ignore
    window.submitAuthForm = this.handleSubmit;
    //@ts-ignore
    window.captchaLoaded = () => {
      this.props.setUserStateData({
        isCaptchaLoaded: true,
      });
    };
    
    createScript("https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onCaptchaLoad");
    
    let script = document.querySelector(
      `script[id="captcha-callback"]`
    ) as HTMLScriptElement;
    if (!script) {
      script = document.createElement("script");
      script.id = "captcha-callback";
      script.innerHTML = `
        function onSuccess(token) {
          if (window.submitAuthForm) {
            window.submitAuthForm(null, token);
          }
        }
        function onCaptchaLoad() {
          if (window.captchaLoaded) {
            window.captchaLoaded();
          }
        }
      `;
      document.head.append(script);
    } 

    const captcha = document.querySelector('.g-recaptcha');
    if (captcha && (window as any).grecaptcha && (window as any).grecaptcha.render) {
      (window as any).grecaptcha.render(captcha);
    }
  }

  openChat() {
    this.setState({
      isChatLoading: true,
    });
    (window as any).$crisp.push([
      "on",
      "chat:opened",
      () => {
        this.setState({
          isChatLoading: false,
        });
      },
    ]);

    openChatWidget();
  }

  clearFieldsMessages() {
    this.props.restorePasswordToggleInvalidAttempt(false);
    this.props.restorePasswordSetServerMessage(undefined);
  }

  handleSubmit = (e: any, token?: string) => {
    if (e) {
      e.preventDefault();
    }
    if (!this.props.user.isCaptchaLoaded) {
      return;
    }

    this.clearFieldsMessages();

    this.props.form.validateFieldsAndScroll((err: object, values: object) => {
      if (!err) {
        this.props.restorePasswordToggleWaitingForServer(true);
        const formData = {
          ...values,
          'g-recaptcha-response': token,
          version: 2
        };

        axios
          .post(api.auth.sendPasswordResetLink, objectToFormData(formData))
          .then((response) => {
            let message =
              response.data.detail +
              " " +
              "If you didn't receive a message, double check the entered email and try again.";

            this.props.restorePasswordSetServerMessage(message);

            openNotification(
              "A restore email sent!",
              message,
              "I understand",
              "info",
              0
            );
          })
          .catch((error) => {
            const captchaError = _.get(error, 'response.data.captcha_error');
            console.log('captchaError: ', captchaError);
            if (captchaError) {
              openNotification('Recaptcha error', `Details: ${captchaError}`, 'OK', 'error');
            }

            if (error.response === undefined) {
              openNotification(
                "Internal error",
                "A server error occurred. Please contact us.",
                "Close",
                "error",
                0
              );
            } else {
              const errorMessage = _.get(error, "response.data.detail", DEFAULT_ERROR_MESSAGE);

              this.props.restorePasswordToggleInvalidAttempt(true);
              this.props.restorePasswordSetServerMessage(errorMessage);
            }
          })
          .finally(() => {
            this.props.restorePasswordToggleWaitingForServer(false);
            if ((window as any).turnstile && (window as any).turnstile.reset) {
              (window as any).turnstile.reset();
              // @ts-ignore
              this.setState({ isTurnstileVisible: false });
            }
          });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const queryString = getAllQueryParams();

    return (
      <AuthForm>
        <div className="login-form">
          <Form 
            className="login-form__form" 
            id="unicorn-auth-form"
            onSubmit={(e) => {
              e.preventDefault();
              if (!this.props.user.isCaptchaLoaded) return;
              if (this.props.auth.restorePassword.isWaitingForServer) return;
              // @ts-ignore
              window.turnstile.execute();
              this.setState({ isTurnstileVisible: true });
            }}
          >
            <AuthFormHeader title="Restore password">
              {this.state.isChatLoading ? (
                <span className="login-form__forgot">
                  Loading chat <Icon type="loading" />
                </span>
              ) : (
                <a
                  className="login-form__forgot"
                  title="Open chat with a real human 🤓"
                  href="#"
                  onClick={(e: any) => {
                    e.preventDefault();
                    this.openChat();
                  }}
                >
                  Need help?
                </a>
              )}
            </AuthFormHeader>

            <Form.Item
              validateStatus={setInputStatus(
                this.props.auth.restorePassword.isInvalidAttempt
              )}
              help={this.props.auth.restorePassword.message}
            >
              {getFieldDecorator("email", {
                // rules: [{ required: true, message: 'Please input your email!' }],
              })(
                <Input
                  name="email"
                  id="email"
                  required={true}
                  allowClear={true}
                  prefix={
                    <Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder="Email"
                  type="email"
                />
              )}
            </Form.Item>
            <Form.Item style={{
              display: this.state.isTurnstileVisible ? "block" : "none",
              marginTop: 13,
              marginBottom: 13,
              height: 65
            }} className="turnstile-container">
              <div 
                className="cf-turnstile" 
                data-sitekey={TURNSTILE_SITE_KEY}
                data-callback="onSuccess"
                data-action="reset-password"
                data-theme="light"
                data-execution="execute"
              ></div>
            </Form.Item>
            <Form.Item>
              <Button
                size="large"
                icon="link"
                type="primary"
                loading={this.props.auth.restorePassword.isWaitingForServer || !this.props.user.isCaptchaLoaded}
                htmlType="submit"
                className="login-form__button"
              >
                Send reset link
              </Button>
            </Form.Item>
          </Form>
          <a
            href={`/auth/login${queryString}`}
            className="restore-pass-form__bottom_button"
            onClick={() => {
              this.clearFieldsMessages();
              window.open(`/auth/login${queryString}`, '_self');
            }}
          >
            <Button
              type="default"
              htmlType="button"
              className=""
            >
              Back to login
            </Button>
          </a>
        </div>
      </AuthForm>
    );
  }
}

export default Form.create<Props>({})(RestorePassForm);
