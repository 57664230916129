import { projectUrl } from "../../data/constants";
import { getWebsiteProtocol } from "../getWebsiteProtocol";

export const getCurrentWebsitePageUrlForExport = (
  currentWebsite: any,
  exportedPageUrl: string,
  blogHomePageURL?: string
) => {
  // exportedPageUrl can be: a page, a blog home page, a blog post
  // if blogHomePageURL - add also blog home page URL (usually 'blog')

  let finalExportedPageUrl = exportedPageUrl;
  if (blogHomePageURL && blogHomePageURL !== "") {
    finalExportedPageUrl = blogHomePageURL + "/" + finalExportedPageUrl;
  }

  let currentWebsitePageUrl = "";

  if (currentWebsite.is_custom_domain_active) {
    let websiteProtocol = getWebsiteProtocol(currentWebsite);
    currentWebsitePageUrl =
      websiteProtocol +
      currentWebsite.custom_domain +
      "/" +
      finalExportedPageUrl;
  } else {
    currentWebsitePageUrl =
      "https://" +
      currentWebsite.subdomain +
      "." +
      (currentWebsite.free_root_domain || projectUrl) +
      "/" +
      finalExportedPageUrl;
  }

  return currentWebsitePageUrl;
};
