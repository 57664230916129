import React, { Component } from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import { callToActionOptions } from "../../data/callToActionOptions";
import { buttonStyles } from "../../data/buttonStyles";
import { linkOrButtonOptions } from "../../data/linkOrButtonOptions";
import templatePluginsInitializerClass from "../../helpers/editor/templatePluginsInitializerClass";
import _ from "lodash";
import ContentInputsTypesEnum from "../../enums/ContentInputsTypesEnum";
import { GraphicsOptions } from "../../components/editor/component_graphics/data/componentGraphicsData";
import { checkIfSlideVisible } from "../../helpers/checkIfSlideVisible";
import GraphicsJSX from "../GraphicsJSX";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import TitleBox from "../component_molecules/TitleBox";
import { DEFAULT_IMAGES_CDN } from "../../data/urls";

interface Props {
  componentItem: any;
  checkIsEmptyContent: any;
  saveContentInStore: any;
  connectDefaultFormIntegration: any; //we need this to connect an integration to forms in CTAs
  isDarkBg: boolean;
}

class Tabs01 extends Component<Props> {
  generator = new generateContentDataObject();

  componentWillMount(): void {
    const { isDefaultEmpty } = this.props.componentItem;
    //check if the component has not content (when just added). if so, generate some default dummy content.
    // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
    if (this.props.checkIsEmptyContent()) {
      //generate defaultContent{}. Use special methods of the generateContentDataObject class;

      this.props.saveContentInStore(
        this.generator.setUpTitle({
          titleText: "Unicorn Platform Helps Great Startups Get Great Websites",
          isDefaultEmpty
        })
      );
      this.props.saveContentInStore(this.generator.setUpSubtitleText({isDefaultEmpty}));
      this.props.saveContentInStore(
        this.generator.setUpGallery({
          isDefaultEmpty,
          fieldTitle: { default: "Images tabs" },
          label: { default: "Edit the tabs:" },
          fieldInfo: { default: "Recommended format: 1440x900px" },

          maxItems: 16,
          tabHasTitle: true,
          items: [
            this.generator.createGalleryContentObject({
              tabTitle: "Manage Metrics",
              contentGraphic: [
                this.generator.createGraphicsContentObject({
                  imageUrl: DEFAULT_IMAGES_CDN.UI_DESKTOP_DASHBOARD_1,
                  videoUrl: "https://www.youtube.com/watch?v=tw4jkyfY4HE",
                  width: 1280,
                  height: 800,
                }),
              ],
            }),
            this.generator.createGalleryContentObject({
              tabTitle: "Plan Sessions",
              contentGraphic: [
                this.generator.createGraphicsContentObject({
                  imageUrl: DEFAULT_IMAGES_CDN.UI_DESKTOP_CALENDAR,
                  videoUrl: "https://www.youtube.com/watch?v=J2U9Hmmpqhc",
                  width: 1280,
                  height: 800,
                }),
              ],
            }),
            this.generator.createGalleryContentObject({
              tabTitle: "Track Spends",
              contentGraphic: [
                this.generator.createGraphicsContentObject({
                  imageUrl: DEFAULT_IMAGES_CDN.UI_DESKTOP_FINANCE,
                  videoUrl: "https://www.youtube.com/watch?v=lfucQM07tGQ",
                  width: 1280,
                  height: 800,
                }),
              ],
            }),
          ],
        })
      );

      this.props.saveContentInStore(
        this.generator.setUpCta({
          isDefaultEmpty,
          activeOption: callToActionOptions.buttons,
          fieldTitle: {
            [callToActionOptions.buttons]: "Bottom buttons",
            default: "Bottom buttons",
          },
          label: {
            [callToActionOptions.buttons]: "Edit the buttons:",
            default: "Edit the buttons:",
          },
          labelTooltip: {
            [callToActionOptions.buttons]:
              "Add a button to the bottom of the Tabs component to invite users to read more about your project.",
            default:
              "Add a button to the bottom of the Tabs component to invite users to read more about your project.",
          },

          buttons: [
            this.generator.createButtonContentObject({
              buttonType: linkOrButtonOptions.link,
              buttonTitle: "",
              buttonHref: "",
              buttonStyleType: buttonStyles.pill.typeTitle,
              pillPrefix: "See more",
            }),
          ],

          priority: 10,
        })
      );
    }
  }

  componentDidMount(): void {}

  render() {
    // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
    // see the content schema in the helpers/editor/generateContentDataObject.ts
    let title = displayTitleMarkupText(
      this.props.componentItem.componentData.title.content.markup
    );
    let subtitleText = displaySubtitleMarkupText(
      this.props.componentItem.componentData.subtitleText.content.markup
    );

    let gallery = this.props.componentItem.componentData.gallery.content.items;

    let randomIndex = Math.floor(Math.random() * 9999); //because each Tabs component needs a unique index to switch the tabs.

    const currentActiveOption = _.get(
      this.props.componentItem,
      ["componentData", ContentInputsTypesEnum.gallery, "activeOption"],
      GraphicsOptions.image
    );

    const version = _.get(this.props, "componentItem.componentData.gallery.version", 1);

    return (
      <section>
        <div
          className={`tabs-01 graphics-${currentActiveOption} default-graphics-${GraphicsOptions.image} ${version >= 2 ? "tabs-has-v2" : ""}`}
        >
          <TitleBox
            title={title}
            subtitleText={subtitleText}
            isDarkBg={this.props.isDarkBg}
          />

          <div className="container">
            <div className="tabs-01__box">
              <ul className="tabs-01__buttons_list">
                {gallery &&
                  gallery.map((item: any, key: number) => {
                    if (item.tabTitle && item.tabTitle !== "") {
                      return (
                        <li className="tab" key={"tabs-item-" + key}>
                          {/* The first item is rendered as active. It may be changed on clicking (the jQuery handler will change it). But if the component is re-rendered, the first tab will be active again. */}
                          <button
                            onClick={() => {
                              // We re-calc tabs height on every tab click because I do not know how to recalc tabs height when an image is loaded.
                              templatePluginsInitializerClass.setAllTabs();
                            }}
                            className={
                              "content-text def-12 tab__button js-open-tab " +
                              (key === 0 ? "state-active-tab" : "")
                            }
                            type="button"
                            data-index={"tab-" + key}
                            data-group={"my-images-group-" + randomIndex}
                          >
                            {item.tabTitle}
                          </button>
                        </li>
                      );
                    } else {
                      return null;
                    }
                  })}
              </ul>

              <div className="tabs-01__img_container">
                <ul className="tabs-01__img_list js-tabs-item-list"
                  data-gallery-version={version}
                >
                  {gallery &&
                    gallery.map((item: any, key: number) => {
                      const videoUrl = _.get(item, [
                        "contentGraphic",
                        0,
                        GraphicsOptions.video,
                        "youtube",
                        "url",
                      ]);
                      const codeSnippet = _.get(item, [
                        "contentGraphic",
                        0,
                        GraphicsOptions.HTML,
                        "codeSnippet",
                      ]);

                      if (checkIfSlideVisible(item, currentActiveOption)) {
                        return (
                          <li
                            key={"tabs-content-" + key}
                            className={
                              "tabs-01__img_box js-tab-content " +
                              (key === 0 ? "state-active-tab" : "")
                            }
                            data-index={"tab-" + key}
                            data-group={"my-images-group-" + randomIndex}
                          >
                            <GraphicsJSX
                              currentActiveOption={currentActiveOption}
                              defaultGraphicsOption={GraphicsOptions.image}
                              classNames={{
                                image: `tabs-01__img js-tab-content-item`,
                                video: "tabs-01__video js-tab-content-item",
                                HTML:
                                  "tabs-01__HTML tabs-01__editor-HTML js-tab-content-item",
                              }}
                              isDarkBg={this.props.isDarkBg}
                              imageUrl={item.contentGraphic[0].image.url}
                              videoUrl={videoUrl}
                              codeSnippet={codeSnippet}
                              permanentSpinnerColor
                              imageDataWidth={
                                item.contentGraphic[0].image.width || 1440
                              }
                              imageDataHeight={
                                item.contentGraphic[0].image.height || 900
                              }
                              setTabsHeightOnChange
                            />
                          </li>
                        );
                      } else {
                        return null;
                      }
                    })}
                </ul>
              </div>
            </div>

            <div className="bottom_cta">
              {this.props.componentItem.componentData.cta && (
                <WebsiteCTA componentItem={this.props.componentItem} />
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Tabs01;
