import axios, { AxiosResponse } from "axios";
import { api } from "../../data/urls";
import returnAuthHeaderForAJAX from "../auth/returnAuthHeaderForAJAX";

type SaveBackupAsync = (payload: {
  accessToken: string;
  dataToBackup: any;
  type;
}) => Promise<AxiosResponse<any>>;

export const saveBackupAsync: SaveBackupAsync = ({
  accessToken,
  dataToBackup,
  type,
}) => {
  return new Promise((resolve, reject) => {
    const apiUrl = `${api.meta.saveBackup}`;
    const data = {
      dataToBackup,
      type,
    };
    axios
      .post(apiUrl, data, { ...returnAuthHeaderForAJAX(accessToken) })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
