import { projectUrl } from "../../data/constants";

export const getFullPostUrl = (currentWebsite: any, blogUrl: string, postUrl: string) => {
    let actualBlogUrl;
    if (blogUrl === '') {
      actualBlogUrl = '';
    } else {
      actualBlogUrl = '/' + blogUrl;
    }
    let subdomainUrl = 'https://' + currentWebsite["subdomain"] + '.' + (currentWebsite.free_root_domain || projectUrl) + actualBlogUrl + '/' + postUrl;
    let customDomainUrl = 'https://' + currentWebsite["custom_domain"] + actualBlogUrl + '/' + postUrl;

    let fullPostUrl = subdomainUrl;
    if(currentWebsite.is_custom_domain_active){
      fullPostUrl = customDomainUrl;
    }

    fullPostUrl = fullPostUrl + '/';

    return fullPostUrl;
};
