import _ from "lodash";
import { SavePartialWebsiteData, WebsitesState } from "./types";
import { getCurrentWebsite } from "../../helpers/getCurrentWebsite";
import {
  changeWebsitesStateGlobalData,
  createWebsiteErrorMessage,
  createWebsiteWaitingForServer,
  editFillTemplateData,
  saveFullWebsiteToStore,
  saveSingleWebsiteInState,
  saveWebsiteFooterInState,
  saveWebsiteNavInState,
  saveWebsitesInState,
  setFullWebsiteLoadStatus,
  toggleWebsiteFooterEditionsDetected,
  toggleWebsiteNavEditionsDetected,
} from "./actions";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import { AuthState } from "../auth/types";
import { createWebsiteAsync } from "../../helpers/async/createWebsiteAsync";
import fireAnalyticsEvent from "../../helpers/editor/fireAnalyticsEvent";
import { CrispEvents } from "../../enums/AnalyticsEventsEnums";
import { changeWebsitePagesStateGlobalData, fetchSingleWebsitePages, setInitialPagesLoadStatus } from "../websitePages/actions";
import Cookies from "js-cookie";
import { getErrorValue } from "../../helpers/editor/getErrorValue";
import { getErrorKey } from "../../helpers/editor/getErrorKey";
import { handleBadWebsiteError } from "../../helpers/handleBadWebsiteError";
import { openNotification } from "../../helpers/openNotification";
import { WebsitePagesState } from "../websitePages/types";
import { BlogsState } from "../blogs/types";
import { BlogPostsState } from "../blogPosts/types";
import { getCurrentWebsitePageUrl } from "../../helpers/getCurrentWebsitePageUrl";
import { getCurrentWebsitePage } from "../../helpers/getCurrentWebsitePage";
import { getCurrentBlog } from "../../helpers/blog/getCurrentBlog";
import { getCurrentBlogPostUrl } from "../../helpers/blog/getCurrentBlogPostUrl";
import { getCurrentBlogPost } from "../../helpers/blog/getCurrentBlogPost";
import {
  getDefaultPopupData,
  getDefaultSuccessPopupComponentData,
} from "../../data/defaultData";
import { saveBackupAsync } from "../../helpers/async/saveBackupAsync";
import { getPathArray } from "../../helpers/getPathArray";
import { LoadStatus } from "../../enums/enums";

const generator = new generateContentDataObject();

export const clearNavigation = () => {
  return (dispatch, getState) => {
    const websites: WebsitesState = getState().websites;
    const currentWebsite = getCurrentWebsite(websites.items);
    const subdomain = currentWebsite.subdomain;
    const navCopy = _.cloneDeep(currentWebsite.nav);
    navCopy.componentData.cta.content.form.fields.items = [];
    navCopy.componentData.cta.content.form.button.title = "";
    navCopy.componentData.cta.content.buttons = [];
    navCopy.componentData.cta.ctaBottomInfo.markup = "";
    navCopy.componentData.navLogo.content.alt = "";
    navCopy.componentData.navLogo.content.src = "";
    navCopy.componentData.navLogo.content.companyTitle.text = "";
    navCopy.componentData.navLinks.content.dropdownItems = {};
    navCopy.componentData.navLinks.content.topLevelItems = [];
    dispatch(
      saveWebsiteNavInState({
        currentWebsiteSubdomain: subdomain,
        navObject: navCopy,
      })
    );
    dispatch(toggleWebsiteNavEditionsDetected(true));
  };
};

export const clearFooter = () => {
  return (dispatch, getState) => {
    const websites: WebsitesState = getState().websites;
    const currentWebsite = getCurrentWebsite(websites.items);
    const subdomain = currentWebsite.subdomain;
    const footerCopy = _.cloneDeep(currentWebsite.footer);
    footerCopy.componentData.text.content.items[0].markup = "";
    footerCopy.componentData.socialLinks.content.items = [];
    footerCopy.componentData.socialLinks.content.title = "";
    if (footerCopy.settings.scheme === 2) {
      footerCopy.componentData.cta.content.form.fields.items = [];
      footerCopy.componentData.cta.content.form.button.title = "";
      footerCopy.componentData.cta.content.buttons = [];
      footerCopy.componentData.cta.ctaBottomInfo.markup = "";
      footerCopy.componentData.list.content.items = [];
      footerCopy.componentData.list.content.groups = [];
      footerCopy.componentData.logos.content.items[0].uploadedSrc = "";
      footerCopy.componentData.contacts.content.items = [];
      footerCopy.componentData = generator.addEmptyContactIfNeeded(
        footerCopy.componentData
      );
    }
    footerCopy.componentData = generator.addEmptySocialLinkIfNeeded(
      footerCopy.componentData
    );
    dispatch(
      saveWebsiteFooterInState({
        currentWebsiteSubdomain: subdomain,
        footerObject: footerCopy,
      })
    );
    dispatch(toggleWebsiteFooterEditionsDetected(true));
  };
};

export const savePartialWebsiteData: SavePartialWebsiteData = (payload) => {
  return (dispatch, getState) => {
    const { data, websiteId } = payload;

    const websites: WebsitesState = getState().websites;
    const websitesItemsCopy = _.cloneDeep(websites.items);
    const currentWebsite = websitesItemsCopy.find((item) => {
      return item.id === websiteId;
    });

    Object.assign(currentWebsite, data);

    dispatch(saveWebsitesInState(websitesItemsCopy));
  };
};

export const createWebsiteFromTemplate = (payload) => {
  return (dispatch, getState) => {
    const {
      event,
      templateSubdomain,
      templateWebsiteId,
      newWebsiteSubdomain,
      hideForm,
      history,
    } = payload;
    event.preventDefault();

    const auth: AuthState = getState().auth;

    if (newWebsiteSubdomain !== null && newWebsiteSubdomain !== undefined) {
      newWebsiteSubdomain.toLowerCase().replace(" ", "");

      dispatch(createWebsiteErrorMessage(""));
      dispatch(createWebsiteWaitingForServer(true));

      let generator = new generateContentDataObject();

      let newWebsiteNav = generator.setUpNav({});

      let newWebsiteFooter = generator.setUpFooter({});

      const isBlankWebsite = !templateSubdomain || !templateWebsiteId;

      createWebsiteAsync({
        accessToken: auth.accessToken,
        subdomain: newWebsiteSubdomain,
        footer: newWebsiteFooter,
        nav: newWebsiteNav,
        popups: {
          items: [
            getDefaultPopupData({
              id: "popup-01-success_default",
              componentData: getDefaultSuccessPopupComponentData(),
            }),
          ],
        },
        templateWebsiteId,
        templateSubdomain,
      })
        .then(async (response) => {
          dispatch(setFullWebsiteLoadStatus({
            websiteId: response.data.id,
            status: LoadStatus.LOADED
          }))
          dispatch(setInitialPagesLoadStatus({
            websiteId: response.data.id,
            status: LoadStatus.IN_PROGRESS
          }))
          fireAnalyticsEvent.fireCrisp(CrispEvents.createWebsiteFromTemplate, {
            subdomain: newWebsiteSubdomain,
            template: templateSubdomain || "Blank",
          });
          dispatch(saveFullWebsiteToStore({
            website: response.data
          }));
          const newWebsiteId = response.data.id;
          dispatch(
            editFillTemplateData({
              websiteId: newWebsiteId,
              newData: {
                isModalVisible: !isBlankWebsite,
                templateSubdomain,
              },
            })
          );
          await dispatch(
            fetchSingleWebsitePages(newWebsiteSubdomain, newWebsiteId)
          );
          hideForm();
          Cookies.remove("template");
          if (
            getPathArray(history.location.pathname)[0] !== newWebsiteSubdomain
          ) {
            window.scrollTo(0, 0);
            history.push(`/${newWebsiteSubdomain}`);
          }
        })
        .catch((error) => {
          if (error.response) {
            let errorData = error.response.data;
            let errorObjectKey = getErrorKey(errorData);
            let errorObjectValue = getErrorValue(errorData, errorObjectKey);
            fireAnalyticsEvent.fireCrisp(
              CrispEvents.createWebsiteFromTemplateError,
              {
                error_type: errorObjectKey,
                error_message: errorObjectValue,
                subdomain: newWebsiteSubdomain,
                template: templateSubdomain,
              },
              true
            );

            if (errorData.subdomain !== undefined) {
              let message = errorData.subdomain.join(" ");
              dispatch(createWebsiteErrorMessage(message));
            }
            handleBadWebsiteError(errorData);
            if (errorData.not_pro !== undefined) {
              let message = errorData.not_pro;
              openNotification("Denied", message, "OK", "warn");
            }

            if (errorData.limit !== undefined) {
              let message = errorData.limit;
              openNotification("Limit reached!", message, "OK", "warn");
            }

            if (errorData.locked !== undefined) {
              let message = errorData.locked;
              openNotification("Denied", message, "OK", "warn");
            }

            if (errorData.detail !== undefined) {
              let message = errorData.detail;

              // This happens when user tries to change a website which doesn't exist or doesn't belong to the user.
              openNotification(
                "Server error",
                'Error message: "' +
                  message +
                  '". This should not have happened. Please contact us.',
                "OK",
                "error"
              );
            }
          }
        })
        .then((response) => {
          // always executed
          dispatch(createWebsiteWaitingForServer(false));
        });
    } else {
      openNotification(
        "Error",
        "We failed to create the website 😔 There must be a script error. Please contact us and we will help you.",
        "OK",
        "error"
      );
    }
  };
};

export const getCurrentStoreData = () => {
  return (dispatch, getState) => {
    const websites: WebsitesState = getState().websites;
    const websitesPages: WebsitePagesState = getState().websitesPages;
    const blogs: BlogsState = getState().blogs;
    const blogPosts: BlogPostsState = getState().blogPosts;

    const currentWebsite = getCurrentWebsite(websites.items);

    const currentWebsitePageUrl = getCurrentWebsitePageUrl(
      currentWebsite,
      websitesPages.items
    );
    const currentWebsitePage = getCurrentWebsitePage(
      currentWebsitePageUrl,
      websitesPages.items,
      currentWebsite
    );

    const currentBlog = getCurrentBlog(blogs.items, currentWebsite);

    const currentBlogPostUrl = getCurrentBlogPostUrl(
      currentBlog,
      blogPosts.items
    );
    const currentBlogPost = getCurrentBlogPost(
      currentBlogPostUrl,
      blogPosts.items,
      currentBlog
    );

    return {
      currentWebsite,
      currentWebsitePage,
      currentBlog,
      currentBlogPost,
    };
  };
};

export const saveWebsiteBackup = (type) => {
  return (dispatch, getState) => {
    const auth: AuthState = getState().auth;
    const websites: WebsitesState = getState().websites;
    const websitesPages: WebsitePagesState = getState().websitesPages;
    
    let dataToBackup = {};
    if (type === "page") {
      dataToBackup = _.cloneDeep(websitesPages.pageBackup);
    } else {
      dataToBackup = _.cloneDeep(websites.websiteBackup);
    }

    return new Promise((resolve, reject) => {
      saveBackupAsync({
        accessToken: auth.accessToken,
        type,
        dataToBackup,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
};

export const saveToLocalBackup = (type) => {
  return (dispatch, getState) => {
    const { currentWebsite, currentWebsitePage } = dispatch(getCurrentStoreData());
    
    if (type === "page") {
      dispatch(changeWebsitePagesStateGlobalData({
        data: {
          pageBackup: _.cloneDeep(currentWebsitePage),
        }
      }));
    } else {
      dispatch(changeWebsitesStateGlobalData({
        websiteBackup: _.cloneDeep(currentWebsite),
      }));
    }
  };
};

export const getSingleWebsiteLoadStatus = (website) => {
  return (dispatch, getState) => {
    if (!website) {
      return LoadStatus.NO_DATA;
    }
    const websites = getState().websites;
    const currentStatus = websites.fullWebsiteLoadStatusArray.find((item) => {
      return item.websiteId === website.id;
    })
    return _.get(currentStatus, "status", LoadStatus.NOT_LOADED);
  };
};
