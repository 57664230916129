import { isKeyValue } from "./isKeyValue";

export const replaceDemoIds = (obj, replacePairs): any => {
  if (typeof obj === "string") {
    return replacePairs.reduce((result, pair) => {
      if (typeof pair.demoId === "string" && typeof pair.newId === "string" && pair.demoId.length === 36 && pair.newId.length === 36) {
        return result.replace(new RegExp(pair.demoId, "g"), pair.newId);
      }
      return result;
    }, obj);
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => replaceDemoIds(item, replacePairs));
  }

  if (isKeyValue(obj)) {
    const newObj = {};
    for (const [key, value] of Object.entries(obj)) {
      newObj[key] = replaceDemoIds(value, replacePairs);
    }
    return newObj;
  }

  return obj;
};
